import React from "react";
import img from "../../img/mock/Ellipse 135.png";
import img2 from "../../img/mock/Ellipse 136.png";
import img3 from "../../img/mock/Ellipse 137.png";
import img4 from "../../img/mock/Ellipse 138.png";
import img5 from "../../img/mock/Rectangle 915.png";
import img6 from "../../img/mock/Rectangle 915-1.png";
import img8 from "../../img/mock/Rectangle 915-2.png";
import img7 from "../../img/mock/Rectangle 915-3.png";
import img10 from "../../img/mock/Rectangle 915-4.png";

import "./Mock_exam.css";
const Mock_exam = () => {
  return (
    <div>
      <div className="mock_instruction_header_bg">
        <p className="mock_instruction_header p-lg-3">
          {" "}
          TEST-1 <span>/Reasoning/Topic Name</span>
        </p>
      </div>
      <div>
        <div className="row p-0 m-0">
          <div className="col-md-8">
            <div className="left_bg">
              <div className="p-5">
                <div className="d-flex">
                  <h3 className="mock_exam_h3">Question-3</h3>
                  <p className="mx-5 mock_exam_p">Marks: +1, -0.25</p>
                  <p className=" mock_exam_p">3/50</p>
                </div>
                <hr className="mock_exam_hr" />
                <div>
                  <h6 className="mock_exam_h4">
                    What Is The Meaning Of Reasoning?{" "}
                  </h6>
                  <div>
                    <img
                      src="https://i.ibb.co/s2nxWp3/sangga-rima-roman-selia-bg-Qg-AKag-QB4-unsplash-2x.png"
                      className="mock_exam_img img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="mock_exam_div">
                    <div class="form-check ms-2 mb-2 mock_exam">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Lorem Ipsum Dolar Slit Amet
                      </label>
                    </div>
                    <div class="form-check ms-2 mb-2 mock_exam">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        checked
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Lorem Ipsum Dolar Slit Amet
                      </label>
                    </div>
                    <div class="form-check ms-2 mb-2 mock_exam">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Lorem Ipsum Dolar Slit Amet
                      </label>
                    </div>
                    <div class="form-check ms-2 mb-2 mock_exam">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Lorem Ipsum Dolar Slit Amet
                      </label>
                    </div>
                  </div>
                </div>
                <hr className="mock_exam_hr" />
                <div>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="d-flex justify-content-between">
                        <div>
                          <button className="mock_exam_btn">Skip</button>
                          <button className="mock_exam_btn2 mx-5">
                            Clear Response
                          </button>
                          <button className="mock_exam_btn2">
                            Make For Review
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <button className="mock_exam_btn3">Save & Next</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mock_exam_card mt-4">
              <div className="p-3">
                <a href="" className="mock_exam_a1">
                  Time Left
                </a>
                <div className="d-flex mx-5">
                  <div>
                    <p className="m-0 p-0">01</p>
                    <h6>Hours</h6>
                  </div>
                  <div className="mx-3">
                    <p className="m-0 p-0">01</p>
                    <h6>Hours</h6>
                  </div>
                  <div>
                    <p className="m-0 p-0">01</p>
                    <h6>Hours</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="d-flex">
                <div>
                  <a href="#" className="mock_exam_a2">
                    <img src={img} alt="" />
                    <span className="ms-2 fs-6">Answered-03</span>
                  </a>
                  <a href="#" className="mock_exam_a2 ms-5">
                    <img src={img3} alt="" />
                    <span className="ms-2 fs-6">Not Attempted-47</span>
                  </a>
                </div>
              </div>
              <div className="d-flex">
                <div>
                  <a href="#" className="mock_exam_a2">
                    <img src={img2} alt="" />
                    <span className="ms-2 fs-6">Not Answered-12</span>
                  </a>
                  <a href="#" className="mock_exam_a2 ms-3">
                    <img src={img4} alt="" />
                    <span className="ms-2 fs-6">Marked For Review-14</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="p-5">
              <div className="question_task">
                <div>
                  <h6 className="question_h6">Question Pallete</h6>
                </div>
                <div className="d-flex">
                  <div className="">
                    <img src={img5} alt="" />
                    <h6 className="question_h1_box">1</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img5} alt="" />
                    <h6 className="question_h1_box">2</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img5} alt="" />
                    <h6 className="question_h1_box">3</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img6} alt="" />
                    <h6 className="question_h1_box">4</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img8} alt="" />
                    <h6 className="question_h1_box">5</h6>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="">
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">6</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">7</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">8</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img10} alt="" />
                    <h6 className="question_h1_box">9</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">10</h6>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="">
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">11</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">12</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">13</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">14</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">15</h6>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="">
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">16</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">17</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">18</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">19</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">20</h6>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="">
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">21</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">22</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">23</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">24</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">25</h6>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="">
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">26</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">27</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">28</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">29</h6>
                  </div>
                  <div style={{ marginLeft: -30 }}>
                    <img src={img7} alt="" />
                    <h6 className="question_h1_box">30</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex ms-5">
              <button className="mock_exam_btn4">Submit Test</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mock_exam;
