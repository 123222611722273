import React from "react";
import ReactTextCollapse from "../../Exam_inner/About/ReactTextCollapse";
const TEXT_COLLAPSE_OPTIONS = {
  collapse: false,
  collapseText: "... show more",
  expandText: "show less",
  minHeight: 100,
  maxHeight: 200,
  textStyle: {
    color: "#64C280",
    fontSize: "18px",
  },
};
const Admission = () => {
  return (
    <div>
      <div className="row p-0">
        <nav className="col-md-2 side p-0" id="myScrollspy">
          <div className="announcement_sm">
            <ul
              className="nav nav-pills nav-stacked"
              data-offset-top={10}
              style={{ background: "unset" }}
            >
              <li className="active">
                <a href="#">INTRODUCTION</a>
              </li>
              <li>
                <a href="#section_admission2">ABOUT TEST</a>
              </li>
              <li>
                <a href="#section_admission3">IMPORTANT DATES</a>
              </li>
              <li>
                <a href="#section_admission4">ADMISSION HIGHLIGHTS</a>
              </li>
              <li>
                <a href="#section_admission5">APPLICATION PROCESS</a>
              </li>
              <li>
                <a href="#section_admission6">PHD ADMISSION PROCESS</a>
              </li>
              <li>
                <a href="#section_admission7">PHD ADMISSION PROCESS</a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="col-md-10 col-12 rightSide">
          <div id="#">
            <div className="p-lg-5 text-start content">
              <h3 className="text-start content_title">Introduction</h3>
              <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </ReactTextCollapse>
            </div>
          </div>
          <div className="my-5" id="section_admission2">
            <div className="p-lg-5 text-start content">
              <h3 className="text-start content_title mt-5">About Test</h3>
              <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </ReactTextCollapse>
            </div>
          </div>
          <div className="my-5" id="section_admission3">
            <div className="p-lg-5 text-start content">
              <h3 className="text-start content_title mt-5">Important Dates</h3>
              <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et ipsum dolor
                  sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                  tempor invidunt ut labore et
                </p>
              </ReactTextCollapse>
            </div>
          </div>

          <div className="my-5" id="section_admission4">
            <div className="p-lg-5 text-start content">
              <h3 className="text-start content_title mt-5">
                {" "}
                Admission Highlights
              </h3>
              <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </ReactTextCollapse>
            </div>
          </div>
          <div className="my-5" id="section_admission5">
            <div className="p-lg-5 text-start content">
              <h3
                id="section_admission5"
                className="text-start content_title mt-5"
              >
                {" "}
                Application Process
              </h3>
              <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </ReactTextCollapse>
            </div>
          </div>
          <div className="my-5" id="section_admission6">
            <div className="p-lg-5 text-start content">
              <h3 className="text-start content_title mt-5">
                {" "}
                PHD Admission Process
              </h3>
              <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </ReactTextCollapse>
            </div>
          </div>

          <div className="my-5" id="section_admission7">
            <div className="p-lg-5 text-start content">
              <h3 className="text-start content_title mt-5">
                {" "}
                Documents Required
              </h3>
              <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </ReactTextCollapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admission;
