import React from "react";
import "./Step.css";
const Step2 = () => {
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center container">
        <div className="otp_card py-5 px-3">
          <p className="otp_p">
            OTP has been sent on your given mobile number or email.
          </p>
          <h6 className="m-0 otp_6">Enter 6 Digit Mobile OTP</h6>

          <div className="d-flex flex-row mt-5">
            <input type="text" className="form-control" autofocus />
            <input type="text" className="form-control" />
            <input type="text" className="form-control" />
            <input type="text" className="form-control" />
            <input type="text" className="form-control" />
            <input type="text" className="form-control" />
          </div>
          <div className="text-center mt-5">
            <button
              type="submit"
              className="  profile_btn"
              style={{ width: 220 }}
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
