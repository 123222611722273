import React from "react";
import Exam from "../../components/Exam/Exam";

const Exams = () => {
  return (
    <div className="container-fluid">
      <Exam />
    </div>
  );
};

export default Exams;
