import React from "react";
import RegistrationAll from "../../components/Registration_college/Registration";

const Registration_college = () => {
  return (
    <div>
      <RegistrationAll />
    </div>
  );
};

export default Registration_college;
