import React from "react";
import "./LeftSide.css";
import { Link } from "react-router-dom";
const LeftSide = () => {
  return (
    <div>
      <div className="left_card_college text-start mt-4">
        <div style={{ borderLeft: " 5px solid #4AB76A" }}>
          <div className="left_card_college_header p-3">
            <a className="left_card_college_text" href="#">
              SHORT TRICKS
            </a>
          </div>
        </div>

        <div className="p-3">
          <Link to={"/corporate_main"}>
            <div class="form-check ms-2 mb-2">
              <input
                class="form-check-input "
                type="checkbox"
                value=""
                id="flexCheckDefault"
                checked
              />
              <label class="form-check-label" for="flexCheckDefault">
                <a href="/corporate_main"> Reasoning Short Tricks</a>
              </label>
            </div>
          </Link>

          <div class="form-check ms-2 mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Quantitative Aptitude Short Tricks
            </label>
          </div>
          <div class="form-check ms-2 mb-2">
            <input
              class="form-check-input "
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Verbal Ability
            </label>
          </div>
          <div class="form-check ms-2 mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Soft Skills/Personality Development
            </label>
          </div>
          <div class="form-check ms-2">
            <input
              class="form-check-input "
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Interview Preparation
            </label>
          </div>
        </div>
      </div>
      <div className="left_card_college text-start mt-4">
        <div style={{ borderLeft: " 5px solid #F8B54C" }}>
          <div className="left_card_college_header p-3">
            <a className="left_card_college_text" href="">
              WORKSHEET FOR PRACTICE
            </a>
          </div>
        </div>

        <div className="p-3">
          <Link to={"/corporate_main_worksheet"}>
            <div class="form-check ms-2 mb-2">
              <input
                class="form-check-input "
                type="checkbox"
                value=""
                id="flexCheckDefault"
                checked
              />
              <label class="form-check-label" for="flexCheckDefault">
                <a href="/corporate_main_worksheet">Reasoning</a>
              </label>
            </div>
          </Link>
          <div class="form-check ms-2 mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Quantitative Aptitude
            </label>
          </div>
          <div class="form-check ms-2 mb-2">
            <input
              class="form-check-input "
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Verbal Ability
            </label>
          </div>
          <div class="form-check ms-2 mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Interview Preparation
            </label>
          </div>
        </div>
      </div>
      <div className="left_card_college text-start mt-4">
        <div style={{ borderLeft: " 5px solid #F7684A" }}>
          <div className="left_card_college_header p-3">
            <a className="left_card_college_text" href="">
              HCCECO LIBRARY FOR TRAINERS/ TEACHERS
            </a>
          </div>
        </div>

        <div className="p-3">
          <Link to={"/corporate_main_library"}>
            <div class="form-check ms-2 mb-2">
              <input
                class="form-check-input "
                type="checkbox"
                value=""
                id="flexCheckDefault"
                checked
              />
              <label class="form-check-label" for="flexCheckDefault">
                <a href="/corporate_main_library">Reasoning</a>
              </label>
            </div>
          </Link>
          <div class="form-check ms-2 mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Quantitative Aptitude
            </label>
          </div>
          <div class="form-check ms-2 mb-2">
            <input
              class="form-check-input "
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Verbal Ability
            </label>
          </div>
          <div class="form-check ms-2 mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Interview Preparation
            </label>
          </div>
        </div>
      </div>
      <div className="left_card_college text-start mt-4">
        <div style={{ borderLeft: " 5px solid #463196" }}>
          <div className="left_card_college_header p-3">
            <a className="left_card_college_text" href="">
              MOCK TESTS FOR PRACTICE
            </a>
          </div>
        </div>

        <div className="p-3">
          <Link to={"/corporate_mock_test"}>
            <div class="form-check ms-2 mb-2">
              <input
                class="form-check-input "
                type="checkbox"
                value=""
                id="flexCheckDefault"
                checked
              />
              <label class="form-check-label" for="flexCheckDefault">
                <a href="/corporate_mock_test">Reasoning</a>
              </label>
            </div>
          </Link>
          <div class="form-check ms-2 mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Quantitative Aptitude
            </label>
          </div>
          <div class="form-check ms-2 mb-2">
            <input
              class="form-check-input "
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Verbal Ability
            </label>
          </div>
          <div class="form-check ms-2 mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Soft Skills/Personality Development
            </label>
          </div>
          <div class="form-check ms-2">
            <input
              class="form-check-input "
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Interview Preparation
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSide;
