import React from "react";

import img1 from "../../img/colleges-inner-page/Icon material-location-on.svg";
import img2 from "../../img/colleges-inner-page/Icon ionic-ios-book.svg";
import img3 from "../../img/colleges-inner-page/Icon material-chat.svg";

import "./College_inner.css";
import Details from "../../components/College_inner/Details/Detalis";

const CollegeInner = () => {
  return (
    <div className="container-fluid ">
      <div className="main_head">
        <div style={{ marginTop: -120 }}>
          <div className="header_img">
            <div>
              <div className="header_text">
                <div className="d-lg-flex">
                  <div>
                    <p className="circle_inner"> </p>
                    <p className="circle_inner_text">College Logo Here</p>
                  </div>

                  <div className="ms-3">
                    <h1 className="">A.G. COLLEGE OF EDUCATION, COIMBATORE</h1>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <a>
                          {" "}
                          <img src={img1} alt="" />
                          <span className="ms-1"> Coimbatore,Tamil Nadu</span>
                        </a>
                      </div>
                      <div className="d-flex">
                        <a>
                          {" "}
                          <img src={img2} alt="" />
                          <span className="ms-1"> UGC</span>
                        </a>
                      </div>
                      <div className="d-flex">
                        <a>
                          {" "}
                          <img src={img2} alt="" />
                          <span className="ms-1"> 25 Reviews</span>
                        </a>
                      </div>
                      <div className="d-flex">
                        <a>
                          {" "}
                          <img src={img3} alt="" />
                          <span className="ms-1"> ESTD</span>
                        </a>
                      </div>
                      <div className="d-flex">
                        <a>
                          {" "}
                          <img src={img3} alt="" />
                          <span className="ms-1"> Ranked 0 by</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex announcement">
        <div className="announcement_text1_bg ">
          <h6 className="announcement_text1 text-center p-3">Announcement</h6>
        </div>
        <div className="announcement_text2_bg">
          <h6 className="announcement_text2 p-3 ">
            Announcement Will Show Here Announcement Will Show Here |
            Announcement Will Show Here Announcement
          </h6>
        </div>
      </div>

      {/* <Navigation /> */}
      <div className="details_sm_details">
        <Details />
      </div>
    </div>
  );
};

export default CollegeInner;
