import React from "react";
import LeftSide from "../../components/Home/LeftSide/LeftSide";
import RightSide from "../../components/Home/RightSide/RightSide";
import MainPart from "../../components/Home_announcement/MainPart/MainPart";

import "./Home_announcement.css";
const Home_announcement = () => {
  return (
    <div className="container-fluid">
      {/* <Navigation /> */}
      <div className="row p-lg-5">
        <div className="col-md-3 announcement_sm">
          <LeftSide />
        </div>
        <div className="col-md-6">
          <MainPart />
        </div>
        <div className="col-md-3 announcement_sm">
          <RightSide />
        </div>
      </div>
    </div>
  );
};

export default Home_announcement;
