import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import Navbar from "./components/common/Navbar/Navbar";
import Course_inner from "./components/Courses_inner/Course_inner";
import Exam_inner from "./pages/Exam_inner/Exam_inner";
import College from "./pages/college/College";
import CollegeInner from "./pages/college_inner/College_inner";
import Courses from "./pages/courses/Courses";
import Exams from "./pages/exams/Exams";

import Home from "./pages/home/Home";
import New from "./pages/new";
import Home_default from "./pages/home_default/Home_default";
import Home_default2 from "./pages/home_default2/Home_default2";
import Home_1 from "./pages/home_1/Home_1.jsx";
import Home_link from "./pages/home_link/Home_link";
import Home_announcement from "./pages/home_announcement/Home_announcement";
import Home_login from "./pages/home_login/Home_login";
import Edit_profile from "./pages/edit_profile/Edit_profile";
import Review from "./pages/review/Review";
import Search from "./pages/search/Search";
import Edit_college_profile from "./pages/edit_college_profile/Edit_college_profile";
import Login from "./pages/Login/login";
import Registration from "./pages/registration/registration";
import Registration_college from "./pages/registration_college/registration_college";
import Corporate from "./pages/corporate/Corporate";
import Corporate_main from "./pages/corporate_main/Corporate_main";
import Corporate_main_worksheet from "./pages/corporate_main_worksheet/Corporate_main_worksheet";
import Corporate_main_library from "./pages/corporate_main_library/Corporate_main_library";
import Mock_instruction from "./pages/mock_instruction/Mock_instruction";
import Mock_result from "./pages/mock_result/Mock_result";
import Mock_test from "./pages/mock_test/Mock_test";
import Mock_exam from "./pages/mock_exam/Mock_exam";
import Flip from "./pages/flip/Flip";
import S from "./pages/s";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Routes>
          <Route path="/home1" element={<Home_1 />} />
        </Routes>
        <Routes>
          <Route path="/homelogin" element={<Home_login />} />
        </Routes>
        <Routes>
          <Route path="/home_default" element={<Home_default />} />
        </Routes>
        <Routes>
          <Route path="/home_default2" element={<Home_default2 />} />
        </Routes>
        <Routes>
          <Route path="/home_link" element={<Home_link />} />
        </Routes>
        <Routes>
          <Route path="/home_announcement" element={<Home_announcement />} />
        </Routes>
        <Routes>
          <Route path="/college" element={<College />} />
        </Routes>
        <Routes>
          <Route path="/college_inner" element={<CollegeInner />} />
        </Routes>
        <Routes>
          <Route path="/exams" element={<Exams />} />
        </Routes>
        <Routes>
          <Route path="/courses" element={<Courses />} />
        </Routes>
        <Routes>
          <Route path="/new" element={<New />} />
        </Routes>
        <Routes>
          <Route path="/flip" element={<Flip />} />
        </Routes>

        <Routes>
          <Route path="/courses_inner" element={<Course_inner />} />
        </Routes>
        <Routes>
          <Route path="/exams_inner" element={<Exam_inner />} />
        </Routes>

        <Routes>
          <Route path="/corporate" element={<Corporate />} />
        </Routes>

        <Routes>
          <Route path="/corporate_main" element={<Corporate_main />} />
        </Routes>
        <Routes>
          <Route
            path="/corporate_main_worksheet"
            element={<Corporate_main_worksheet />}
          />
        </Routes>
        <Routes>
          <Route
            path="/corporate_main_library"
            element={<Corporate_main_library />}
          />
        </Routes>
        <Routes>
          <Route path="/corporate_mock_test" element={<Mock_test />} />
        </Routes>
        <Routes>
          <Route path="/review" element={<Review />} />
        </Routes>
        <Routes>
          <Route path="/edit_profile" element={<Edit_profile />} />
        </Routes>
        <Routes>
          <Route
            path="/edit_college_profile"
            element={<Edit_college_profile />}
          />
        </Routes>
        <Routes>
          <Route path="/search" element={<Search />} />
        </Routes>
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
        <Routes>
          <Route path="/register" element={<Registration />} />
        </Routes>
        <Routes>
          <Route path="/register_college" element={<Registration_college />} />
        </Routes>
        <Routes>
          <Route path="/mock_instruction" element={<Mock_instruction />} />
        </Routes>
        <Routes>
          <Route path="/mock_result" element={<Mock_result />} />
        </Routes>
        <Routes>
          <Route path="/mock_exam" element={<Mock_exam />} />
        </Routes>
        <Routes>
          <Route path="/s" element={<S />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
