import React from "react";
import "./Course_inner.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Course_inner = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div className="row px-lg-5" style={{ background: "#EEEEEE" }}>
        <nav className="col-md-2 side p-0 " id="myScrollspy">
          <div className="announcement_sm">
            <ul
              className="nav nav-pills nav-stacked"
              data-offset-top={10}
              style={{ background: "unset" }}
            >
              <li className="active">
                <a href="#"> ABOUT B.ARCH</a>
              </li>
              <li>
                <a href="#section_course_11"> SPECIALIZATION</a>
              </li>
              <li>
                <a href="#section_course_12">ELIGIBILITY</a>
              </li>
              <li>
                <a href="#section_course_13"> COURSE AFTER B.ARCH</a>
              </li>
              <li>
                <a href="#section_course_14"> CARRER AFTER B.ARCH</a>
              </li>
              <li>
                <a href="#section_course_15">IMPORTANT EXAM BOOKS</a>
              </li>
              <li>
                <a href="#section_course_16"> AVERAGE FEE</a>
              </li>
              <li>
                <a href="#section_course_16"> SALARY TRENDS</a>
              </li>
              <li>
                <a href="#section_course_18"> TOP RECRUITERS</a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="col-md-10 rightSide">
          <div className="college_inner_tab">
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                background: "#463196 0 0 no-repeat padding-box",
                paddingTop: 1,
              }}
              className="tab_bg"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                TabIndicatorProps={{
                  style: { background: "#fff", height: 4, marginTop: 10 },
                }}
              >
                <Tab
                  label="ABOUT B.ARCH"
                  {...a11yProps(0)}
                  sx={{
                    textTransform: "initial",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                />

                <Tab
                  label="SPECIALIZATION"
                  {...a11yProps(1)}
                  sx={{
                    textTransform: "initial",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                />
                <Tab
                  label="ELIGIBILITY"
                  {...a11yProps(2)}
                  sx={{
                    textTransform: "initial",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                />

                <Tab
                  label="COURSE AFTER B.ARCH"
                  {...a11yProps(3)}
                  sx={{
                    textTransform: "initial",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                />
                <Tab
                  label="CARRER AFTER B.ARCH "
                  {...a11yProps(4)}
                  sx={{
                    textTransform: "initial",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                />

                <Tab
                  label="IMPORTANT EXAM BOOKS"
                  {...a11yProps(5)}
                  sx={{
                    textTransform: "initial",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                />
                <Tab
                  label="AVERAGE FEE "
                  {...a11yProps(6)}
                  sx={{
                    textTransform: "initial",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                />

                <Tab
                  label="TOP RECRUITERS"
                  {...a11yProps(7)}
                  sx={{
                    textTransform: "initial",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <h3 className=" content_title text-start">ABOUT B.ARCH</h3>
              <p className="text-start">
                {" "}
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                invidunt ut labore et
              </p>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {" "}
              <h3 className="text-start content_title">SPECIALIZATION </h3>
              <p className="content_text2">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Blanditiis dignissimos explicabo quae sunt eaque nihil dolor aut
                neque obcaecati ipsa voluptatibus nisi ab, est id odio
                recusandae tempora in! Iure, voluptatibus rerum beatae corporis
                aperiam eaque aliquam dolorum atque fugiat hic, ab accusantium
                tempora nostrum, harum quasi suscipit sint libero illum vel!
                Numquam possimus deserunt iure qui quis nesciunt minus id cumque
                temporibus voluptatibus consequatur animi deleniti commodi,
                similique assumenda aperiam accusantium velit pariatur provident
                nobis dolor sint earum. Ab tenetur, porro reiciendis
                consequuntur culpa ea nesciunt facilis unde voluptatum odit
                dolor? Soluta eligendi assumenda ea doloremque incidunt suscipit
                corrupti iste accusantium minima quia, libero aliquam magnam
                dolorem. Error minus neque obcaecati. Recusandae voluptas
                doloribus necessitatibus eveniet nemo quasi, veritatis,
                molestias libero ad quae excepturi praesentium numquam sunt
                odio? Natus dolor repellendus laboriosam, amet ab excepturi
                numquam consectetur nostrum dolores adipisci soluta eos
                sapiente, commodi mollitia! Excepturi omnis, maiores dolore
                laborum dolor repellat officiis quia necessitatibus aliquam
                numquam illum quo eius architecto quae nobis error a pariatur
                et. Id dignissimos, sapiente earum omnis adipisci autem cumque
                repellendus quisquam est doloremque quidem quibusdam aperiam
                explicabo enim repudiandae minima at porro rem sunt modi!
                Excepturi vero exercitationem sapiente voluptate ullam
                consequuntur blanditiis? Lorem ipsum dolor sit amet, consetetur
                sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et
              </p>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <h3 className="text-start content_title">ELIGIBILITY</h3>
              <p className="content_text2">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                invidunt ut labore et
              </p>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <h3 className="text-start content_title">COURSE AFTER B.ARCH</h3>
              <p className="content_text2">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                invidunt ut labore et
              </p>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <h3 className="text-start content_title">COURSE AFTER B.ARCH</h3>
              <p className="content_text2">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                invidunt ut labore et
              </p>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <h3 className="text-start content_title">IMPORTANT EXAM BOOKS</h3>
              <p className="content_text2">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                invidunt ut labore et
              </p>
            </TabPanel>
            <TabPanel value={value} index={6}>
              <h3 className="text-start content_title">AVERAGE FEE</h3>
              <p className="content_text2">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                invidunt ut labore et
              </p>
            </TabPanel>
            <TabPanel value={value} index={7}>
              <h3 className="text-start content_title">SALARY TRENDS</h3>
              <p className="content_text2">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                invidunt ut labore et
              </p>
            </TabPanel>
          </div>

          <div className="announcement_sm">
            <div className="my-5" id="#">
              <div className="p-lg-5 text-start content">
                <h3 className="text-start content_title">ABOUT B.ARCH</h3>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>
            <div className="my-5" id="section_course_11">
              <div className="p-lg-5 text-start content">
                <h3 className="text-start content_title">SPECIALIZATION</h3>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Blanditiis dignissimos explicabo quae sunt eaque nihil dolor
                  aut neque obcaecati ipsa voluptatibus nisi ab, est id odio
                  recusandae tempora in! Iure, voluptatibus rerum beatae
                  corporis aperiam eaque aliquam dolorum atque fugiat hic, ab
                  accusantium tempora nostrum, harum quasi suscipit sint libero
                  illum vel! Numquam possimus deserunt iure qui quis nesciunt
                  minus id cumque temporibus voluptatibus consequatur animi
                  deleniti commodi, similique assumenda aperiam accusantium
                  velit pariatur provident nobis dolor sint earum. Ab tenetur,
                  porro reiciendis consequuntur culpa ea nesciunt facilis unde
                  voluptatum odit dolor? Soluta eligendi assumenda ea doloremque
                  incidunt suscipit corrupti iste accusantium minima quia,
                  libero aliquam magnam dolorem. Error minus neque obcaecati.
                  Recusandae voluptas doloribus necessitatibus eveniet nemo
                  quasi, veritatis, molestias libero ad quae excepturi
                  praesentium numquam sunt odio? Natus dolor repellendus
                  laboriosam, amet ab excepturi numquam consectetur nostrum
                  dolores adipisci soluta eos sapiente, commodi mollitia!
                  Excepturi omnis, maiores dolore laborum dolor repellat
                  officiis quia necessitatibus aliquam numquam illum quo eius
                  architecto quae nobis error a pariatur et. Id dignissimos,
                  sapiente earum omnis adipisci autem cumque repellendus
                  quisquam est doloremque quidem quibusdam aperiam explicabo
                  enim repudiandae minima at porro rem sunt modi! Excepturi vero
                  exercitationem sapiente voluptate ullam consequuntur
                  blanditiis? Lorem ipsum dolor sit amet, consetetur sadipscing
                  elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                  dolore magna aliquyam erat, sed diam voluptua. At vero eos et
                  accusam et justo duo dolores et ea rebum. Stet clita kasd
                  gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                  amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore
                  magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                  et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                  no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>
            <div className="my-5" id="section_course_12">
              <div className="p-lg-5 text-start content">
                <h3 className="text-start content_title">ELIGIBILITY </h3>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>
            <div className="my-5" id="section_course_13">
              <div className="p-lg-5 text-start content">
                <h3 className="text-start content_title">
                  COURSE AFTER B.ARCH
                </h3>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>
            <div className="my-5" id="section_course_14">
              <div className="p-lg-5 text-start content">
                <h3 className="text-start content_title">
                  CARRER AFTER B.ARCH
                </h3>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>
            <div className="my-5" id="section_course_15">
              <div className="p-lg-5 text-start content">
                <h3 className="text-start content_title">
                  IMPORTANT EXAM BOOKS
                </h3>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>
            <div className="my-5" id="section_course_16">
              <div className="p-lg-5 text-start content">
                <h3 className="text-start content_title">AVERAGE FEE</h3>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>
            <div className="my-5" id="section_course_17">
              <div className="p-lg-5 text-start content">
                <h3 className="text-start content_title">SALARY TRENDS</h3>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>
            <div className="my-5" id="section_course_18">
              <div className="p-lg-5 text-start content">
                <h3 className="text-start content_title"> TOP RECRUITERS</h3>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course_inner;
