import React, { useEffect, useState } from "react";
import img from "../../../img/home/announcement-megaphone-svgrepo-com.png";
import img2 from "../../../img/home/Rectangle 560.png";
import img3 from "../../../img/home/XMLID_91_.png";
import img4 from "../../../img/home/connection-svgrepo-com.png";
import "./RightSide.css";
import { Link } from "react-router-dom";
import axios from "axios";
const RightSide = () => {
  //get_latest_announcements
  const [data, setData] = useState([]);
  const loadPosts = async () => {
    try {
      const response = await axios.get(
        "https://educationapi.pino10.shop/api/announcement"
      );
      setData(response.data.splice(0, 7));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loadPosts();
  }, []);

  //get_latest_hirings
  const [data2, setData2] = useState([]);
  const loadPosts2 = async () => {
    try {
      const response = await axios.get(
        "https://educationapi.pino10.shop/api/hiring"
      );
      setData2(response.data.splice(0, 7));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loadPosts2();
  }, []);

  return (
    <div className="container">
      <div>
        <div className="d-flex p-2 justify-content-center card_header">
          <a href="" className="card_text">
            <img src={img} alt="" />
            <span className="ms-2">LATEST ANNOUNCEMENT</span>
          </a>
        </div>

        <nav className="side w-100" id="myScrollspy">
          {data.map((data) => (
            <div>
              <div className="d-flex">
                <a href="" className="right_side_text mt-4">
                  <img src={img2} alt="" />
                  <span className="ms-2">
                    {data.description.split(/\s+/).slice(0, 20).join(" ")}...
                  </span>
                </a>
              </div>
              <div className="d-flex small_text_main">
                <span className=" small_text">Manpreet Kaur</span>
                <span className="ms-5 small_text">
                  {data.date.split(/\s+/).slice(0, 1).join(" ")}
                </span>
              </div>
            </div>
          ))}
          <div className="mt-5">
            <Link to={"/home_announcement"} style={{ margin: 20 }}>
              <a href="'/home_announcement" className="link_text">
                Show more..
              </a>
            </Link>
          </div>
        </nav>
      </div>
      <div className="mt-4">
        <div className="d-flex p-2 justify-content-center card_header">
          <a href="" className="card_text">
            <img src={img3} alt="" />
            <span className="ms-2">LATEST HIRING</span>
          </a>
        </div>

        <nav className="side w-100 home_side_height" id="myScrollspy">
          {data2.map((data) => (
            <div>
              <div className="d-flex">
                <a href="" className="right_side_text mt-4">
                  <img src={img2} alt="" />
                  <span className="ms-2 right_side_text">
                    {data.description}
                  </span>
                </a>
              </div>
              <div className="d-flex small_text_main">
                <span className=" small_text">Manpreet Kaur</span>
                <span className="ms-5  small_text">
                  {" "}
                  {data.date.split(/\s+/).slice(0, 1).join(" ")}
                </span>
              </div>
            </div>
          ))}

          <div className="mt-5">
            <Link to={"/home_announcement"}>
              <a href="/home_announcement" className="link_text">
                Show more..
              </a>
            </Link>
          </div>
        </nav>
      </div>
      <div className="mt-4">
        <div className="d-flex p-2 justify-content-center card_header">
          <a href="" className="card_text">
            <img src={img4} alt="" />
            <span className="ms-2">HCCECO LINKS</span>
          </a>
        </div>

        <nav className="side w-100" id="myScrollspy" style={{ height: 280 }}>
          <div>
            <div className="d-flex message_part">
              <button className="btn1 mt-4">Messages</button>
              <button className="btn2 mt-4">Link Requests</button>
            </div>
            <div className="search_message mb-3">
              <input
                type="text"
                className="btn_input mt-4 p-3"
                placeholder="Search connections.."
              />
            </div>
            <span className="mt-5">No Messages to show</span>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default RightSide;
