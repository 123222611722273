import React from "react";

import "./College.css";
const College = () => {
  return (
    <div>
      <div className="" style={{ margin: "0px 120px" }}>
        <form className="row g-3">
          <div className="col-md-6 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              Choose Affiliation
            </label>
            <select id="inputState" className="form-select">
              <option selected>Colleges</option>
              <option>A</option>
              <option>B</option>
            </select>
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              College Name
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter College Name"
            />
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              College Mail Id
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter Mail Id"
            />
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              College Type
            </label>
            <select id="inputState" className="form-select">
              <option selected>Select college type..</option>
              <option>A</option>
              <option>B</option>
            </select>
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              College Established Date
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter Established Date"
            />
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              Choose Approval
            </label>
            <select id="inputState" className="form-select">
              <option selected>UGC</option>
              <option>Mumbai</option>
              <option>Kolkata</option>
            </select>
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              College State
            </label>
            <select id="inputState" className="form-select">
              <option selected>Enter college state</option>
              <option>Mumbai</option>
              <option>Kolkata</option>
            </select>
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              College City
            </label>
            <select id="inputState" className="form-select">
              <option selected>Enter College City</option>
              <option>Mumbai</option>
              <option>Kolkata</option>
            </select>
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              College Agency
            </label>
            <select id="inputState" className="form-select">
              <option selected>Enter College City</option>
              <option>Mumbai</option>
              <option>Kolkata</option>
            </select>
          </div>
          <div className="col-md-6 form mt-5">
            <label
              htmlFor="inputState"
              className="form-label profile_label2"
            ></label>
            <select id="inputState" className="form-select">
              <option selected>Enter College City</option>
              <option>Mumbai</option>
              <option>Kolkata</option>
            </select>
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              College NAAC Grade
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter college state"
            />
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              College Status
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter College City"
            />
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              College Logo
            </label>
            <input
              type="file"
              className="form-control"
              id="inputGroupFile04"
              aria-describedby="inputGroupFileAddon04"
              aria-label="Upload"
            />
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              College Image
            </label>
            <input
              type="file"
              className="form-control"
              id="inputGroupFile04"
              aria-describedby="inputGroupFileAddon04"
              aria-label="Upload"
            />
          </div>

          <div className="d-flex justify-content-center align-items-center mb-5">
            <button
              type="submit"
              className="  profile_btn"
              style={{ width: 220 }}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default College;
