import React from "react";
import img from "../../../img/colleges-inner-page/Icon ionic-md-school.png";
import img2 from "../../../img/colleges-inner-page/Group 737.png";
import img3 from "../../../img/colleges-inner-page/Icon awesome-star.png";
import img4 from "../../../img/colleges-inner-page/Icon ionic-ios-time.png";
import img5 from "../../../img/colleges-inner-page/Icon awesome-book-open.png";
import img6 from "../../../img/colleges-inner-page/Group 728.png";
import img7 from "../../../img/colleges-inner-page/Icon ionic-md-school.png";
import img8 from "../../../img/colleges-inner-page/Icon material-timelapse.png";
import "./Courses.css";

const Courses = () => {
  return (
    <div>
      <div className="row p-0">
        <nav className="col-md-2 side p-0" id="myScrollspy">
          <div className="announcement_sm">
            <ul
              className="nav nav-pills nav-stacked"
              data-offset-top={10}
              style={{ background: "unset" }}
            >
              <li className="active">
                <a href="#">B.ED</a>
              </li>
              <li>
                <a href="#section_courses1">B.MLT</a>
              </li>
              <li>
                <a href="#section_courses2">M.ED</a>
              </li>
              <li>
                <a href="#section_courses3">BCS</a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="col-md-10 rightSide px-lg-5">
          <div id="#">
            <div className="left_card2_course">
              <div className="p-lg-3 p-2 text-start content">
                <div className="d-flex justify-content-between">
                  <h6 className="bachelor">
                    Bachelors of Science [B.Sc](Microbiology)
                  </h6>
                  <div className="d-flex">
                    <a className="star" style={{ marginTop: 7 }}></a>
                    <span className="ms-2">Total Fees</span>

                    <p className="mx-5">₹ 30,000</p>
                    <button
                      className="btn btn-outline-dark mt-lg-0 mt-3"
                      type="submit"
                      style={{
                        width: "80px",
                        height: 40,
                        marginTop: -12,
                      }}
                    >
                      GEN
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-between my-5 my-lg-2">
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img4} alt="" />
                      <span> 2 Yrs</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img5} alt="" />
                      <span> Degree</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img6} alt="" />
                      <span> On Campus</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img7} alt="" />
                      <span> Graduation</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img8} alt="" />
                      <span> Full Time </span>
                    </a>
                  </div>

                  <button
                    className="btn btn-outline-dark course_sm_btn"
                    type="submit"
                  >
                    APPLY NOW
                  </button>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img} alt="" />
                      <span> Eligibility</span>
                    </a>
                    <p className="eligibility_text">Graduation</p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img2} alt="" />
                      <span> Exams Accepted</span>
                    </a>
                    <p className="eligibility_text2">JE | JE | JE </p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img3} alt="" />
                      <span> Rank</span>
                    </a>
                    <p className="eligibility_text2">0 by </p>
                  </div>
                </div>
                <p className="mt-4 mt-lg-0">
                  11 Streams- Biological Sciences | Biological Sciences | Bio
                  Sciences | Biological Sciences ...{" "}
                  <span style={{ color: "#1BA643" }}>View more</span>
                </p>
              </div>
            </div>
            <div className="left_card2_course mt-5">
              <div className="p-lg-3 p-2 text-start content">
                <div className="d-flex justify-content-between">
                  <h6 className="bachelor">
                    Bachelors of Science [B.Sc](Microbiology)
                  </h6>
                  <div className="d-flex">
                    <a className="star" style={{ marginTop: 7 }}></a>
                    <span className="ms-2">Total Fees</span>

                    <p className="mx-5">₹ 30,000</p>
                    <button
                      className="btn btn-outline-dark mt-lg-0 mt-3"
                      type="submit"
                      style={{
                        width: "80px",
                        height: 40,
                        marginTop: -12,
                      }}
                    >
                      GEN
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-between my-5 my-lg-2">
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img4} alt="" />
                      <span> 2 Yrs</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img5} alt="" />
                      <span> Degree</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img6} alt="" />
                      <span> On Campus</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img7} alt="" />
                      <span> Graduation</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img8} alt="" />
                      <span> Full Time </span>
                    </a>
                  </div>

                  <button
                    className="btn btn-outline-dark course_sm_btn"
                    type="submit"
                  >
                    APPLY NOW
                  </button>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img} alt="" />
                      <span> Eligibility</span>
                    </a>
                    <p className="eligibility_text">Graduation</p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img2} alt="" />
                      <span> Exams Accepted</span>
                    </a>
                    <p className="eligibility_text2">JE | JE | JE </p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img3} alt="" />
                      <span> Rank</span>
                    </a>
                    <p className="eligibility_text2">0 by </p>
                  </div>
                </div>
                <p className="mt-4 mt-lg-0">
                  11 Streams- Biological Sciences | Biological Sciences | Bio
                  Sciences | Biological Sciences ...{" "}
                  <span style={{ color: "#1BA643" }}>View more</span>
                </p>
              </div>
            </div>
          </div>
          <div id="section_courses1">
            <div className="left_card2_course mt-5">
              <div className="p-lg-3 p-2 text-start content">
                <div className="d-flex justify-content-between">
                  <h6 className="bachelor">
                    Bachelors of Science [B.Sc](Microbiology)
                  </h6>
                  <div className="d-flex">
                    <a className="star" style={{ marginTop: 7 }}></a>
                    <span className="ms-2">Total Fees</span>

                    <p className="mx-5">₹ 30,000</p>
                    <button
                      className="btn btn-outline-dark mt-lg-0 mt-3"
                      type="submit"
                      style={{
                        width: "80px",
                        height: 40,
                        marginTop: -12,
                      }}
                    >
                      GEN
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-between my-5 my-lg-2">
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img4} alt="" />
                      <span> 2 Yrs</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img5} alt="" />
                      <span> Degree</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img6} alt="" />
                      <span> On Campus</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img7} alt="" />
                      <span> Graduation</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img8} alt="" />
                      <span> Full Time </span>
                    </a>
                  </div>

                  <button
                    className="btn btn-outline-dark course_sm_btn"
                    type="submit"
                  >
                    APPLY NOW
                  </button>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img} alt="" />
                      <span> Eligibility</span>
                    </a>
                    <p className="eligibility_text">Graduation</p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img2} alt="" />
                      <span> Exams Accepted</span>
                    </a>
                    <p className="eligibility_text2">JE | JE | JE </p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img3} alt="" />
                      <span> Rank</span>
                    </a>
                    <p className="eligibility_text2">0 by </p>
                  </div>
                </div>
                <p className="mt-4 mt-lg-0">
                  11 Streams- Biological Sciences | Biological Sciences | Bio
                  Sciences | Biological Sciences ...{" "}
                  <span style={{ color: "#1BA643" }}>View more</span>
                </p>
              </div>
            </div>
            <div className="left_card2_course mt-5">
              <div className="p-lg-3 p-2 text-start content">
                <div className="d-flex justify-content-between">
                  <h6 className="bachelor">
                    Bachelors of Science [B.Sc](Microbiology)
                  </h6>
                  <div className="d-flex">
                    <a className="star" style={{ marginTop: 7 }}></a>
                    <span className="ms-2">Total Fees</span>

                    <p className="mx-5">₹ 30,000</p>
                    <button
                      className="btn btn-outline-dark mt-lg-0 mt-3"
                      type="submit"
                      style={{
                        width: "80px",
                        height: 40,
                        marginTop: -12,
                      }}
                    >
                      GEN
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-between my-5 my-lg-2">
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img4} alt="" />
                      <span> 2 Yrs</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img5} alt="" />
                      <span> Degree</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img6} alt="" />
                      <span> On Campus</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img7} alt="" />
                      <span> Graduation</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img8} alt="" />
                      <span> Full Time </span>
                    </a>
                  </div>

                  <button
                    className="btn btn-outline-dark course_sm_btn"
                    type="submit"
                  >
                    APPLY NOW
                  </button>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img} alt="" />
                      <span> Eligibility</span>
                    </a>
                    <p className="eligibility_text">Graduation</p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img2} alt="" />
                      <span> Exams Accepted</span>
                    </a>
                    <p className="eligibility_text2">JE | JE | JE </p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img3} alt="" />
                      <span> Rank</span>
                    </a>
                    <p className="eligibility_text2">0 by </p>
                  </div>
                </div>
                <p className="mt-4 mt-lg-0">
                  11 Streams- Biological Sciences | Biological Sciences | Bio
                  Sciences | Biological Sciences ...{" "}
                  <span style={{ color: "#1BA643" }}>View more</span>
                </p>
              </div>
            </div>
          </div>
          <div id="section_courses2">
            <div className="left_card2_course mt-5">
              <div className="p-lg-3 p-2 text-start content">
                <div className="d-flex justify-content-between">
                  <h6 className="bachelor">
                    Bachelors of Science [B.Sc](Microbiology)
                  </h6>
                  <div className="d-flex">
                    <a className="star" style={{ marginTop: 7 }}></a>
                    <span className="ms-2">Total Fees</span>

                    <p className="mx-5">₹ 30,000</p>
                    <button
                      className="btn btn-outline-dark mt-lg-0 mt-3"
                      type="submit"
                      style={{
                        width: "80px",
                        height: 40,
                        marginTop: -12,
                      }}
                    >
                      GEN
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-between my-5 my-lg-2">
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img4} alt="" />
                      <span> 2 Yrs</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img5} alt="" />
                      <span> Degree</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img6} alt="" />
                      <span> On Campus</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img7} alt="" />
                      <span> Graduation</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img8} alt="" />
                      <span> Full Time </span>
                    </a>
                  </div>

                  <button
                    className="btn btn-outline-dark course_sm_btn"
                    type="submit"
                  >
                    APPLY NOW
                  </button>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img} alt="" />
                      <span> Eligibility</span>
                    </a>
                    <p className="eligibility_text">Graduation</p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img2} alt="" />
                      <span> Exams Accepted</span>
                    </a>
                    <p className="eligibility_text2">JE | JE | JE </p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img3} alt="" />
                      <span> Rank</span>
                    </a>
                    <p className="eligibility_text2">0 by </p>
                  </div>
                </div>
                <p className="mt-4 mt-lg-0">
                  11 Streams- Biological Sciences | Biological Sciences | Bio
                  Sciences | Biological Sciences ...{" "}
                  <span style={{ color: "#1BA643" }}>View more</span>
                </p>
              </div>
            </div>
            <div className="left_card2_course mt-5">
              <div className="p-lg-3 p-2 text-start content">
                <div className="d-flex justify-content-between">
                  <h6 className="bachelor">
                    Bachelors of Science [B.Sc](Microbiology)
                  </h6>
                  <div className="d-flex">
                    <a className="star" style={{ marginTop: 7 }}></a>
                    <span className="ms-2">Total Fees</span>

                    <p className="mx-5">₹ 30,000</p>
                    <button
                      className="btn btn-outline-dark mt-lg-0 mt-3"
                      type="submit"
                      style={{
                        width: "80px",
                        height: 40,
                        marginTop: -12,
                      }}
                    >
                      GEN
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-between my-5 my-lg-2">
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img4} alt="" />
                      <span> 2 Yrs</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img5} alt="" />
                      <span> Degree</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img6} alt="" />
                      <span> On Campus</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img7} alt="" />
                      <span> Graduation</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img8} alt="" />
                      <span> Full Time </span>
                    </a>
                  </div>

                  <button
                    className="btn btn-outline-dark course_sm_btn"
                    type="submit"
                  >
                    APPLY NOW
                  </button>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img} alt="" />
                      <span> Eligibility</span>
                    </a>
                    <p className="eligibility_text">Graduation</p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img2} alt="" />
                      <span> Exams Accepted</span>
                    </a>
                    <p className="eligibility_text2">JE | JE | JE </p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img3} alt="" />
                      <span> Rank</span>
                    </a>
                    <p className="eligibility_text2">0 by </p>
                  </div>
                </div>
                <p className="mt-4 mt-lg-0">
                  11 Streams- Biological Sciences | Biological Sciences | Bio
                  Sciences | Biological Sciences ...{" "}
                  <span style={{ color: "#1BA643" }}>View more</span>
                </p>
              </div>
            </div>
          </div>
          <div id="section_courses3">
            <div className="left_card2_course mt-5">
              <div className="p-lg-3 p-2 text-start content">
                <div className="d-flex justify-content-between">
                  <h6 className="bachelor">
                    Bachelors of Science [B.Sc](Microbiology)
                  </h6>
                  <div className="d-flex">
                    <a className="star" style={{ marginTop: 7 }}></a>
                    <span className="ms-2">Total Fees</span>

                    <p className="mx-5">₹ 30,000</p>
                    <button
                      className="btn btn-outline-dark mt-lg-0 mt-3"
                      type="submit"
                      style={{
                        width: "80px",
                        height: 40,
                        marginTop: -12,
                      }}
                    >
                      GEN
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-between my-5 my-lg-2">
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img4} alt="" />
                      <span> 2 Yrs</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img5} alt="" />
                      <span> Degree</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img6} alt="" />
                      <span> On Campus</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img7} alt="" />
                      <span> Graduation</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img8} alt="" />
                      <span> Full Time </span>
                    </a>
                  </div>

                  <button
                    className="btn btn-outline-dark course_sm_btn"
                    type="submit"
                  >
                    APPLY NOW
                  </button>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img} alt="" />
                      <span> Eligibility</span>
                    </a>
                    <p className="eligibility_text">Graduation</p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img2} alt="" />
                      <span> Exams Accepted</span>
                    </a>
                    <p className="eligibility_text2">JE | JE | JE </p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img3} alt="" />
                      <span> Rank</span>
                    </a>
                    <p className="eligibility_text2">0 by </p>
                  </div>
                </div>
                <p className="mt-4 mt-lg-0">
                  11 Streams- Biological Sciences | Biological Sciences | Bio
                  Sciences | Biological Sciences ...{" "}
                  <span style={{ color: "#1BA643" }}>View more</span>
                </p>
              </div>
            </div>
            <div className="left_card2_course mt-5">
              <div className="p-lg-3 p-2 text-start content">
                <div className="d-flex justify-content-between">
                  <h6 className="bachelor">
                    Bachelors of Science [B.Sc](Microbiology)
                  </h6>
                  <div className="d-flex">
                    <a className="star" style={{ marginTop: 7 }}></a>
                    <span className="ms-2">Total Fees</span>

                    <p className="mx-5">₹ 30,000</p>
                    <button
                      className="btn btn-outline-dark mt-lg-0 mt-3"
                      type="submit"
                      style={{
                        width: "80px",
                        height: 40,
                        marginTop: -12,
                      }}
                    >
                      GEN
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-between my-5 my-lg-2">
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img4} alt="" />
                      <span> 2 Yrs</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img5} alt="" />
                      <span> Degree</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img6} alt="" />
                      <span> On Campus</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img7} alt="" />
                      <span> Graduation</span>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility_start">
                      <img src={img8} alt="" />
                      <span> Full Time </span>
                    </a>
                  </div>

                  <button
                    className="btn btn-outline-dark course_sm_btn"
                    type="submit"
                  >
                    APPLY NOW
                  </button>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img} alt="" />
                      <span> Eligibility</span>
                    </a>
                    <p className="eligibility_text">Graduation</p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img2} alt="" />
                      <span> Exams Accepted</span>
                    </a>
                    <p className="eligibility_text2">JE | JE | JE </p>
                  </div>
                  <div className="d-flex">
                    <a className="eligibility">
                      <img src={img3} alt="" />
                      <span> Rank</span>
                    </a>
                    <p className="eligibility_text2">0 by </p>
                  </div>
                </div>
                <p className="mt-4 mt-lg-0">
                  11 Streams- Biological Sciences | Biological Sciences | Bio
                  Sciences | Biological Sciences ...{" "}
                  <span style={{ color: "#1BA643" }}>View more</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
