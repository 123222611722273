import React from "react";
import img from "../../../img/corporate/Icon feather-thumbs-up-1.png";
import img2 from "../../../img/corporate/Icon awesome-eye.png";
import img3 from "../../../img/corporate/Icon material-file-download.png";
import img4 from "../../../img/corporate/Icon ionic-md-thumbs-up.png";
import img5 from "../../../img/corporate/Icon material-file-download.png";

import "./RightSide.css";
const RightSide = () => {
  return (
    <div className="corporate_bg">
      <div>
        <h6 className="corporate_header">
          SHORT TRICKS /{" "}
          <span className="corporate_header_other">Reasoning Short Tricks</span>
        </h6>
        <div className="row corporate_card mx-2 mt-3 p-3">
          <div className="col-md-7 ">
            <h4 className="corporate_h4">Topic Name Here</h4>
            <div className="d-flex ">
              <a href="" className="corporate_span_like">
                <img src={img} alt="" />
                <span>14 Likes</span>
              </a>
              <a href="" className="corporate_span_like mx-5">
                <img src={img2} alt="" />
                <span>12 Views</span>
              </a>
              <a href="" className="corporate_span_like">
                <img src={img3} alt="" />
                <span>140 Downloads</span>
              </a>
            </div>
          </div>
          <div className="col-md-5">
            <button className="corporate_btn">
              <a href="" style={{ textDecoration: "none" }}>
                <img src={img4} alt="" />
                <span className="corporate_btn_icon">Like</span>
              </a>
            </button>
            <button className="corporate_btn2 ms-4">
              <a href="" style={{ textDecoration: "none" }}>
                <img src={img3} alt="" />
                <span className="corporate_btn_icon">Download</span>
              </a>
            </button>
          </div>
        </div>
        <div className="row corporate_card mx-2 mt-3 p-3">
          <div className="col-md-7 ">
            <h4 className="corporate_h4">Topic Name Here</h4>
            <div className="d-flex ">
              <a href="" className="corporate_span_like">
                <img src={img} alt="" />
                <span>14 Likes</span>
              </a>
              <a href="" className="corporate_span_like mx-5">
                <img src={img2} alt="" />
                <span>12 Views</span>
              </a>
              <a href="" className="corporate_span_like">
                <img src={img3} alt="" />
                <span>140 Downloads</span>
              </a>
            </div>
          </div>
          <div className="col-md-5">
            <button className="corporate_btn">
              <a href="" style={{ textDecoration: "none" }}>
                <img src={img4} alt="" />
                <span className="corporate_btn_icon">Like</span>
              </a>
            </button>
            <button className="corporate_btn2 ms-4">
              <a href="" style={{ textDecoration: "none" }}>
                <img src={img3} alt="" />
                <span className="corporate_btn_icon">Download</span>
              </a>
            </button>
          </div>
        </div>
        <div className="corporate_pdf">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div>
              <h6 className="corporate_pdf_text">PDF WILL SHOW HERE</h6>
              <h6 className="corporate_pdf_text">PDF WILL SHOW HERE</h6>
              <h6 className="corporate_pdf_text">PDF WILL SHOW HERE</h6>
              <h6 className="corporate_pdf_text">PDF WILL SHOW HERE</h6>
              <h6 className="corporate_pdf_text">PDF WILL SHOW HERE</h6>
              <h6 className="corporate_pdf_text">PDF WILL SHOW HERE</h6>
              <h6 className="corporate_pdf_text">PDF WILL SHOW HERE</h6>
              <h6 className="corporate_pdf_text">PDF WILL SHOW HERE</h6>
              <h6 className="corporate_pdf_text">PDF WILL SHOW HERE</h6>
            </div>
          </div>
        </div>
        <div className="row corporate_card mx-2 mt-3 p-3 mb-5">
          <div className="col-md-7 ">
            <h4 className="corporate_h4">Topic Name Here</h4>
            <div className="d-flex ">
              <a href="" className="corporate_span_like">
                <img src={img} alt="" />
                <span>14 Likes</span>
              </a>
              <a href="" className="corporate_span_like mx-5">
                <img src={img2} alt="" />
                <span>12 Views</span>
              </a>
              <a href="" className="corporate_span_like">
                <img src={img3} alt="" />
                <span>140 Downloads</span>
              </a>
            </div>
          </div>
          <div className="col-md-5">
            <button className="corporate_btn">
              <a href="" style={{ textDecoration: "none" }}>
                <img src={img4} alt="" />
                <span className="corporate_btn_icon">Like</span>
              </a>
            </button>
            <button className="corporate_btn2 ms-4">
              <a href="" style={{ textDecoration: "none" }}>
                <img src={img3} alt="" />
                <span className="corporate_btn_icon">Download</span>
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSide;
