import React from "react";
import LeftSide from "../../components/Corporate/Left/LeftSide";
import RightSide from "../../components/Corporate_main_library/Right/RightSide";

const Corporate_main_library = () => {
  return (
    <div className="container-fluid">
      {/* <Navigation /> */}
      <div className="row">
        <div className="col-md-3 p-5" style={{ background: "#EEEEEE" }}>
          <LeftSide />
        </div>
        <div className="col-md-9">
          <RightSide />
        </div>
      </div>
    </div>
  );
};

export default Corporate_main_library;
