import React from "react";
import img from "./../../img/review/Group 1210.png";
import img2 from "./../../img/review/Icon awesome-question-circle.png";
import "./Review.css";
const Review = () => {
  return (
    <div className="container-fluid review_main">
      <div className="review_main">
        <h4 className="profile_header my-4">How was your experience?</h4>
        <form className="row g-3">
          <div className="col-md-8 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              Choose College
            </label>
            <select id="inputState" className="form-select">
              <option selected>Choose College</option>
              <option>Mumbai</option>
              <option>Kolkata</option>
            </select>
          </div>
          <div className="col-md-4">
            <h4
              className="profile_header2 my-4"
              style={{ textDecoration: "none" }}
            >
              {" "}
              Can't find my college
            </h4>
            <div className="question">
              <img src={img2} alt="" />
            </div>
          </div>
          <div className="col-md-8 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              Choose Course
            </label>
            <select id="inputState" className="form-select">
              <option selected>Choose Course</option>
              <option>Mumbai</option>
              <option>Kolkata</option>
            </select>
          </div>

          <h4 className="profile_header2 my-4">Basic Info</h4>
          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Name
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter Name"
            />
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              Gender
            </label>
            <select id="inputState" className="form-select">
              <option selected>Select your Gender</option>
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Noor@gmail.com"
            />
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Mobile Number
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="1244555"
            />
          </div>
          <h4 className="profile_header2 my-4">College Info</h4>

          <div className="col-md-6 form">
            <label
              htmlFor="inputPassword4"
              className="form-label profile_label2"
            >
              Enrollment Year
            </label>
            <input
              type="password"
              className="form-control"
              id="inputPassword4"
              placeholder="Enrollment Year"
            />
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Program Fees
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter program fees"
            />
          </div>
          <div className="col-md-12 form">
            <label
              htmlFor="inputPassword4"
              className="form-label profile_label2"
            >
              Rate your college based on overall experience
            </label>
            <br />
          </div>
          <div className="text-start">
            <img src={img} alt="" />
          </div>
          <div className="col-md-6 form">
            <label
              htmlFor="inputPassword4"
              className="form-label profile_label2"
            >
              Review Title
            </label>
            <input
              type="password"
              className="form-control"
              id="inputPassword4"
              placeholder="Give a nice title to your review.."
            />
          </div>
          <div className="col-md-12 form2">
            <label
              htmlFor="inputPassword4"
              className="form-label profile_label3"
            >
              Describe your college here
            </label>
            <input
              type="password"
              className="form-control"
              id="inputPassword4"
              placeholder="Please share the reason to join this college and share your experiences in this college..."
            />
          </div>

          <div className="d-flex justify-content-center align-items-center mb-5">
            <button
              type="submit"
              className="  profile_btn"
              style={{ width: 220 }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Review;
