import React from "react";
import LeftSide from "../../components/Home/LeftSide/LeftSide";
import MainPart from "../../components/Home/MainPart/MainPart";
import RightSide from "../../components/Home/RightSide/RightSide";

import "./Home.css";
const Home = () => {
  return (
    <div className="container-fluid" style={{ background: "#EEEEEE" }}>
      {/* <Navigation /> */}
      <div className="row">
        <div className="col-lg-3 col-md-12" style={{ marginTop: 60 }}>
          <LeftSide />
        </div>
        <div className="col-lg-6 col-md-12">
          <MainPart />
        </div>
        <div className="col-lg-3 col-md-12" style={{ marginTop: 60 }}>
          <RightSide />
        </div>
      </div>
    </div>
  );
};

export default Home;
