import React from "react";
import ReactTextCollapse from "../../Exam_inner/About/ReactTextCollapse";

const TEXT_COLLAPSE_OPTIONS = {
  collapse: false,
  collapseText: "... show more",
  expandText: "show less",
  minHeight: 100,
  maxHeight: 200,
  textStyle: {
    color: "#64C280",
    fontSize: "18px",
  },
};
const Placement = () => {
  return (
    <div>
      <div className="row" style={{ background: "#EEEEEE" }}>
        <nav className="col-md-2 side p-0 " id="myScrollspy">
          <div className="announcement_sm">
            <ul
              className="nav nav-pills nav-stacked"
              data-offset-top={10}
              style={{ background: "unset" }}
            >
              <li className="active">
                <a href="#">BASIC INFORMATION</a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="col-md-10 col-12 rightSide">
          <div className="p-lg-5 text-start content">
            <h3 className="text-start content_title">BASIC INFORMATION</h3>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
              <p className="content_text2">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                invidunt ut labore et
              </p>
            </ReactTextCollapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Placement;
