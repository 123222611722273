import React from "react";
import "./Mock_instruction.css";
const Mock_instruction = () => {
  return (
    <div>
      <div className="mock_instruction_header_bg">
        <p className="mock_instruction_header p-lg-5">
          {" "}
          TEST-1 <span>/Reasoning/Topic Name</span>
        </p>
      </div>
      <div className="p-5 mt-5">
        <h6 className="mock_instruction_text">Instructions</h6>
        <p className="mock_instruction_text_p">
          1- The test consistes of 50 questions. Each question carry equal marks
        </p>
        <p className="mock_instruction_text_p">
          2- Total duration for the test is 120 minutes.
        </p>
        <p className="mock_instruction_text_p">
          3- Each questions have five options. Only one option is correct.
        </p>
        <p className="mock_instruction_text_p">
          4- Each questions have five options. Only one option is correct.
        </p>
        <p className="mock_instruction_text_p">
          5- No marks will be deducted for unanswered questions.
        </p>
      </div>
    </div>
  );
};

export default Mock_instruction;
