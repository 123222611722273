import React, { useState } from "react";
import { Link } from "react-router-dom";
import img from "../../../img/icon/Group 1.jpg";
import img1 from "../../../img/icon/Group 23.png";
import img2 from "../../../img/icon/Group 3.png";
import img3 from "../../../img/icon/Group 25.png";
import img4 from "../../../img/icon/Group 5.png";
import img5 from "../../../img/icon/Group 34.png";
import img6 from "../../../img/icon/Group 35.png";
import img7 from "../../../img/home/Rectangle 560.png";
import img8 from "../../../img/home/Ellipse 120.png";
import img9 from "../../../img/home/Group 23.png";
import img10 from "../../../img/home/Group 3.png";
import img11 from "../../../img/home/Group 25.png";

import { UilSearch } from "@iconscout/react-unicons";
import "./Navbar.css";
import axios from "axios";
import { useEffect } from "react";

const text = {
  textTransform: "initial",
  color: "#0F0C1A",
  fontWeight: 600,
};

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const handleSearch = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  const [data3, setData3] = useState([]);
  const loadPosts3 = async () => {
    try {
      const response = await axios.get(
        "https://educationapi.pino10.shop/api/notification"
      );
      setData3(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loadPosts3();
  }, []);
  return (
    <div>
      <nav className="navbar navbar-expand-lg menu nav_all">
        <div className="container-fluid mx-lg-5">
          <div className="main_sm_menu">
            <div className="menu_option2 d-flex">
              <div style={{ marginTop: 7 }}>
                <img src={img8} alt="" />
              </div>
              <div>
                <form className="ms-2" role="search">
                  <div className="d-flex">
                    <div className="logoSearch" style={{ marginRight: 10 }}>
                      <div className="search">
                        <input
                          type="search"
                          name="search"
                          placeholder="search here"
                        />
                        <div className="s-icon">
                          <UilSearch />
                        </div>
                      </div>
                    </div>

                    <button
                      type="button"
                      className="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      style={{ marginTop: -7 }}
                    >
                      <img src={img6} alt="" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div style={{ marginTop: 7 }}>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>

          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <a className="navbar-brand logo" href="#">
              LOGO
            </a>

            <ul
              className="navbar-nav  mb-2 mb-lg-0 py-lg-3 navbar_main indicator"
              style={{ width: 400 }}
            >
              <li className="nav-item navbar_main_li">
                <Link to={"/"} className="menu_li">
                  <img className="menu_img" src={img} alt="" />{" "}
                  <a
                    className="nav-link active ms-lg-4"
                    aria-current="page"
                    style={text}
                  >
                    Home
                  </a>
                </Link>
              </li>
              <li className="nav-item mx-lg-5">
                <Link to={"college"} className="menu_li">
                  <img src={img1} alt="" />

                  <a className="nav-link" href="colleges" style={text}>
                    Colleges
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"courses"} className="menu_li">
                  <img src={img2} alt="" />

                  <a className="nav-link " style={text}>
                    Courses
                  </a>
                </Link>
              </li>
              <li className="nav-item mx-lg-5">
                <Link to={"exams"} className="menu_li">
                  <img src={img3} alt="" />

                  <a className="nav-link ms-lg-3" style={text}>
                    Exams
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"corporate"} className="menu_li">
                  <img src={img4} alt="" />

                  <a className="nav-link " style={text}>
                    Corporate
                  </a>
                </Link>
              </li>
            </ul>
          </div>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            style={{ width: 260 }}
          >
            <div className="offcanvas-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end  pe-3">
                <div className="d-flex mb-3">
                  <div>
                    <img className="menu_img_sm" src={img} alt="" />
                  </div>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle menu_img_sm_text "
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="dropdown_menu"> Home</span>
                    </a>
                    <ul
                      className="dropdown-menu mt-3"
                      style={{ border: "none" }}
                    >
                      <Link to={"/home_announcement"}>
                        <li>
                          <a className="dropdown-item p-0" href="#">
                            <span className="dropdown_text"> Hirings </span>
                          </a>
                        </li>
                      </Link>
                      <Link to={"/home_announcement"}>
                        <li>
                          <a className="dropdown-item p-0" href="#">
                            <span className="dropdown_text">
                              {" "}
                              Announcements{" "}
                            </span>
                          </a>
                        </li>
                      </Link>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Questions </span>
                        </a>
                      </li>
                      <Link to={"/review"}>
                        <li>
                          <a className="dropdown-item p-0" href="#">
                            <span className="dropdown_text">
                              {" "}
                              Write a review
                            </span>
                          </a>
                        </li>
                      </Link>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text">Learning links</span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Question bank </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </div>
                <div className="d-flex mb-3">
                  <div>
                    <img className="menu_img_sm" src={img9} alt="" />
                  </div>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle menu_img_sm_text "
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="dropdown_menu">Colleges</span>
                    </a>
                    <ul
                      className="dropdown-menu mt-3"
                      style={{ border: "none" }}
                    >
                      <Link to={"/college"}>
                        <li>
                          <a className="dropdown-item p-0" href="#">
                            <span className="dropdown_text"> Colleges </span>
                          </a>
                        </li>
                      </Link>

                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Announcements </span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Questions </span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Write a review</span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text">Learning links</span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Question bank </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </div>
                <div className="d-flex mb-3">
                  <div>
                    <img className="menu_img_sm" src={img10} alt="" />
                  </div>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle menu_img_sm_text"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="dropdown_menu"> Courses</span>
                    </a>
                    <ul
                      className="dropdown-menu mt-3"
                      style={{ border: "none" }}
                    >
                      <Link to={"/courses"}>
                        <li>
                          <a className="dropdown-item p-0" href="#">
                            <span className="dropdown_text"> Courses </span>
                          </a>
                        </li>
                      </Link>

                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Announcements </span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Questions </span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Write a review</span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text">Learning links</span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Question bank </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </div>
                <div className="d-flex mb-3">
                  <div>
                    <img className="menu_img_sm" src={img11} alt="" />
                  </div>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle menu_img_sm_text"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="dropdown_menu">Exam</span>
                    </a>
                    <ul
                      className="dropdown-menu mt-3"
                      style={{ border: "none" }}
                    >
                      <Link to={"/exams"}>
                        <li>
                          <a className="dropdown-item p-0" href="#">
                            <span className="dropdown_text"> Exams </span>
                          </a>
                        </li>
                      </Link>
                    </ul>
                  </li>
                </div>
                <div className="d-flex mb-3">
                  <div>
                    <img className="menu_img_sm" src={img} alt="" />
                  </div>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle menu_img_sm_text"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="dropdown_menu">Corporate</span>
                    </a>
                    <ul
                      className="dropdown-menu mt-3"
                      style={{ border: "none" }}
                    >
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Hirings </span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Announcements </span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Questions </span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Write a review</span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text">Learning links</span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item p-0" href="#">
                          <span className="dropdown_text"> Question bank </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </div>
              </ul>
              <form className="d-flex mt-3" role="search">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button className="btn btn-outline-success" type="submit">
                  Search
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="menu_option">
          <form className="d-flex  px-2" role="search">
            <div className="mt-2 d-flex">
              <button
                type="button"
                className="btn "
                data-bs-toggle="modal"
                data-bs-target="#exampleModal3"
              >
                <img className="search_img" src={img5} alt="" />
              </button>

              <button
                type="button"
                className="btn "
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <img src={img6} alt="" />
              </button>
            </div>

            <button
              className="btn btn-outline-dark mx-lg-5 px-lg-4 get_app "
              type="submit"
              style={{ width: "120px" }}
            >
              Get App
            </button>

            <Link to={"/login"} style={{ textDecoration: "none" }}>
              <a
                className="btn ms-2"
                href="/login"
                style={{
                  background: "#463196",
                  color: "white",
                  width: "120px",
                  padding: "12px 0",
                }}
                type="submit"
              >
                Log In
              </a>
            </Link>
          </form>
        </div>
      </nav>

      <div>
        {/* Modal */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog navbar_modal">
            <div className="modal-content">
              <div className="modal-header">
                <h1
                  className="modal-title fs-5 modal_header"
                  id="exampleModalLabel"
                >
                  NOTIFICATIONS
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                {data3.map((data, index) => (
                  <>
                    <div className="d-flex mt-4" key={index}>
                      <img
                        src={img7}
                        className="mt-2"
                        alt=""
                        style={{ width: 10, height: 10 }}
                      />
                      <a href="#" className="text2" style={{ width: "70%" }}>
                        <span className="ms-2">
                          {data.description ? data.description : ""}
                        </span>
                      </a>
                      <p className="text2 ms-5 text-start">
                        {data.date ? data.date.slice(0, 10) : ""}
                      </p>
                    </div>
                    <hr />
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal3"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="search_menu_modal">
              <div className="modal-content">
                <div className="logoSearch_navbar ">
                  <div className="search_navbar">
                    <input type="text" placeholder="Search here" />
                    <div className="s-icon_navbar">
                      <Link to={"/search"}>
                        <UilSearch />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
