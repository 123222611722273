import React, { useEffect, useState } from "react";
import { UilSearch } from "@iconscout/react-unicons";
import img from "../../img/icon/pexels-pixabay-207684@2x.png";
import img1 from "../../img/icon/Rectangle 343.png";
import img2 from "../../img/icon/pexels-hai-nguyen-1699414.png";
import img3 from "../../img/icon/pexels-karolina-grabowska-8106684.png";
import img4 from "../../img/icon/pexels-olya-kobruseva-8177922.png";
import img5 from "../../img/icon/pexels-olya-kobruseva-8283960.png";
import img6 from "../../img/icon/pexels-abdul-aziz-10459963.png";
import img22 from "../../img/home/icons8-slider-50 (1).png";
import "./Course.css";
import axios from "axios";
import { Link } from "react-router-dom";
const Course = () => {
  const [searchInput, setSearchInput] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const loadPosts = async () => {
    try {
      const response = await axios.get(
        "https://educationapi.pino10.shop/api/course"
      );
      setAPIData(response.data);
      setFilteredResults(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loadPosts();
  }, []);
  useEffect(() => {
    const result = APIData.filter((item) => {
      return item.name.toLowerCase().match(searchInput.toLowerCase());
    });
    setFilteredResults(result);
  }, [searchInput]);

  const [courseTrend, setCourseTrend] = React.useState("all");
  React.useEffect(() => {
    if (courseTrend === "all") {
      setFilteredResults(APIData);
    } else {
      const filterExamMode = APIData.filter(
        (member) => member.name === courseTrend
      );
      setFilteredResults(filterExamMode);
    }
  }, [courseTrend]);

  const [examMode, setExamMode] = React.useState("all");
  React.useEffect(() => {
    if (examMode === "all") {
      setFilteredResults(APIData);
    } else {
      const filterExamMode = APIData.filter(
        (member) => member.name === examMode
      );
      setFilteredResults(filterExamMode);
    }
  }, [examMode]);

  return (
    <div>
      <div className="row">
        <div
          className="col-md-3 p-5 announcement_sm"
          style={{ background: "#EEEEEE" }}
        >
          <div>
            <div className="logoSearch">
              <div className="search">
                <input
                  type="search"
                  name="search"
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <div className="s-icon">
                  <UilSearch />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <h6>Found {filteredResults.length} Course</h6>
              <a href="" style={{ color: "black" }}>
                See Default
              </a>{" "}
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div>
            <div className="announcement_sm">
              <h1
                className="my-5 right_header font_family_h1"
                style={{ color: "#463196" }}
              >
                CHOOSE BY YOUR LEVEL
              </h1>

              <div className="row row-cols-1 row-cols-md-3 g-4 my-5 ">
                <div className="col">
                  <div className="card">
                    <div
                      className="card text-bg-dark"
                      style={{ backgroundImage: `url(${img})` }}
                    >
                      <img
                        src={img1}
                        className="card-img"
                        alt="..."
                        style={{ opacity: 1, backgroundBlendMode: "darken" }}
                      />
                      <div className="card-img-overlay">
                        <h6
                          className="card-text p-5"
                          style={{ textTransform: "capitalize" }}
                        >
                          10Th, 12Th LEVEL DIPLOMA & CERTIFICATION COURSES
                        </h6>
                      </div>
                    </div>
                    <div className="card-body">
                      <h2 className="card-text my-4 course_h2">
                        No Courses Available
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="card text-bg-dark">
                      <img src={img2} className="card-img" alt="..." />
                      <div className="card-img-overlay">
                        <h6 className="card-text p-5">
                          GRADUATION LEVEL DEGREE, DIPLOMA & CERTIFICATION
                          COURSES
                        </h6>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="d-flex courses_card_btn">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            07
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            MEDICAL
                          </button>
                        </div>
                        <div
                          className="btn-group mx-3"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            07
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            LAW
                          </button>
                        </div>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            15
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            COMMERCE
                          </button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between my-2 courses_card_btn">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            07
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            ENGINEERING
                          </button>
                        </div>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            12
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            ARTS
                          </button>
                        </div>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            12
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            SCIENCE
                          </button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            07
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            ARCHITECTURE
                          </button>
                        </div>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            07
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            PARAMEDICAL
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="card text-bg-dark">
                      <img src={img3} className="card-img " alt="..." />
                      <div className="card-img-overlay">
                        <h6 className="card-text p-5">
                          10Th, 12Th LEVEL DIPLOMA & CERTIFICATION COURSES
                        </h6>
                      </div>
                    </div>
                    <div className="card-body">
                      <h2 className="card-text my-4 course_h2">
                        No Courses Available
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="card text-bg-dark">
                      <img src={img4} className="card-img" alt="..." />
                      <div className="card-img-overlay">
                        <h6 className="card-text p-5">
                          10Th, 12Th LEVEL DIPLOMA & CERTIFICATION COURSES
                        </h6>
                      </div>
                    </div>
                    <div className="card-body">
                      <h2 className="card-text my-4 course_h2">
                        No Courses Available
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="card text-bg-dark">
                      <img src={img5} className="card-img" alt="..." />
                      <div className="card-img-overlay">
                        <h6 className="card-text p-5">
                          GRADUATION LEVEL DEGREE, DIPLOMA & CERTIFICATION
                          COURSES
                        </h6>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-between courses_card_btn">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            07
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            MEDICAL
                          </button>
                        </div>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            07
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            LAW
                          </button>
                        </div>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            15
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            COMMERCE
                          </button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between my-2 courses_card_btn">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            07
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            ENGINEERING
                          </button>
                        </div>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            12
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            ARTS
                          </button>
                        </div>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            12
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            SCIENCE
                          </button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            07
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            ARCHITECTURE
                          </button>
                        </div>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#C8C1DF" }}
                          >
                            07
                          </button>
                          <button
                            type="button"
                            className="btn"
                            style={{ background: "#fffff" }}
                          >
                            PARAMEDICAL
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="card text-bg-dark">
                      <img src={img6} className="card-img" alt="..." />
                      <div className="card-img-overlay">
                        <h6 className="card-text p-5">
                          10Th, 12Th LEVEL DIPLOMA & CERTIFICATION COURSES
                        </h6>
                      </div>
                    </div>
                    <div className="card-body">
                      <h2 className="card-text my-4 course_h2">
                        No Courses Available
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h1
              className="my-5 right_header font_family_h1"
              style={{ color: "#463196" }}
            >
              LIST OF POPULAR COURSES IN INDIA
            </h1>
            <div className="exam_sm">
              <div className="d-flex px-3">
                <div className="logoSearch ">
                  <div className="search">
                    <input
                      type="text"
                      placeholder="Search here"
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <div className="s-icon">
                      <UilSearch />
                    </div>
                  </div>
                </div>
                <div>
                  {/* Button trigger modal */}
                  <img
                    src={img22}
                    alt=""
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal2"
                    className="ms-2"
                    style={{ cursor: "pointer" }}
                  />

                  <div
                    className="modal fade"
                    id="exampleModal2"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel2"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-fullscreen">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="exampleModalLabel2"
                            style={{ color: "#7060AC", fontWeight: "bold" }}
                          >
                            Filters
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>

                        <div className="modal-footer">
                          <div className="d-flex justify-content-between mt-3 w-100 px-3">
                            <h6 className="fs-4" style={{ color: "#504E59" }}>
                              Found {filteredResults.length} Courses
                            </h6>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              style={{ background: "#463196", width: 90 }}
                              data-bs-dismiss="modal"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {filteredResults.map((item) => (
              <div className="px-lg-5">
                <div className="popular exam_a">
                  <Link to={"/courses_inner"}>
                    <div className="row row-cols-1 row-cols-md-2 my-4 course_card">
                      <div className="col py-lg-5 px-lg-5 px-3">
                        <h4 className=" course_sm_name">{item.name}</h4>
                        <div className="d-flex px-lg-0 px-4">
                          <div className="d-flex">
                            <div className="p-2">
                              <p className="star"></p>
                            </div>
                            <p className="mx-1">3 Yrs</p>
                          </div>
                          <div className="d-flex ms-5">
                            <div className="p-2">
                              <p className="star"></p>
                            </div>
                            <p className="mx-1">Full Time</p>
                          </div>
                        </div>
                        <div className="exam_sm w-100">
                          <a href="">12 Colleges Offering This Course</a>
                        </div>
                        <div className="announcement_sm">
                          <div className="d-flex justify-content-between w-75">
                            <a href="">Overview</a>

                            <a href="">Overview</a>
                            <a href="">Overview</a>
                          </div>
                        </div>
                      </div>
                      <div className="col py-lg-5 px-lg-5 ">
                        <div className="course_average_part">
                          <div className="d-flex justify-content-between ">
                            <div className="d-flex">
                              <div className="p-2">
                                <p className="star"></p>
                              </div>
                              <div className="announcement_sm">
                                <h4 className="mx-1 ">Average Fees</h4>
                              </div>
                              <div className="exam_sm">
                                <p className="mx-1 ">Average Fees</p>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="p-2">
                                <p className="star2"></p>
                              </div>
                              <div className="announcement_sm">
                                <h4 className="mx-1">Average Salary</h4>
                              </div>
                              <div className="exam_sm">
                                <p className="mx-1 ">Average Salary</p>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between w-75 ms-3">
                            <p className="ms-lg-0 ms-4">₹ 30,000</p>
                            <p className="ms-lg-0 ms-5">₹ 30,000</p>
                          </div>
                          <div className="announcement_sm">
                            <button
                              className="btn btn-outline-dark"
                              type="submit"
                              style={{
                                width: "100%",
                              }}
                            >
                              12 Colleges Offering This Course
                            </button>
                          </div>
                          <div className="exam_sm">
                            <div className="d-flex justify-content-between">
                              <a href="">Overview</a>

                              <a href="">Overview</a>
                              <a href="">Overview</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
