import React from "react";

const Step1 = () => {
  return (
    <div>
      <div className="registration_div">
        <form className="row g-3">
          <div className="col-md-6 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              Select User Type
            </label>
            <select id="inputState" className="form-select">
              <option selected>Student</option>
              <option>A</option>
              <option>B</option>
            </select>
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Name
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter Full name"
            />
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Designation
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Student"
            />
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter your Email"
            />
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Mobile Number
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter Mobile Number"
            />
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              State
            </label>
            <select id="inputState" className="form-select">
              <option selected>Choose state</option>
              <option>A</option>
              <option>B</option>
            </select>
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              School/College/Company
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="School/College/Company"
            />
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Highest Education
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter your highest qualification"
            />
          </div>
          <div className="col-md-12 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              Summary
            </label>
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue="Summary"
              />
            </div>
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              Area of Expertise
            </label>
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue="Expertise area in comma separated value"
              />
            </div>
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              Accomplishments
            </label>
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue="Accomplishments in comma separated value"
              />
            </div>
          </div>

          <div className="col-md-7 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Total Experience
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter your total experience"
            />
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Profile Photo
            </label>
            <input
              type="file"
              className="form-control"
              id="inputGroupFile04"
              aria-describedby="inputGroupFileAddon04"
              aria-label="Upload"
            />
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Cover Photo
            </label>
            <input
              type="file"
              className="form-control"
              id="inputGroupFile04"
              aria-describedby="inputGroupFileAddon04"
              aria-label="Upload"
            />
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="inputEmail4"
              placeholder="Password"
            />
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="inputEmail4"
              placeholder="Re-Enter Password"
            />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-5">
            <button
              type="submit"
              className="  profile_btn"
              style={{ width: 220 }}
            >
              Next Step
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Step1;
