import React from "react";
import "./All.css";
import img from "../../../../img/search/Group 1279.png";
const All = () => {
  return (
    <div>
      <div className="row">
        {/* Card 1 */}
        <input type="checkbox" style={{ display: "none" }} id="card-1" />
        <label className="col-md-4 card-container" htmlFor="card-1">
          <div className="card-flip">
            {/* Card 1 Front */}
            <div className="card front">
              <div>
                <img
                  src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  className="card-img-top all_img"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex">
                    <div className="img_main">
                      <img src={img} alt="" />
                    </div>
                    <div>
                      <p className="all_p_text1">Khushpreet</p>
                      <p className="all_p_text2">
                        College Kolkata, West Bengal
                      </p>
                      <div className="d-flex">
                        <p className="all_p_text3">Total Links</p>
                        <p className="all_p_text4">142</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <button className="btn_all_1">Linked</button>
                  <button className="btn_all_2">Message</button>
                  <button className="btn_all_3">Posts</button>
                </div>
              </div>
            </div>
            {/* End Card 1 Front */}
            {/* Card 1 Back */}
            <div className="card back">
              <div className="card-block">
                <button className="flip_btn mt-3">Profile</button>
                <h6 className="mt-2">Khushpreet</h6>
                <div className="text-start mt-3 p-2" style={{ float: "left" }}>
                  <h5 className="flip_text_h5">Area of Expertise-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                  <h5 className="mt-4 flip_text_h5">Highest education-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                </div>
              </div>
            </div>
            {/* End Card 1 Back */}
          </div>
        </label>
        {/* End Card 1 */}
        {/* Card 2 */}
        <input type="checkbox" style={{ display: "none" }} id="card-2" />
        <label className="col-md-4 card-container" htmlFor="card-2">
          <div className="card-flip">
            {/* Card 1 Front */}
            <div className="card front">
              <div style={{ width: "100%" }}>
                <img
                  src="https://images.unsplash.com/photo-1653045377674-baf2e662886a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8Mnx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60"
                  className="card-img-top all_img"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex">
                    <div className="img_main">
                      <div className="circle3"></div>
                      <div>
                        <span className="text3 ">College Logo Here</span>
                      </div>
                    </div>
                    <div>
                      <p className="all_p_text1">Khushpreet</p>
                      <p className="all_p_text2">
                        College Kolkata, West Bengal
                      </p>
                      <div className="d-flex">
                        <p className="all_p_text3">Total Links</p>
                        <p className="all_p_text4">142</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <button className="btn_all_1">Linked</button>
                  <button className="btn_all_2">Message</button>
                  <button className="btn_all_3">Posts</button>
                </div>
              </div>
            </div>
            {/* End Card 1 Front */}
            {/* Card 1 Back */}
            <div className="card back">
              <div className="card-block">
                <button className="flip_btn mt-3">Profile</button>
                <h6 className="mt-2">Khushpreet</h6>
                <div className="text-start mt-3 p-2" style={{ float: "left" }}>
                  <h5 className="flip_text_h5">Area of Expertise-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                  <h5 className="mt-4 flip_text_h5">Highest education-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                </div>
              </div>
            </div>
            {/* End Card 1 Back */}
          </div>
        </label>
        {/* End Card 2 */}
        {/* Card 3 */}
        <input type="checkbox" style={{ display: "none" }} id="card-3" />
        <label className="col-md-4 card-container" htmlFor="card-3">
          <div className="card-flip">
            {/* Card 1 Front */}
            <div className="card front">
              <div>
                <img
                  src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  className="card-img-top all_img"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex">
                    <div className="img_main">
                      <img src={img} alt="" />
                    </div>
                    <div>
                      <p className="all_p_text1">Khushpreet</p>
                      <p className="all_p_text2">
                        College Kolkata, West Bengal
                      </p>
                      <div className="d-flex">
                        <p className="all_p_text3">Total Links</p>
                        <p className="all_p_text4">142</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <button className="btn_all_1">Linked</button>
                  <button className="btn_all_2">Message</button>
                  <button className="btn_all_3">Posts</button>
                </div>
              </div>
            </div>
            {/* End Card 1 Front */}
            {/* Card 1 Back */}
            <div className="card back">
              <div className="card-block">
                <button className="flip_btn mt-3">Profile</button>
                <h6 className="mt-2">Khushpreet</h6>
                <div className="text-start mt-3 p-2" style={{ float: "left" }}>
                  <h5 className="flip_text_h5">Area of Expertise-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                  <h5 className="mt-4 flip_text_h5">Highest education-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                </div>
              </div>
            </div>
            {/* End Card 1 Back */}
          </div>
        </label>
        {/* End Card 3 */}
        {/* Card 4 */}
        <input type="checkbox" style={{ display: "none" }} id="card-4" />
        <label className="col-md-4 card-container mt-3" htmlFor="card-4">
          <div className="card-flip">
            {/* Card 1 Front */}
            <div className="card front">
              <div>
                <img
                  src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  className="card-img-top all_img"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex">
                    <div className="img_main">
                      <img src={img} alt="" />
                    </div>
                    <div>
                      <p className="all_p_text1">Khushpreet</p>
                      <p className="all_p_text2">
                        College Kolkata, West Bengal
                      </p>
                      <div className="d-flex">
                        <p className="all_p_text3">Total Links</p>
                        <p className="all_p_text4">142</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <button className="btn_all_1">Linked</button>
                  <button className="btn_all_2">Message</button>
                  <button className="btn_all_3">Posts</button>
                </div>
              </div>
            </div>
            {/* End Card 1 Front */}
            {/* Card 1 Back */}
            <div className="card back">
              <div className="card-block">
                <button className="flip_btn mt-3">Profile</button>
                <h6 className="mt-2">Khushpreet</h6>
                <div className="text-start mt-3 p-2" style={{ float: "left" }}>
                  <h5 className="flip_text_h5">Area of Expertise-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                  <h5 className="mt-4 flip_text_h5">Highest education-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                </div>
              </div>
            </div>
            {/* End Card 1 Back */}
          </div>
        </label>
        {/* End Card 4 */}
        {/* Card 5 */}
        <input type="checkbox" style={{ display: "none" }} id="card-5" />
        <label className="col-md-4 card-container mt-3" htmlFor="card-5">
          <div className="card-flip">
            {/* Card 1 Front */}
            <div className="card front">
              <div>
                <img
                  src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  className="card-img-top all_img"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex">
                    <div className="img_main">
                      <img src={img} alt="" />
                    </div>
                    <div>
                      <p className="all_p_text1">Khushpreet</p>
                      <p className="all_p_text2">
                        College Kolkata, West Bengal
                      </p>
                      <div className="d-flex">
                        <p className="all_p_text3">Total Links</p>
                        <p className="all_p_text4">142</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <button className="btn_all_1">Linked</button>
                  <button className="btn_all_2">Message</button>
                  <button className="btn_all_3">Posts</button>
                </div>
              </div>
            </div>
            {/* End Card 1 Front */}
            {/* Card 1 Back */}
            <div className="card back">
              <div className="card-block">
                <button className="flip_btn mt-3">Profile</button>
                <h6 className="mt-2">Khushpreet</h6>
                <div className="text-start mt-3 p-2" style={{ float: "left" }}>
                  <h5 className="flip_text_h5">Area of Expertise-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                  <h5 className="mt-4 flip_text_h5">Highest education-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                </div>
              </div>
            </div>
            {/* End Card 1 Back */}
          </div>
        </label>
        {/* End Card 5 */}
        {/* Card 6 */}
        <input type="checkbox" style={{ display: "none" }} id="card-6" />
        <label className="col-md-4 card-container mt-3" htmlFor="card-6">
          <div className="card-flip">
            {/* Card 1 Front */}
            <div className="card front">
              <div>
                <img
                  src="https://images.unsplash.com/photo-1653045377674-baf2e662886a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8Mnx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60"
                  className="card-img-top all_img"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex">
                    <div className="img_main">
                      <div className="circle3"></div>
                      <div>
                        <span className="text3 ">College Logo Here</span>
                      </div>
                    </div>
                    <div>
                      <p className="all_p_text1">Khushpreet</p>
                      <p className="all_p_text2">
                        College Kolkata, West Bengal
                      </p>
                      <div className="d-flex">
                        <p className="all_p_text3">Total Links</p>
                        <p className="all_p_text4">142</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <button className="btn_all_1">Linked</button>
                  <button className="btn_all_2">Message</button>
                  <button className="btn_all_3">Posts</button>
                </div>
              </div>
            </div>
            {/* End Card 1 Front */}
            {/* Card 1 Back */}
            <div className="card back">
              <div className="card-block">
                <button className="flip_btn mt-3">Profile</button>
                <h6 className="mt-2">Khushpreet</h6>
                <div className="text-start mt-3 p-2" style={{ float: "left" }}>
                  <h5 className="flip_text_h5">Area of Expertise-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                  <h5 className="mt-4 flip_text_h5">Highest education-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                </div>
              </div>
            </div>
            {/* End Card 1 Back */}
          </div>
        </label>
        {/* End Card 6 */}
      </div>
    </div>
  );
};

export default All;
