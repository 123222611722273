import React, { useEffect, useState } from "react";
import { UilSearch } from "@iconscout/react-unicons";
import img from "../../img/icon/Icon awesome-long-arrow-alt-down.png";
import img3 from "../../img/icon/Component 75 – 1@2x.png";
import img2 from "../../img/icon/Component 74 – 1@2x.png";
import img4 from "../../img/icon/Component 76 – 1@2x.png";
import img5 from "../../img/icon/Component 78 – 1@2x.png";
import img6 from "../../img/icon/Icon material-location-on@2x.png";
import img7 from "../../img/icon/Icon ionic-ios-book@2x.png";
import "./College.css";
import axios from "axios";
import { Link } from "react-router-dom";
const Colleges = () => {
  //search
  const [searchInput, setSearchInput] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const loadPosts = async () => {
    try {
      const response = await axios.get(
        "https://educationapi.pino10.shop/api/college"
      );
      setAPIData(response.data);
      setFilteredResults(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loadPosts();
  }, []);
  useEffect(() => {
    const result = APIData.filter((item) => {
      return item.name.toLowerCase().match(searchInput.toLowerCase());
    });
    setFilteredResults(result);
  }, [searchInput]);

  const [data2, setData2] = useState([]);
  const loadPosts2 = async () => {
    try {
      const response = await axios.get(
        "https://educationapi.pino10.shop/api/states"
      );
      setData2(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loadPosts2();
  }, []);

  const [data3, setData3] = useState([]);
  const loadPosts3 = async () => {
    try {
      const response = await axios.get(
        "https://educationapi.pino10.shop/api/division"
      );
      setData3(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loadPosts3();
  }, []);

  return (
    <div>
      <div className="row">
        <div
          className="col-lg-3 col-md-5 p-lg-5 announcement_sm"
          style={{ background: "#EEEEEE" }}
        >
          <div>
            <div className="logoSearch">
              <div className="search">
                <input
                  type="text"
                  placeholder="Search here"
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <div className="s-icon">
                  <UilSearch />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <h6>Found {filteredResults.length} Colleges</h6>
              <a href="" style={{ color: "black" }}>
                See Default
              </a>{" "}
            </div>
            <div className="left_card_college text-start mt-4">
              <div style={{ borderLeft: " 5px solid #4AB76A" }}>
                <div className="left_card_college_header p-3">
                  <a className="left_card_college_text" href="">
                    STATE
                  </a>
                </div>
              </div>
              <div className="college_search2 ms-3">
                <input type="text" placeholder="Search here" />
              </div>
              <div className="p-3" style={{ height: 200, overflowY: "scroll" }}>
                {data2.map((data, index) => (
                  <>
                    <div class="form-check ms-2 mb-2" key={index}>
                      <input
                        class="form-check-input "
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        {data.name} [1]
                      </label>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="left_card_college text-start mt-4">
              <div style={{ borderLeft: " 5px solid #F8B54C" }}>
                <div className="left_card_college_header p-3">
                  <a className="left_card_college_text" href="">
                    Division
                  </a>
                </div>
              </div>
              <div className="college_search2 ms-3">
                <input type="text" placeholder="Search here" />
              </div>
              <div className="p-3" style={{ height: 200, overflowY: "scroll" }}>
                {data3.map((data, index) => (
                  <div class="form-check ms-2 mb-2" key={index}>
                    <input
                      class="form-check-input "
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      {data.name} [1]
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-md-7">
          <div>
            <h1 className="my-5 right_header font_family_h1">
              LIST OF TOP COLLEGES IN BANGLADESH BASED ON 2022 RANKING
            </h1>
            <div className="exam_sm">
              <div className="logoSearch ">
                <div className="search">
                  <input
                    type="text"
                    placeholder="Search here"
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <div className="s-icon">
                    <UilSearch />
                  </div>
                </div>
              </div>
            </div>
            <div className="announcement_sm">
              <div className="d-flex stream text-start">
                <div style={{ borderRight: " 1px solid #D6D3E1" }}>
                  <h6 className="p-3">SELECT STREAM</h6>
                </div>
                <div className="ms-4 my-3">
                  <button className="button">EDUCATION(1)</button>
                  <button className="button">ENGINEERING(12)</button>
                </div>
              </div>
              <div className="d-flex stream text-start mt-5 ">
                <div
                  className="stream2"
                  style={{ borderRight: " 1px solid #D6D3E1" }}
                >
                  <h6 className="p-3">SELECT COURSE</h6>
                </div>
                <div className="ms-4 my-3">
                  <button className="button2">BDS</button>
                  <button className="button2">B.ED</button>
                  <button className="button2">B.ED</button>
                  <button className="button2">B.ED</button>
                  <button className="button2">B.ED</button>
                  <button className="button2">B.ED</button>
                  <button className="button2">B.ED</button>
                </div>
              </div>
              <div className="d-flex stream text-start mt-5 college_sort">
                <div
                  className="stream2"
                  style={{ borderRight: " 1px solid #D6D3E1" }}
                >
                  <h6 className="p-3">SORT BY:</h6>
                </div>
                <div className="d-flex ms-4 my-3">
                  <p className="streamText">
                    Popularity <img src={img} alt="" />
                  </p>

                  <p className="streamText">
                    Rating <img src={img} alt="" />
                  </p>
                  <p className="streamText">
                    Highest Fee <img src={img} alt="" />
                  </p>
                  <p className="streamText">
                    Lowest Fee <img src={img} alt="" />
                  </p>
                </div>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-xl-3 row-cols-lg-2 row-cols-md-2  g-4 my-5">
              {filteredResults.map((data) => (
                <div className="col exam_a">
                  <Link to={"/college_inner"}>
                    <div className="card">
                      <img
                        src={data.img[0]}
                        className="card-img-top"
                        alt="..."
                        height={250}
                      />
                      <div
                        className="card-body"
                        style={{ background: "#EBEBEB ", height: 70 }}
                      >
                        <p className="card-text">{data.name}</p>
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <div className="d-flex justify-content-between px-5">
                            <div className="d-flex">
                              <a href="#" className="search_span">
                                <img src={img6} alt="" />
                              </a>
                              {/* <p className="ms-2">
                                {data.division ? data.division.name : ""}
                                <span>
                                  {data.states ? ` , ${data.states.name}` : ""}
                                </span>
                              </p> */}
                            </div>
                            <div className="d-flex">
                              <a href="#" className="search_span">
                                <img src={img7} alt="" />
                              </a>
                              {/* <p className="ms-2">
                                {data.college_type
                                  ? data.college_type.name
                                  : ""}
                              </p> */}
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="d-flex justify-content-between px-5">
                            <div>
                              <h6>Course</h6>
                              <a>B.Ed</a>
                            </div>
                            <div>
                              <h6>Fees</h6>
                              <a>₹ 63,000</a>
                            </div>
                            <div>
                              <h6>Exam</h6>
                              <a>AKNUCET</a>
                            </div>
                            <div>
                              <h6>Ranked</h6>
                              <a>#0 by</a>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="d-flex justify-content-between px-5">
                            <div className="d-flex">
                              <img
                                className="card_list_img"
                                src={img2}
                                alt=""
                              />
                              <p>{data.total_likes}</p>
                            </div>
                            <div className="d-flex">
                              <img
                                className="card_list_img"
                                src={img3}
                                alt=""
                              />
                              <p>{data.total_friends}</p>
                            </div>
                            <div className="d-flex">
                              <img
                                className="card_list_img"
                                src={img4}
                                alt=""
                              />
                              <p>{data.total_shares}</p>
                            </div>
                            <div className="d-flex">
                              <img
                                className="card_list_img"
                                src={img5}
                                alt=""
                              />
                              {/* <p>{data.total_comments}</p> */}
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div className="card-body card_footer">
                        <a href="#" className="card-link">
                          Courses & Fees
                        </a>
                        <a href="#" className="card-link ">
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Colleges;
