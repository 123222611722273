import React from "react";
import img from "../../../img/corporate/Icon ionic-ios-bookmarks.png";

import img3 from "../../../img/corporate/Icon ionic-ios-time.png";

import img6 from "../../../img/corporate/Icon awesome-question-circle.png";

import "./RightSide.css";
import { Link } from "react-router-dom";
const RightSide = () => {
  return (
    <div className="corporate_bg">
      <div>
        <h6 className="corporate_header">
          MOCK TESTS FOR PRACTICE /{" "}
          <span className="corporate_header_other">Reasoning </span>
        </h6>
        <div className="row mock_test_card mx-2 mt-3 p-3">
          <div className="col-md-7 ">
            <h4 className="corporate_h4">Topic Name Here</h4>
            <h6 className="mocktest">Mocktest 1</h6>
            <div className="d-flex ">
              <a href="" className="corporate_span_like ">
                <img src={img6} alt="" />
                <span>14 Questions</span>
              </a>
              <a href="" className="corporate_span_like mx-5">
                <img src={img} alt="" />
                <span>12 Marks</span>
              </a>
              <a href="" className="corporate_span_like">
                <img src={img3} alt="" />
                <span>12 Mins</span>
              </a>
            </div>
            <div className="d-flex mt-2">
              <a href="" className="corporate_a">
                Learn Short Tricks
              </a>
              <a href="" className="corporate_a ms-5">
                Download Worksheet
              </a>
            </div>
          </div>
          <div className="col-md-5">
            <Link to={"/mock_exam"}>
              <button className="corporate_btn2 mt-3">Start Now</button>
            </Link>
          </div>
        </div>
        <div className="row mock_test_card mx-2 mt-3 p-3">
          <div className="col-md-7 ">
            <h4 className="corporate_h4">Topic Name Here</h4>
            <h6 className="mocktest">Mocktest 1</h6>
            <div className="d-flex ">
              <a href="" className="corporate_span_like ">
                <img src={img6} alt="" />
                <span>14 Questions</span>
              </a>
              <a href="" className="corporate_span_like mx-5">
                <img src={img} alt="" />
                <span>12 Marks</span>
              </a>
              <a href="" className="corporate_span_like">
                <img src={img3} alt="" />
                <span>12 Mins</span>
              </a>
            </div>
            <div className="d-flex mt-2">
              <a href="" className="corporate_a">
                Learn Short Tricks
              </a>
              <a href="" className="corporate_a ms-5">
                Download Worksheet
              </a>
            </div>
          </div>
          <div className="col-md-5">
            <Link to={"/mock_exam"}>
              <button className="corporate_btn2 mt-3">Start Now</button>
            </Link>
          </div>
        </div>
        <div className="row mock_test_card mx-2 mt-3 p-3">
          <div className="col-md-7 ">
            <h4 className="corporate_h4">Topic Name Here</h4>
            <h6 className="mocktest">Mocktest 1</h6>
            <div className="d-flex ">
              <a href="" className="corporate_span_like ">
                <img src={img6} alt="" />
                <span>14 Questions</span>
              </a>
              <a href="" className="corporate_span_like mx-5">
                <img src={img} alt="" />
                <span>12 Marks</span>
              </a>
              <a href="" className="corporate_span_like">
                <img src={img3} alt="" />
                <span>12 Mins</span>
              </a>
            </div>
            <div className="d-flex mt-2">
              <a href="" className="corporate_a">
                Learn Short Tricks
              </a>
              <a href="" className="corporate_a ms-5">
                Download Worksheet
              </a>
            </div>
          </div>
          <div className="col-md-5">
            <Link to={"/mock_exam"}>
              <button className="corporate_btn2 mt-3">Start Now</button>
            </Link>
          </div>
        </div>
        "
      </div>
    </div>
  );
};

export default RightSide;
