import React from "react";

import img from "../../../img/home/Group 174@2x.png";
import img1 from "../../../img/home/Group 166.png";
import img2 from "../../../img/home/Group 168.png";
import img3 from "../../../img/home/Group 169.png";
import img4 from "../../../img/home/book-svgrepo-com.png";
import img5 from "../../../img/home/Group 174.png";
import img6 from "../../../img/home/Rectangle 560.png";
import "./LeftSide.css";
import { Link } from "react-router-dom";
const LeftSide = () => {
  return (
    <div className="container">
      <div>
        <div className="d-flex p-2 justify-content-center card_header">
          <a href="" className="card_text">
            <img src={img} alt="" />
            <span className="ms-2">STUDY GOALS</span>
          </a>
        </div>

        <nav className="side w-100" id="myScrollspy" style={{ height: 200 }}>
          <ul
            className="nav nav-pills nav-stacked w-100"
            data-offset-top={10}
            style={{ background: "unset" }}
          >
            <li className="active">
              <Link to={"/college"}>
                <a href="/college" className="menu_text">
                  <img src={img1} alt="" />
                  <span className="ms-2">Top Colleges</span>
                </a>
              </Link>
            </li>
            <li>
              <Link to={"/courses"}>
                <a href="/courses" className="menu_text">
                  <img src={img2} alt="" />
                  <span className="ms-2">Top Courses</span>
                </a>
              </Link>
            </li>
            <li>
              <Link to={"/exams"}>
                <a href="/exams" className="menu_text">
                  <img src={img2} alt="" />
                  <span className="ms-2">Top Exams</span>
                </a>
              </Link>
            </li>
            <li>
              <Link to={"/review"}>
                <a href="#" className="menu_text">
                  <img src={img3} alt="" />
                  <span className="ms-2">Write A Review</span>
                </a>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="mt-4">
        <div className="d-flex p-2 justify-content-center card_header">
          <a href="" className="card_text">
            <img src={img4} alt="" />
            <span className="ms-2">LEARNING LINKS</span>
          </a>
        </div>

        <nav className="side w-100" id="myScrollspy" style={{ height: 420 }}>
          <ul
            className="nav nav-pills nav-stacked w-100"
            data-offset-top={10}
            style={{ background: "unset" }}
          >
            <li className="active">
              <a href="#" className="menu_text">
                <img src={img5} alt="" />
                <span className="ms-2">Learn English </span>
              </a>
            </li>
            <li>
              <a href="#" className="menu_text">
                <img src={img5} alt="" />
                <span className="ms-2">Learn IELTS</span>
              </a>
            </li>
            <li>
              <a href="#" className="menu_text">
                <img src={img5} alt="" />
                <span className="ms-2">Learn Quants</span>
              </a>
            </li>
            <li>
              <a href="#" className="menu_text">
                <img src={img5} alt="" />
                <span className="ms-2">Learn Reasoning</span>
              </a>
            </li>
            <li>
              <a href="#" className="menu_text">
                <img src={img5} alt="" />
                <span className="ms-2">Learn Verbal Ability</span>
              </a>
            </li>
            <li>
              <a href="#" className="menu_text">
                <img src={img5} alt="" />
                <span className="ms-2">Soft Skills</span>
              </a>
            </li>
            <li>
              <a href="#" className="menu_text">
                <img src={img5} alt="" />
                <span className="ms-2">Interview Preparation</span>
              </a>
            </li>
            <li>
              <a href="#" className="menu_text">
                <img src={img5} alt="" />
                <span className="ms-2">Practice Test</span>
              </a>
            </li>
            <li>
              <a href="#" className="menu_text">
                <img src={img5} alt="" />
                <span className="ms-2">Question Bank</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="mt-4">
        <div className="d-flex p-2 justify-content-center card_header">
          <a href="" className="card_text">
            <img src={img4} alt="" />
            <span className="ms-2">Quick LINKS</span>
          </a>
        </div>

        <div className="last_part">
          <div className="accordion-wrapper p-3">
            <div className="accordion">
              <input type="radio" name="radio-a" id="check1" defaultChecked />
              <div className="d-flex">
                <img src={img6} alt="" className="left_circle_img" />
                <label
                  className="accordion-label w-100 label_size"
                  htmlFor="check1"
                >
                  B.TECH
                </label>
              </div>

              <div
                className="accordion-content "
                style={{ marginLeft: "-120px" }}
              >
                <ul
                  className="nav nav-pills nav-stacked"
                  data-offset-top={10}
                  style={{ display: "unset" }}
                >
                  <li className="active">
                    <a href="#" className="menu_text">
                      <img src={img1} alt="" />
                      <span className="ms-2">B.Tech Top Colleges</span>
                    </a>
                  </li>
                  <br />
                  <li className="second_li">
                    <a href="#" className="menu_text">
                      <img src={img1} alt="" />
                      <span className="ms-2">B.Tech Exams</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="accordion">
              <input type="radio" name="radio-a" id="check2" defaultChecked />
              <div className="d-flex">
                <img src={img6} alt="" className="left_circle_img" />
                <label
                  className="accordion-label w-100 label_size"
                  htmlFor="check2"
                >
                  MBBS
                </label>
              </div>

              <div
                className="accordion-content "
                style={{ marginLeft: "-120px" }}
              >
                <ul
                  className="nav nav-pills nav-stacked"
                  data-offset-top={10}
                  style={{ display: "unset" }}
                >
                  <li className="active">
                    <a href="#" className="menu_text">
                      <img src={img1} alt="" />
                      <span className="ms-2">B.Tech Top Colleges</span>
                    </a>
                  </li>
                  <br />
                  <li className="second_li">
                    <a href="#" className="menu_text">
                      <img src={img1} alt="" />
                      <span className="ms-2">B.Tech Exams</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="accordion">
              <input type="radio" name="radio-a" id="check3" defaultChecked />
              <div className="d-flex">
                <img src={img6} alt="" className="left_circle_img" />
                <label
                  className="accordion-label w-100 label_size"
                  htmlFor="check3"
                >
                  MBA
                </label>
              </div>

              <div
                className="accordion-content "
                style={{ marginLeft: "-120px" }}
              >
                <ul
                  className="nav nav-pills nav-stacked"
                  data-offset-top={10}
                  style={{ display: "unset" }}
                >
                  <li className="active">
                    <a href="#" className="menu_text">
                      <img src={img1} alt="" />
                      <span className="ms-2">B.Tech Top Colleges</span>
                    </a>
                  </li>
                  <br />
                  <li className="second_li">
                    <a href="#" className="menu_text">
                      <img src={img1} alt="" />
                      <span className="ms-2">B.Tech Exams</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="accordion">
              <input type="radio" name="radio-a" id="check4" defaultChecked />
              <div className="d-flex">
                <img src={img6} alt="" className="left_circle_img" />
                <label
                  className="accordion-label w-100 label_size"
                  htmlFor="check4"
                >
                  DESIGN
                </label>
              </div>

              <div
                className="accordion-content "
                style={{ marginLeft: "-120px" }}
              >
                <ul
                  className="nav nav-pills nav-stacked"
                  data-offset-top={10}
                  style={{ display: "unset" }}
                >
                  <li className="active">
                    <a href="#" className="menu_text">
                      <img src={img1} alt="" />
                      <span className="ms-2">B.Tech Top Colleges</span>
                    </a>
                  </li>
                  <br />
                  <li className="second_li">
                    <a href="#" className="menu_text">
                      <img src={img1} alt="" />
                      <span className="ms-2">B.Tech Exams</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="accordion">
              <input type="radio" name="radio-a" id="check5" defaultChecked />
              <div className="d-flex">
                <img src={img6} alt="" className="left_circle_img" />
                <label
                  className="accordion-label w-100 label_size"
                  htmlFor="check5"
                >
                  LAW
                </label>
              </div>

              <div
                className="accordion-content "
                style={{ marginLeft: "-120px" }}
              >
                <ul
                  className="nav nav-pills nav-stacked"
                  data-offset-top={10}
                  style={{ display: "unset" }}
                >
                  <li className="active">
                    <a href="#" className="menu_text">
                      <img src={img1} alt="" />
                      <span className="ms-2">B.Tech Top Colleges</span>
                    </a>
                  </li>
                  <br />
                  <li className="second_li">
                    <a href="#" className="menu_text">
                      <img src={img1} alt="" />
                      <span className="ms-2">B.Tech Exams</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="accordion">
              <input type="radio" name="radio-a" id="check6" defaultChecked />
              <div className="d-flex">
                <img src={img6} alt="" className="left_circle_img" />
                <label
                  className="accordion-label w-100 label_size"
                  htmlFor="check6"
                >
                  SCIENCE
                </label>
              </div>

              <div
                className="accordion-content "
                style={{ marginLeft: "-120px" }}
              >
                <ul
                  className="nav nav-pills nav-stacked"
                  data-offset-top={10}
                  style={{ display: "unset" }}
                >
                  <li className="active">
                    <a href="#" className="menu_text">
                      <img src={img1} alt="" />
                      <span className="ms-2">B.Tech Top Colleges</span>
                    </a>
                  </li>
                  <br />
                  <li className="second_li">
                    <a href="#" className="menu_text">
                      <img src={img1} alt="" />
                      <span className="ms-2">B.Tech Exams</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSide;
