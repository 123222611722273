import React from "react";
import img from "../../img/colleges-inner-page/Icon feather-plus-circle.png";
const FaqExam = () => {
  return (
    <div>
      <div className="mx-5">
        <div className="row " style={{ background: "#EEEEEE" }}>
          <nav className="col-md-2 side p-0 " id="myScrollspy">
            <ul
              className="nav nav-pills nav-stacked"
              data-offset-top={10}
              style={{ background: "unset" }}
            >
              <li className="active">
                <a href="#"> GENERAL</a>
              </li>
            </ul>
          </nav>
          <div className="col-md-10 rightSide">
            <div id="#">
              <div className=" text-start content">
                <h3 className="text-start content_title ms-5">Faq</h3>
                <div>
                  <div
                    className="accordion-wrapper "
                    style={{ width: 600, float: "left", marginLeft: 32 }}
                  >
                    <div className="accordion">
                      <input
                        type="radio"
                        name="radio-a"
                        id="check1"
                        defaultChecked
                      />
                      <div className="d-flex">
                        <label
                          className="accordion-label w-100 label_size"
                          htmlFor="check1"
                        >
                          What is the fee structure for exam?
                        </label>
                      </div>

                      <div className="accordion-content ">
                        <p className="text-start px-2">
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam...View More
                        </p>
                      </div>
                    </div>

                    <div className="accordion">
                      <input
                        type="radio"
                        name="radio-a"
                        id="check2"
                        defaultChecked
                      />
                      <div className="d-flex">
                        <label
                          className="accordion-label w-100 label_size"
                          htmlFor="check2"
                        >
                          What is the fee structure for exam?
                        </label>
                      </div>

                      <div className="accordion-content ">
                        <p className="text-start px-2">
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam...View More
                        </p>
                      </div>
                    </div>
                    <div className="accordion">
                      <input
                        type="radio"
                        name="radio-a"
                        id="check3"
                        defaultChecked
                      />
                      <div className="d-flex">
                        <label
                          className="accordion-label w-100 label_size"
                          htmlFor="check3"
                        >
                          What is the fee structure for exam?
                        </label>
                      </div>

                      <div className="accordion-content ">
                        <p className="text-start px-2">
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam...View More
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqExam;
