import React from "react";
import "./Profile.css";
import img from "../../img/home/Group 1277.png";
import img2 from "../../img/home/Group 1276.png";
const Profile = () => {
  return (
    <div>
      <div className="" style={{ margin: "0px 120px" }}>
        <h4 className="profile_header">Edit Profile</h4>
        <form className="row g-3">
          <div className="row py-5 px-4">
            <div className="col-md-12 mx-auto">
              <div>
                <div className="px-4 pt-0 pb-4 cover2">
                  <div>
                    <img
                      src={img2}
                      alt=""
                      style={{ float: "right", marginTop: 220 }}
                    />
                  </div>
                  <div className="media align-items-end profile-head">
                    <div className="profile mr-3">
                      <img
                        src={img}
                        alt="..."
                        width={130}
                        height={130}
                        className="img-fluid"
                        style={{ marginTop: 190 }}
                      />
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          </div>

          <div className="col-md-6 form">
            <label
              htmlFor="inputPassword4"
              className="form-label profile_label2"
            >
              Designation
            </label>
            <input
              type="text"
              className="form-control"
              id="inputPassword4"
              placeholder="Colleges"
            />
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter Email"
            />
          </div>
          <div className="col-md-6 form">
            <label
              htmlFor="inputPassword4"
              className="form-label profile_label2"
            >
              Password
            </label>
            <input
              type="text"
              className="form-control"
              id="inputPassword4"
              placeholder="Enter Password"
            />
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Mobile Number
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="1244555"
            />
          </div>
          <div className="col-md-6 form">
            <label
              htmlFor="inputPassword4"
              className="form-label profile_label2"
            >
              College Website
            </label>
            <input
              type="text"
              className="form-control"
              id="inputPassword4"
              placeholder="Enter College Website"
            />
          </div>

          <div className="d-flex justify-content-center align-items-center mb-5">
            <button
              type="submit"
              className="  profile_btn"
              style={{ width: 220 }}
            >
              Save Changes
            </button>
            <button
              type="submit"
              className=" profile_btn2 ms-3"
              style={{ width: 220 }}
            >
              Deactivate Account
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
