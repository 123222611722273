import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/core";
import img from "../../../img/home/Ellipse 120.png";
import img2 from "../../../img/home/Group 188.png";
import { UilSearch } from "@iconscout/react-unicons";
import "./MainPart.css";
const MainPart = () => {
  return (
    <div className="main">
      <div className="p-4">
        <div className="search mt-5">
          <input
            type="text"
            placeholder="Search your college activity and more"
          />
          <div className="s-icon">
            <UilSearch />
          </div>
        </div>
        <div className="p-4">
          <div className="row row-cols-1 row-cols-md-4 g-5">
            <div className="col box_part" style={{ width: 197 }}>
              <div className="box_text">
                <i className="fas fa-plus icon"></i>
                <p className="mt-4">Post an Event</p>
              </div>
            </div>
            <div className="col box_part" style={{ width: 197 }}></div>
            <div className="col box_part" style={{ width: 197 }}></div>
            <div className="col box_part" style={{ width: 197 }}></div>
          </div>
        </div>
        <div className="logoSearch2">
          <div className="search2">
            <div className="s-icon2">
              <img src={img} alt="" />
            </div>
            <input
              type="text"
              placeholder="What's in your mind, Argha?"
              style={{ width: "100%" }}
            />
            <div className="s-icon2 d-flex">
              <i class="fa-solid fa-image fs-4 "></i>
              <i class="fa-solid fa-at fs-4 px-lg-5 px-1"></i>
              <i class="fa-solid fa-ellipsis-vertical fs-4"></i>
            </div>
          </div>
        </div>

        <div className="">
          <div className=" text-center my-5">
            <h6 className="suggested_text">Suggested Links</h6>
          </div>

          <>
            <Splide
              options={{
                // type: "loop",
                drag: "free",
                focus: "center",
                gap: "2rem",
                perPage: 2,
                height: "10rem",
                breakpoints: {
                  1200: { arrows: false, perPage: 2 },
                  800: { perPage: 2 },
                  640: { perPage: 1 },
                },
              }}
            >
              <SplideSlide>
                <div
                  className="suggested_card "
                  style={{
                    visibility: "visible",
                    animationName: "fadeInUp",
                  }}
                >
                  <img src={img2} alt="" style={{ marginLeft: 260 }} />
                  <div className="d-flex">
                    <div>
                      <img
                        src={img}
                        alt="portfolio-img1 "
                        className="img-fluid"
                      />
                    </div>

                    <div className="text-start ms-3">
                      <span>Bharat</span>
                      <p className="suggested_p">
                        Student Lyallpur college of Technology
                      </p>
                      <div>
                        <button className="suggested_btn1 mt-3">Link</button>
                        <button className="suggested_btn2">Posts</button>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div
                  className="suggested_card "
                  style={{
                    visibility: "visible",
                    animationName: "fadeInUp",
                  }}
                >
                  <img src={img2} alt="" style={{ marginLeft: 260 }} />
                  <div className="d-flex">
                    <div>
                      <img
                        src={img}
                        alt="portfolio-img1 "
                        className="img-fluid"
                      />
                    </div>

                    <div className="text-start ms-3">
                      <span>Bharat</span>
                      <p className="suggested_p">
                        Student Lyallpur college of Technology
                      </p>
                      <div>
                        <button className="suggested_btn1 mt-3">Link</button>
                        <button className="suggested_btn2">Posts</button>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div
                  className="suggested_card "
                  style={{
                    visibility: "visible",
                    animationName: "fadeInUp",
                  }}
                >
                  <img src={img2} alt="" style={{ marginLeft: 260 }} />
                  <div className="d-flex">
                    <div>
                      <img
                        src={img}
                        alt="portfolio-img1 "
                        className="img-fluid"
                      />
                    </div>

                    <div className="text-start ms-3">
                      <span>Bharat</span>
                      <p className="suggested_p">
                        Student Lyallpur college of Technology
                      </p>
                      <div>
                        <button className="suggested_btn1 mt-3">Link</button>
                        <button className="suggested_btn2">Posts</button>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </>
        </div>
        <hr className="my-5" />
        <p>No posts to show, Create links to start showing posts</p>
      </div>
    </div>
  );
};

export default MainPart;
