import React from "react";
import ReactTextCollapse from "../../Exam_inner/About/ReactTextCollapse";
const TEXT_COLLAPSE_OPTIONS = {
  collapse: false,
  collapseText: "... show more",
  expandText: "show less",
  minHeight: 100,
  maxHeight: 200,
  textStyle: {
    color: "#64C280",
    fontSize: "18px",
  },
};
const About = () => {
  return (
    <div>
      <div className="row p-0" style={{ background: "#EEEEEE" }}>
        <nav className="col-md-2 side p-0 " id="myScrollspy">
          <div className="announcement_sm">
            <ul
              className="nav nav-pills nav-stacked"
              data-offset-top={10}
              style={{ background: "unset" }}
            >
              <li className="active">
                <a href="#">INTRODUCTION</a>
              </li>
              <li>
                <a href="#section1">HIGHLIGHTS</a>
              </li>
              <li>
                <a href="#section2">RANKING & AWARDS</a>
              </li>
              <li>
                <a href="#section3">COURSES</a>
              </li>
              <li>
                <a href="#section4">SCHOLARSHIP PLACEMENTS</a>
              </li>
              <li>
                <a href="#section5">FACILITIES</a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="col-md-10 col-12 rightSide">
          <div id="#">
            <div className="p-lg-5 text-start content">
              <h3 className="text-start content_title">Introduction</h3>
              <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </ReactTextCollapse>
            </div>
          </div>
          <div className="my-5" id="section1">
            <div className="p-lg-5 text-start content">
              <h3 className="text-start content_title">HIGHLIGHTS</h3>
              <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Blanditiis dignissimos explicabo quae sunt eaque nihil dolor
                  aut neque obcaecati ipsa voluptatibus nisi ab, est id odio
                  recusandae tempora in! Iure, voluptatibus rerum beatae
                  corporis aperiam eaque aliquam dolorum atque fugiat hic, ab
                  accusantium tempora nostrum, harum quasi suscipit sint libero
                  illum vel! Numquam possimus deserunt iure qui quis nesciunt
                  minus id cumque temporibus voluptatibus consequatur animi
                  deleniti commodi, similique assumenda aperiam accusantium
                  velit pariatur provident nobis dolor sint earum. Ab tenetur,
                  porro reiciendis consequuntur culpa ea nesciunt facilis unde
                  voluptatum odit dolor? Soluta eligendi assumenda ea doloremque
                  incidunt suscipit corrupti iste accusantium minima quia,
                  libero aliquam magnam dolorem. Error minus neque obcaecati.
                  Recusandae voluptas doloribus necessitatibus eveniet nemo
                  quasi, veritatis, molestias libero ad quae excepturi
                  praesentium numquam sunt odio? Natus dolor repellendus
                  laboriosam, amet ab excepturi numquam consectetur nostrum
                  dolores adipisci soluta eos sapiente, commodi mollitia!
                  Excepturi omnis, maiores dolore laborum dolor repellat
                  officiis quia necessitatibus aliquam numquam illum quo eius
                  architecto quae nobis error a pariatur et. Id dignissimos,
                  sapiente earum omnis adipisci autem cumque repellendus
                  quisquam est doloremque quidem quibusdam aperiam explicabo
                  enim repudiandae minima at porro rem sunt modi! Excepturi vero
                  exercitationem sapiente voluptate ullam consequuntur
                  blanditiis? Lorem ipsum dolor sit amet, consetetur sadipscing
                  elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                  dolore magna aliquyam erat, sed diam voluptua. At vero eos et
                  accusam et justo duo dolores et ea rebum. Stet clita kasd
                  gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                  amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore
                  magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                  et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                  no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </ReactTextCollapse>
            </div>
          </div>
          <div className="my-5" id="section2">
            <div className="p-lg-5 text-start content">
              <h3 className="text-start content_title">RANKING & AWARDS</h3>
              <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </ReactTextCollapse>
            </div>
          </div>
          <div className="my-5" id="section3">
            <div className="p-lg-5 text-start content">
              <h3 className="text-start content_title">COURSES</h3>
              <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </ReactTextCollapse>
            </div>
          </div>
          <div className="my-5" id="section4">
            <div className="p-lg-5 text-start content">
              <h3 className="text-start content_title">
                SCHOLARSHIP PLACEMENTS
              </h3>
              <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </ReactTextCollapse>
            </div>
          </div>
          <div className="my-5" id="section5">
            <div className="p-lg-5 text-start content">
              <h3 className="text-start content_title">FACILITIES</h3>
              <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <p className="content_text2">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et
                </p>
              </ReactTextCollapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
