import React from "react";
import "./CMS.css";
const CMS = () => {
  return (
    <div>
      <div className="" style={{ margin: "0px 120px" }}>
        <h4 className="profile_header">Edit Profile</h4>
        <form className="row g-3">
          <h4 className="profile_header3">College About</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue={
                  "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et"
                }
              />
            </div>
          </div>
          <h4 className="profile_header3">Courses & Fees</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue={
                  "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et"
                }
              />
            </div>
          </div>
          <h4 className="profile_header3">College Admission</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue=""
              />
            </div>
          </div>
          <h4 className="profile_header3">College Announcement</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue=""
              />
            </div>
          </div>
          <h4 className="profile_header3">College Reviews</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue=""
              />
            </div>
          </div>
          <h4 className="profile_header3">Distance Education</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue=""
              />
            </div>
          </div>
          <h4 className="profile_header3">College Placement</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue=""
              />
            </div>
          </div>
          <h4 className="profile_header3">College Faculty</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue=""
              />
            </div>
          </div>
          <h4 className="profile_header3">College Scholarship</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue=""
              />
            </div>
          </div>
          <h4 className="profile_header3">University Highlights</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue=""
              />
            </div>
          </div>
          <h4 className="profile_header3">University Ranking & Highlights</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue=""
              />
            </div>
          </div>
          <h4 className="profile_header3">University Courses</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue=""
              />
            </div>
          </div>
          <h4 className="profile_header3">University Scholarship</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue=""
              />
            </div>
          </div>
          <h4 className="profile_header3">University Placements</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue=""
              />
            </div>
          </div>
          <h4 className="profile_header3">University Facilities</h4>
          <div className="col-md-12 form">
            <div>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={6}
                defaultValue=""
              />
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mb-5">
            <button
              type="submit"
              className="  profile_btn"
              style={{ width: 220 }}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CMS;
