import React from "react";
import { Link } from "react-router-dom";

import "./RightSide.css";
const RightSide = () => {
  return (
    <div className="corporate_bg">
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="my-3">
          <Link to={"/login"} style={{ textDecoration: "none" }}>
            <h6 className="corporate_no_content">
              No Content Available, Please Login To View Content Of This Page
            </h6>
          </Link>
          <Link to={"/login"} style={{ textDecoration: "none" }}>
            <h6 className="corporate_no_content">
              No Content Available, Please Login To View Content Of This Page
            </h6>
          </Link>
          <Link to={"/login"} style={{ textDecoration: "none" }}>
            <h6 className="corporate_no_content">
              No Content Available, Please Login To View Content Of This Page
            </h6>
          </Link>
          <Link to={"/login"} style={{ textDecoration: "none" }}>
            <h6 className="corporate_no_content">
              No Content Available, Please Login To View Content Of This Page
            </h6>
          </Link>
          <Link to={"/login"} style={{ textDecoration: "none" }}>
            <h6 className="corporate_no_content">
              No Content Available, Please Login To View Content Of This Page
            </h6>
          </Link>
          <Link to={"/login"} style={{ textDecoration: "none" }}>
            <h6 className="corporate_no_content">
              No Content Available, Please Login To View Content Of This Page
            </h6>
          </Link>
          <Link to={"/login"} style={{ textDecoration: "none" }}>
            <h6 className="corporate_no_content">
              No Content Available, Please Login To View Content Of This Page
            </h6>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RightSide;
