import React from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import "./Registration.css";
const RegistrationAll = () => {
  return (
    <div>
      <div className="my-5">
        <h6 className="registration_h6">LOGO</h6>
        <p className="registration_p">Create your account</p>
        <div className="registration_tabs mt-5">
          <input type="radio" id="tab1" name="tab-control" defaultChecked />
          <input type="radio" id="tab2" name="tab-control" />

          <ul>
            <li title="Features">
              <label htmlFor="tab1" role="button">
                <span className="menu_li">Step1</span>
              </label>
            </li>
            <li title="Courses & Fees">
              <label htmlFor="tab2" role="button">
                <span className="menu_li">Step2</span>
              </label>
            </li>
          </ul>
          <div className="slider">
            <div className="indicator" />
          </div>
          <div className="content">
            <section>
              <Step1 />
            </section>
            <section>
              <Step2 />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationAll;
