import React from "react";
import About from "./About/About";
import Admit_card from "./Admit_card";
import Councelling from "./Counselling";
import FaqExam from "./FaqExam";
import "./Details.css";
import Eligibility from "./Eligibility";
import Exam_center from "./Exam_center";
import Exam_pattern from "./Exam_pattern";
import Preparation_tips from "./Preparation_tips";
import Important from "./Important";

import Registration from "./Registration";
import Reservation from "./Reservation";
import Syllabus from "./Syllabus";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Details = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className="mx-lg-5">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            background: "#463196 0 0 no-repeat padding-box",
            paddingTop: 1,
          }}
          className="tab_bg"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            TabIndicatorProps={{
              style: { background: "#fff", height: 4, marginTop: 10 },
            }}
          >
            <Tab
              label="About"
              {...a11yProps(0)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />

            <Tab
              label="Registration"
              {...a11yProps(1)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />
            <Tab
              label="Admit Card "
              {...a11yProps(2)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />

            <Tab
              label="Important Dates"
              {...a11yProps(3)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />
            <Tab
              label="Reservation "
              {...a11yProps(4)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />

            <Tab
              label="Exam Centers"
              {...a11yProps(5)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />
            <Tab
              label="Eligibility "
              {...a11yProps(6)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />

            <Tab
              label="Exam Pattern"
              {...a11yProps(7)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />
            <Tab
              label="Syllabus "
              {...a11yProps(8)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />

            <Tab
              label=" Preparation Tips"
              {...a11yProps(9)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />
            <Tab
              label="Counselling "
              {...a11yProps(10)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />

            <Tab
              label=" Faq"
              {...a11yProps(11)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <About />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Registration />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Admit_card />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Important />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Reservation />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Exam_center />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Eligibility />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <Exam_pattern />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <Syllabus />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <Preparation_tips />
        </TabPanel>
        <TabPanel value={value} index={10}>
          <Councelling />
        </TabPanel>
        <TabPanel value={value} index={11}>
          <FaqExam />
        </TabPanel>
      </div>
    </div>
  );
};

export default Details;
