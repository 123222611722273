import React from "react";

const Step1 = () => {
  return (
    <div>
      <div className="" style={{ margin: "0px 120px" }}>
        <form className="row g-3">
          <div className="col-md-6 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              Select User Type
            </label>
            <select id="inputState" className="form-select">
              <option selected>Student</option>
              <option>A</option>
              <option>B</option>
            </select>
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter Email"
            />
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Mobile Number
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="Enter Mobile Number"
            />
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              State
            </label>
            <select id="inputState" className="form-select">
              <option selected>Choose state</option>
              <option>A</option>
              <option>B</option>
            </select>
          </div>
          <div className="col-md-6 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              City
            </label>
            <select id="inputState" className="form-select">
              <option selected>Choose City</option>
              <option>A</option>
              <option>B</option>
            </select>
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              College Website
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail4"
              placeholder="College Website"
            />
          </div>

          <div className="col-md-12 form">
            <label htmlFor="inputState" className="form-label profile_label2">
              College
            </label>
            <select id="inputState" className="form-select">
              <option selected>Choose College</option>
              <option>A</option>
              <option>B</option>
            </select>
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="inputEmail4"
              placeholder="Password"
            />
          </div>

          <div className="col-md-6 form">
            <label htmlFor="inputEmail4" className="form-label profile_label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="inputEmail4"
              placeholder="Re-Enter Password"
            />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-5">
            <button
              type="submit"
              className="  profile_btn"
              style={{ width: 220 }}
            >
              Next Step
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Step1;
