import axios from "axios";
import React, { useState } from "react";
import { UilSearch } from "@iconscout/react-unicons";
import { useEffect } from "react";
import img from "../../img/home/icons8-slider-50 (1).png";
import "./Exam.css";
import { Link } from "react-router-dom";
const Exam = () => {
  const [searchInput, setSearchInput] = useState("");

  const [APIData, setAPIData] = useState([]);

  const [filteredResults, setFilteredResults] = useState([]);

  const loadPosts = async () => {
    try {
      const response = await axios.get(
        "https://educationapi.pino10.shop/api/exam"
      );
      setAPIData(response.data);
      setFilteredResults(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadPosts();
  }, []);

  useEffect(() => {
    const result = APIData.filter((item) => {
      return item.name.toLowerCase().match(searchInput.toLowerCase());
    });
    setFilteredResults(result);
  }, [searchInput]);

  //examination mode
  const [examMode, setExamMode] = React.useState("all");
  React.useEffect(() => {
    if (examMode === "all") {
      setFilteredResults(APIData);
    } else {
      const filterExamMode = APIData.filter(
        (member) => member.exam_mode === examMode
      );
      setFilteredResults(filterExamMode);
    }
  }, [examMode]);

  const checkboxFiltersValue2 = {
    all: "all",
    online: "online",
    offline: "offline",
    both: "both",
  };

  return (
    <div>
      <div className="row">
        <div
          className="col-md-3 p-lg-5 announcement_sm"
          style={{ background: "#EEEEEE" }}
        >
          <div>
            <div className="logoSearch">
              <div className="search">
                <input
                  type="search"
                  name="search"
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <div className="s-icon">
                  <UilSearch />
                </div>
              </div>
            </div>
            <div></div>
            <div className="d-flex justify-content-between mt-3">
              <h6>Found {filteredResults.length} exam</h6>
              <a href="" style={{ color: "black" }}>
                See Default
              </a>{" "}
            </div>

            <div className="left_card_exam text-start mt-3">
              <div style={{ borderLeft: " 5px solid #F7684A" }}>
                <div className="left_card_exam_header p-3">
                  <a className="left_card_exam_text" href="">
                    EXAMINATION MODE
                  </a>
                  <i class="fa-solid fa-caret-down"></i>
                </div>
              </div>

              <div className="p-3">
                <div class="form-check ms-2">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    value={checkboxFiltersValue2.all}
                    checked={examMode === checkboxFiltersValue2.all}
                    onClick={({ target }) => setExamMode(target.value)}
                  />

                  <label class="form-check-label">All</label>
                </div>
                <div class="form-check ms-2">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    value={checkboxFiltersValue2.online}
                    checked={examMode === checkboxFiltersValue2.online}
                    onClick={({ target }) => setExamMode(target.value)}
                  />

                  <label class="form-check-label">Online</label>
                </div>
                <div class="form-check ms-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value={checkboxFiltersValue2.offline}
                    checked={examMode === checkboxFiltersValue2.offline}
                    onClick={({ target }) => setExamMode(target.value)}
                  />
                  <label class="form-check-label">Offline</label>
                </div>
                <div class="form-check ms-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value={checkboxFiltersValue2.both}
                    checked={examMode === checkboxFiltersValue2.both}
                    onClick={({ target }) => setExamMode(target.value)}
                  />
                  <label class="form-check-label">Both</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <h1 className="my-5 right_header font_family_h1">
            TOP ENTRANCE EXAM IN INDIA
          </h1>
          <div className="exam_sm">
            <div className="d-flex px-3">
              <div className="logoSearch ">
                <div className="search">
                  <input
                    type="text"
                    placeholder="Search here"
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <div className="s-icon">
                    <UilSearch />
                  </div>
                </div>
              </div>
              <div>
                {/* Button trigger modal */}
                <img
                  src={img}
                  alt=""
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                  className="ms-2"
                  style={{ cursor: "pointer" }}
                />

                <div
                  className="modal fade"
                  id="exampleModal2"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel2"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1
                          className="modal-title fs-5"
                          id="exampleModalLabel2"
                          style={{ color: "#7060AC", fontWeight: "bold" }}
                        >
                          Filters
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body p-0">
                        <div className="d-flex align-items-start">
                          <div
                            className="nav flex-column nav-pills me-3"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                            style={{
                              background: "#EFEFEF",
                              height: "100vh",
                              width: 230,
                              textAlign: "start",
                            }}
                          >
                            <div style={{ marginTop: 90 }}>
                              <button
                                className="nav-link active py-3"
                                id="v-pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-home"
                                type="button"
                                role="tab"
                                style={{
                                  fontSize: 12,
                                  width: "-moz-available",
                                  background: "#FFFFFF",
                                  color: "#272430",
                                  borderRadius: "0%",
                                }}
                                aria-controls="v-pills-home"
                                aria-selected="true"
                              >
                                EXAMINATION TYPE
                              </button>

                              <button
                                className="nav-link my-3 py-3"
                                id="v-pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-profile"
                                type="button"
                                role="tab"
                                style={{
                                  fontSize: 12,
                                  width: "-moz-available",
                                  background: "#FFFFFF",
                                  color: "#272430",
                                  borderRadius: "0%",
                                }}
                                aria-controls="v-pills-profile"
                                aria-selected="false"
                              >
                                APPLICATION MODE
                              </button>

                              <button
                                className="nav-link py-3"
                                id="v-pills-messages-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-messages"
                                type="button"
                                role="tab"
                                style={{
                                  fontSize: 12,
                                  width: "-moz-available",
                                  background: "#FFFFFF",
                                  color: "#272430",
                                  borderRadius: "0%",
                                }}
                                aria-controls="v-pills-messages"
                                aria-selected="true"
                              >
                                APPLICATION MODE
                              </button>
                            </div>
                          </div>
                          <div className="tab-content" id="v-pills-tabContent">
                            <div
                              className="tab-pane fade show active"
                              id="v-pills-home"
                              role="tabpanel"
                              aria-labelledby="v-pills-home-tab"
                              tabIndex={0}
                            >
                              <div className=" text-start mt-4">
                                <div>
                                  <div
                                    className="logoSearch"
                                    style={{ marginRight: 10 }}
                                  >
                                    <div className="search">
                                      <input
                                        type="search"
                                        name="search"
                                        onChange={(e) =>
                                          setSearchInput(e.target.value)
                                        }
                                      />
                                      <div className="s-icon">
                                        <UilSearch />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="v-pills-profile"
                              role="tabpanel"
                              aria-labelledby="v-pills-profile-tab"
                              tabIndex={0}
                            >
                              <div className="text-start mt-3">
                                <div>
                                  <div
                                    className="logoSearch"
                                    style={{ marginRight: 10 }}
                                  >
                                    <div className="search">
                                      <input
                                        type="search"
                                        name="search"
                                        onChange={(e) =>
                                          setSearchInput(e.target.value)
                                        }
                                      />
                                      <div className="s-icon">
                                        <UilSearch />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="tab-pane fade"
                              id="v-pills-messages"
                              role="tabpanel"
                              aria-labelledby="v-pills-messages-tab"
                              tabIndex={0}
                            >
                              <div className=" text-start mt-3">
                                <div
                                  className="col-md-3 p-lg-5"
                                  style={{ background: "#EEEEEE" }}
                                >
                                  <div>
                                    <div
                                      className="logoSearch"
                                      style={{ marginRight: 10 }}
                                    >
                                      <div className="search">
                                        <input
                                          type="search"
                                          name="search"
                                          onChange={(e) =>
                                            setSearchInput(e.target.value)
                                          }
                                        />
                                        <div className="s-icon">
                                          <UilSearch />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{ borderLeft: "5px solid #F7684A" }}
                                ></div>

                                <div className="p-3">
                                  <div class="form-check ms-2">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      value={checkboxFiltersValue2.all}
                                      checked={
                                        examMode === checkboxFiltersValue2.all
                                      }
                                      onClick={({ target }) =>
                                        setExamMode(target.value)
                                      }
                                    />

                                    <label class="form-check-label">All</label>
                                  </div>
                                  <div class="form-check ms-2">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      value={checkboxFiltersValue2.online}
                                      checked={
                                        examMode ===
                                        checkboxFiltersValue2.online
                                      }
                                      onClick={({ target }) =>
                                        setExamMode(target.value)
                                      }
                                    />

                                    <label class="form-check-label">
                                      Online
                                    </label>
                                  </div>
                                  <div class="form-check ms-2">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value={checkboxFiltersValue2.offline}
                                      checked={
                                        examMode ===
                                        checkboxFiltersValue2.offline
                                      }
                                      onClick={({ target }) =>
                                        setExamMode(target.value)
                                      }
                                    />
                                    <label class="form-check-label">
                                      Offline
                                    </label>
                                  </div>
                                  <div class="form-check ms-2">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value={checkboxFiltersValue2.both}
                                      checked={
                                        examMode === checkboxFiltersValue2.both
                                      }
                                      onClick={({ target }) =>
                                        setExamMode(target.value)
                                      }
                                    />
                                    <label class="form-check-label">Both</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <div className="d-flex justify-content-between mt-3 w-100 px-3">
                          <h6 className="fs-4" style={{ color: "#504E59" }}>
                            Found {filteredResults.length} exams
                          </h6>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            style={{ background: "#463196", width: 90 }}
                            data-bs-dismiss="modal"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {filteredResults.map((item) => {
            return (
              <>
                <div className="p-0 m-0 exam_a">
                  <Link to={"/exams_inner"}>
                    <div className="stream text-start mt-5">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="d-flex p-lg-3">
                            <img
                              src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSGFXknp64F6atN6OV7yxoB-kAsZYWnIOgznZ78mC7wyKCZJGZA"
                              alt=""
                              style={{ width: 80, height: 80 }}
                              className="img-fluid announcement_sm"
                            />
                            <div className="ms-3 exam_text">
                              <h6>{item.name}</h6>
                              <p>{item.name}</p>
                              <a href="" className="text">
                                2188 Colleges Accepting this Exam
                              </a>

                              <div className="announcement_sm">
                                <a href="" className="text2">
                                  Overview{" "}
                                  <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                                </a>
                                <a href="" className="text2 mx-3">
                                  Syllabus{" "}
                                  <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                                </a>
                                <a href="" className="text2">
                                  Exam Pattern{" "}
                                  <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-flex mt-3">
                            <div className="d-flex application_date">
                              <img
                                src="https://i.ibb.co/HPkY4PK/images-removebg-preview.png"
                                alt=""
                                style={{ height: 15 }}
                                className="m-1"
                              />
                              <h6>Application Date</h6>
                            </div>
                            <div className="d-flex application_date">
                              <img
                                src="https://i.ibb.co/TrvKqs4/images-removebg-preview-1.png"
                                alt=""
                                style={{ height: 15 }}
                                className="m-1"
                              />
                              <h6 className="mx-2">Exam Date</h6>
                            </div>
                            <div className="d-flex application_date2">
                              <img
                                src="https://i.ibb.co/tPzdNwy/images-1-removebg-preview.png"
                                alt=""
                                style={{ height: 20 }}
                                className="m-1"
                              />
                              <h6>Result Date</h6>
                            </div>
                          </div>
                          <div className="d-flex px-lg-4">
                            <p className="result_date">{item.exam_date}</p>
                            <p className="mx-4 result_date">{item.exam_date}</p>
                            <p className="result_date2"> {item.result_date}</p>
                          </div>
                          <div className="exam_sm w-100 d-flex justify-content-around">
                            <a href="" className="text2">
                              Overview{" "}
                              <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                            </a>
                            <a href="" className="text2 mx-3">
                              Syllabus{" "}
                              <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                            </a>
                            <a href="" className="text2">
                              Exam Pattern{" "}
                              <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                            </a>
                          </div>
                          <div className="mt-4 button_last mb-3">
                            <button className="btn btn-outline-dark button_update me-3">
                              Get Updates
                            </button>
                            <button className="button_apply">
                              How to Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Exam;
