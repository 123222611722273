import React from "react";
import Course from "../../components/Courses/Course";

import "./Courses.css";
const Courses = () => {
  return (
    <div className="container-fluid">
      <Course />
    </div>
  );
};

export default Courses;
