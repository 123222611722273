import React from "react";
import CMS from "./CMS";
import College from "./College";
import Course from "./Course";
import Profile from "./Profile";
import Review from "./Review";
import "./Details.css";

const Details = () => {
  return (
    <div>
      <div>
        <div className="college_edit_tabs">
          <input type="radio" id="tab1" name="tab-control" defaultChecked />
          <input type="radio" id="tab2" name="tab-control" />
          <input type="radio" id="tab3" name="tab-control" />
          <input type="radio" id="tab4" name="tab-control" />
          <input type="radio" id="tab5" name="tab-control" />

          <ul>
            <li title="Features">
              <label htmlFor="tab1" role="button">
                <span className="menu_li">Profile</span>
              </label>
            </li>
            <li title="Courses & Fees">
              <label htmlFor="tab2" role="button">
                <span className="menu_li">College</span>
              </label>
            </li>
            <li title="Announcement">
              <label htmlFor="tab3" role="button">
                <span className="menu_li">Course</span>
              </label>
            </li>
            <li title="Announcement">
              <label htmlFor="tab4" role="button">
                <span className="menu_li">CMS</span>
              </label>
            </li>
            <li title="Announcement">
              <label htmlFor="tab5" role="button">
                <span className="menu_li">Review</span>
              </label>
            </li>
          </ul>
          <div className="slider">
            <div className="indicator" />
          </div>
          <div className="content">
            <section>
              <Profile />
            </section>
            <section>
              <College />
            </section>

            <section>
              <Course />
            </section>
            <section>
              <CMS />
            </section>
            <section>
              <Review />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
