import React from "react";
import img from "../../../img/colleges-inner-page/Group 1237.png";
import img2 from "../../../img/colleges-inner-page/Ellipse 120.png";
import img3 from "../../../img/colleges-inner-page/Icon ionic-ios-school.png";
import img4 from "../../../img/colleges-inner-page/Icon ionic-ios-time.jpg";
import "./Review.css";
const Review = () => {
  return (
    <div>
      <div className="py-2 px-5">
        <div className="text-start content">
          <div className="d-flex justify-content-between px-5">
            <div>
              <h3 className="text-start heading">College Rating</h3>
              <p className="heading_sub">03 Reviews Found</p>
            </div>
            <div>
              <p className="text-center rating_text">3.4/4</p>
              <img src={img} alt="" />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="left_card p-2 ">
            <div className="d-flex justify-content-between p-3">
              <div className="d-flex">
                <div>
                  <img className="profile_img" src={img2} alt="" />
                </div>
                <div className="ms-2">
                  <p className="profile_name">Noor Fatima</p>
                  <div className="d-flex enrolled_main">
                    <p className="enrolled">Enrolled 2021</p>

                    <div className="d-flex">
                      <a className="enrolled2">
                        <img src={img3} alt="" className="enrolled_img" />
                        <span> Bachelor of Science</span>
                      </a>
                    </div>
                    <div className="d-flex ms-3">
                      <a className="enrolled2">
                        <img src={img4} alt="" className="  enrolled_img" />
                        <span> July 29,2022</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <p className="star_img"></p>

                <p className="rating_text2">3.4/4</p>
              </div>
            </div>
            <p className="review_text_middle">Review title for the review</p>
            <p className="review_text_last">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores lorem ipsum
            </p>
          </div>
        </div>
        <div className="p-4">
          <div className="left_card p-2 ">
            <div className="d-flex justify-content-between p-3">
              <div className="d-flex">
                <div>
                  <img className="profile_img" src={img2} alt="" />
                </div>
                <div className="ms-2">
                  <p className="profile_name">Noor Fatima</p>
                  <div className="d-flex enrolled_main">
                    <p className="enrolled">Enrolled 2021</p>

                    <div className="d-flex">
                      <a className="enrolled2">
                        <img src={img3} alt="" className="enrolled_img" />
                        <span> Bachelor of Science</span>
                      </a>
                    </div>
                    <div className="d-flex ms-3">
                      <a className="enrolled2">
                        <img src={img4} alt="" className="  enrolled_img" />
                        <span> July 29,2022</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <p className="star_img"></p>

                <p className="rating_text2">3.4/4</p>
              </div>
            </div>
            <p className="review_text_middle">Review title for the review</p>
            <p className="review_text_last">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores lorem ipsum
            </p>
          </div>
        </div>
        <div className="p-4">
          <div className="left_card p-2 ">
            <div className="d-flex justify-content-between p-3">
              <div className="d-flex">
                <div>
                  <img className="profile_img" src={img2} alt="" />
                </div>
                <div className="ms-2">
                  <p className="profile_name">Noor Fatima</p>
                  <div className="d-flex enrolled_main">
                    <p className="enrolled">Enrolled 2021</p>

                    <div className="d-flex">
                      <a className="enrolled2">
                        <img src={img3} alt="" className="enrolled_img" />
                        <span> Bachelor of Science</span>
                      </a>
                    </div>
                    <div className="d-flex ms-3">
                      <a className="enrolled2">
                        <img src={img4} alt="" className="  enrolled_img" />
                        <span> July 29,2022</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <p className="star_img"></p>

                <p className="rating_text2">3.4/4</p>
              </div>
            </div>
            <p className="review_text_middle">Review title for the review</p>
            <p className="review_text_last">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores lorem ipsum
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
