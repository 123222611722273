import React from "react";

const New = () => {
  return (
    <>
      <div>
        <div
          className="container-fluid"
          style={{ backgroundColor: "#2196F3", color: "#fff", height: 200 }}
        >
          <h1>Scrollspy + Affix (Vertical menu / sidenav)</h1>
          <h3>Fixed vertical sidenav on scroll</h3>
          <p>
            Scroll down to see how the left navbar menu behaves with
            data-spy="affix" &amp; data-spy="scrollspy".
          </p>
          <p>
            The left sidebar menu sticks to the page after you have scrolled a
            specified number of pixels, and links in the menu are automatically
            updated based on the scroll position.
          </p>
        </div>
        <br />
        <div
          className="container-fluid"
          style={{ backgroundColor: "#2196F3", color: "#fff", height: 200 }}
        >
          <h1>Scrollspy + Affix (Vertical menu / sidenav)</h1>
          <h3>Fixed vertical sidenav on scroll</h3>
          <p>
            Scroll down to see how the left navbar menu behaves with
            data-spy="affix" &amp; data-spy="scrollspy".
          </p>
          <p>
            The left sidebar menu sticks to the page after you have scrolled a
            specified number of pixels, and links in the menu are automatically
            updated based on the scroll position.
          </p>
        </div>
        <br />
        <div className="container">
          <div className="row" style={{ margin: "20px 0px" }}>
            <nav className="col-sm-3 sss" id="myScrollspy">
              <ul className="nav nav-pills nav-stacked" data-offset-top={10}>
                <li className="active">
                  <a href="#">Basic Sidenav</a>
                </li>
                <li>
                  <a href="#section1">Section 1</a>
                </li>
                <li>
                  <a href="#section2">Section 2</a>
                </li>
                <li>
                  <a href="#section3">Section 3</a>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                  >
                    Section 4 <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu">
                    <a
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                    ></a>
                    <li>
                      <a
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        href="#"
                      />
                      <a href="#section41">Section 4-1</a>
                    </li>
                    <li>
                      <a href="#section42">Section 4-2</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
            <div className="col-sm-9">
              <div id="section1">
                <h1>Section 1</h1>
                <p>Affix plugin:</p>
                <p>
                  1) allows an element to become locked to an area on the page
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Quasi necessitatibus eos voluptates aliquid totam. Corrupti,
                  totam omnis. Maiores quidem recusandae repellendus, autem
                  dolorum atque odit quaerat amet consequatur quos ut fuga
                  corrupti dolor ducimus asperiores possimus deleniti.
                  Cupiditate atque, aut in corporis dolores non quia. Saepe cum
                  excepturi recusandae, debitis mollitia, ut totam eum modi
                  distinctio facilis dignissimos rem. Dolores nisi odio corporis
                  a fuga commodi facere esse laborum error asperiores
                  accusantium corrupti unde mollitia ipsa sint, ab quas rerum? A
                  aspernatur velit tenetur vel consectetur, explicabo ipsam vero
                  neque quis nam, iure reiciendis assumenda debitis illo. Sequi
                  fugit nam quos provident delectus possimus magni, esse ea,
                  error facere similique exercitationem earum nobis repudiandae
                  id porro non doloribus nulla minus! Sequi totam explicabo sunt
                  praesentium consequuntur a harum fugiat enim quibusdam numquam
                  assumenda, doloribus voluptate ex, eius id sit qui ipsum
                  excepturi quod dolorem mollitia! Hic ratione neque, dolorum,
                  veritatis eaque ipsam exercitationem vitae eius, delectus
                  aliquam accusantium dolores unde deserunt? Sed eaque nam
                  voluptates cum. Commodi ipsa quae tenetur laborum consectetur,
                  quaerat minus ullam, voluptatem praesentium fuga molestiae
                  assumenda autem animi mollitia officiis illo minima asperiores
                  debitis cupiditate culpa! Accusamus voluptates ducimus
                  explicabo error ipsum dolor, enim officiis laudantium!
                </p>
                <p>2) usually used with nav menus or social icon buttons</p>
              </div>
              <div id="section2">
                <h1>Section 2</h1>
                <p>
                  3) plugin toggles css position from static to fixed, depending
                  on the scroll position
                </p>
                <p>4) e.g. affixed navbar / affixed sidebar</p>
                <p>5) add data-spy="affix" to element you want affixed</p>
              </div>
              <div id="section3">
                <h1>Section 3</h1>
                <p>
                  6) (optional) add data-offset-top|bottom to calculate position
                  of the scroll
                </p>
                <p>
                  7) affix plugin toggles between the 3 classes: .affix,
                  .affix-top, and .affix-bottom
                </p>
                <p>
                  8) add css properties to handle the actual positions, except
                  for position: fixed on the .affix class
                </p>
              </div>
              <div id="section41">
                <h1>Section 4-1</h1>
                <p>
                  9) plugin adds the .affix-top and .affix-bottom class to
                  indicate the element in its top-most or bottom-most position.
                  positioning with css is not required at this point.
                </p>
                <p>
                  10) scrolling past the affixed element triggers the actual
                  affixing - this is where the plugin replaces the .affix-top or
                  .affix-bottom class with the .affix class (sets position:
                  fixed). at this point, you must add the css top or bottom
                  property to position the affixed element in the page.
                </p>
                <p>
                  11) if a bottom offset is defined, scrolling past it replaces
                  the .affix class with the .affix-bottom. since offsets are
                  optional, setting one requires the appropriate css. in this
                  case, add position: absolute when necessary.
                </p>
              </div>
              <div id="section42">
                <h1>Section 4-2</h1>
                <p>
                  12) in the previous eg, the affix plugin adds the .affix class
                  (position: fixed) to the &lt;nav&gt; element after scrolling
                  197 pixels from the top. we added the css top property with a
                  value of 0 to the .affix class. this is to make sure that the
                  navbar stays at the top all the time, when we have scrolled
                  197 pixels from the top.
                </p>
                <br />
                <br />
                <p>Other things:</p>
                <p>
                  bootstrap grid system: clear floats (.clearfix class),
                  offsetting columns, push and pull to change column ordering
                </p>
                <p>
                  templates: blog, portfolio, webpage, social, marketing,
                  analytics, online store
                </p>
                <p>!!themes***</p>
                <p>!!quiz</p>
                <p>!!js ref</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid"
          style={{ backgroundColor: "#2196F3", color: "#fff", height: 200 }}
        >
          <h1>Scrollspy + Affix (Vertical menu / sidenav)</h1>
          <h3>Fixed vertical sidenav on scroll</h3>
          <p>
            Scroll down to see how the left navbar menu behaves with
            data-spy="affix" &amp; data-spy="scrollspy".
          </p>
          <p>
            The left sidebar menu sticks to the page after you have scrolled a
            specified number of pixels, and links in the menu are automatically
            updated based on the scroll position.
          </p>
        </div>
        <br />
        <div
          className="container-fluid"
          style={{ backgroundColor: "#2196F3", color: "#fff", height: 200 }}
        >
          <h1>Scrollspy + Affix (Vertical menu / sidenav)</h1>
          <h3>Fixed vertical sidenav on scroll</h3>
          <p>
            Scroll down to see how the left navbar menu behaves with
            data-spy="affix" &amp; data-spy="scrollspy".
          </p>
          <p>
            The left sidebar menu sticks to the page after you have scrolled a
            specified number of pixels, and links in the menu are automatically
            updated based on the scroll position.
          </p>
        </div>
        <div
          className="container-fluid"
          style={{ backgroundColor: "#2196F3", color: "#fff", height: 200 }}
        >
          <h1>Scrollspy + Affix (Vertical menu / sidenav)</h1>
          <h3>Fixed vertical sidenav on scroll</h3>
          <p>
            Scroll down to see how the left navbar menu behaves with
            data-spy="affix" &amp; data-spy="scrollspy".
          </p>
          <p>
            The left sidebar menu sticks to the page after you have scrolled a
            specified number of pixels, and links in the menu are automatically
            updated based on the scroll position.
          </p>
        </div>
        <div
          className="container-fluid"
          style={{ backgroundColor: "#2196F3", color: "#fff", height: 200 }}
        >
          <h1>Scrollspy + Affix (Vertical menu / sidenav)</h1>
          <h3>Fixed vertical sidenav on scroll</h3>
          <p>
            Scroll down to see how the left navbar menu behaves with
            data-spy="affix" &amp; data-spy="scrollspy".
          </p>
          <p>
            The left sidebar menu sticks to the page after you have scrolled a
            specified number of pixels, and links in the menu are automatically
            updated based on the scroll position.
          </p>
        </div>
        <br />
      </div>
    </>
  );
};

export default New;
