import React from "react";
import img from "../../img/corporate/Group 1314.png";
import img2 from "../../img/corporate/Group 1312.png";
import img3 from "../../img/corporate/Group 1311.png";
import img4 from "../../img/corporate/Group 1313.png";

import "./Mock_result.css";
const Mock_result = () => {
  return (
    <div>
      <div className="my-5">
        <h1 className="mock_result_h6">
          Congratulations! You've completed the test
        </h1>
        <div className="row p-0 m-0">
          <div className="col-md-6">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "600px" }}
            >
              <div>
                <img src={img} alt="" />
                <div style={{ marginTop: -222 }}>
                  <h1>75%</h1>
                  <h4>Score</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mock_result_card my-5 w-50">
              <div className="p-5">
                <h1 className="text-start">Total Score: 44/50</h1>
                <h4 className="mock_result_p">Percentile 55.87%</h4>
                <h4 className="mock_result_p">Accuracy 0%</h4>
                <h4 className="mock_result_p">
                  Time Taken 01Hrs 22Mins 05Secs
                </h4>
              </div>
            </div>
            <div className="mock_result_card w-50">
              <div className="p-5">
                <div>
                  <a href="" className="correct">
                    <img src={img2} alt="" />
                    <span className="ms-4">14 Correct Questions</span>
                  </a>
                </div>
                <div>
                  <a href="" className="correct2">
                    <img src={img3} alt="" className="my-3" />
                    <span className="ms-4">14 Correct Questions</span>
                  </a>
                </div>
                <div>
                  <a href="" className="correct3">
                    <img src={img4} alt="" />
                    <span className="ms-4">14 Correct Questions</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mock_result;
