import React from "react";
import "./Faq.scss";
import ReactTextCollapse from "../../Exam_inner/About/ReactTextCollapse";
const TEXT_COLLAPSE_OPTIONS = {
  collapse: false,
  collapseText: "... show more",
  expandText: "show less",
  minHeight: 100,
  maxHeight: 200,
  textStyle: {
    color: "#64C280",
    fontSize: "18px",
  },
};
const Faq = () => {
  return (
    <div>
      <div className="row" style={{ background: "#EEEEEE" }}>
        <nav className="col-md-2 side p-0 " id="myScrollspy">
          <div className="announcement_sm">
            <ul
              className="nav nav-pills nav-stacked"
              data-offset-top={10}
              style={{ background: "unset" }}
            >
              <li className="active">
                <a href="#">GENERAL</a>
              </li>
              <li>
                <a href="#section_faq1">COURSES & FEES</a>
              </li>
              <li>
                <a href="#section_faq2">SCHOLARSHIPS</a>
              </li>
              <li>
                <a href="#section_faq3">PLACEMENTS</a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="col-md-10 col-12 rightSide">
          <div id="#">
            <div className="px-5 text-start content">
              <h3 className="text-start content_title">FAQs</h3>
            </div>
          </div>
          <div>
            <div
              className="accordion-wrapper px-3 ms-3"
              style={{ width: 600, float: "left" }}
            >
              <div className="accordion">
                <input type="radio" name="radio-a" id="check1" defaultChecked />
                <div className="d-flex">
                  <label
                    className="accordion-label w-100 label_size"
                    htmlFor="check1"
                  >
                    What is eligibility criteria for scholarship?
                  </label>
                </div>

                <div className="accordion-content ">
                  <p className="text-start px-2">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et
                    accusam...View More
                  </p>
                </div>
              </div>

              <div className="accordion">
                <input type="radio" name="radio-a" id="check2" defaultChecked />
                <div className="d-flex">
                  <label
                    className="accordion-label w-100 label_size"
                    htmlFor="check2"
                  >
                    What is eligibility criteria for scholarship?
                  </label>
                </div>

                <div className="accordion-content ">
                  <p className="text-start px-2">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et
                    accusam...View More
                  </p>
                </div>
              </div>
              <div className="accordion">
                <input type="radio" name="radio-a" id="check3" defaultChecked />
                <div className="d-flex">
                  <label
                    className="accordion-label w-100 label_size"
                    htmlFor="check3"
                  >
                    What is eligibility criteria for scholarship?
                  </label>
                </div>

                <div className="accordion-content ">
                  <p className="text-start px-2">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et
                    accusam...View More
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
