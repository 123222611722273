import React from "react";
import "./Review.css";
const Review = () => {
  return (
    <div>
      <div>
        <h2>Responsive Table</h2>
        <div className="table-wrapper">
          <table className="fl-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Enroll Year</th>
                <th>Rating</th>
                <th>Date</th>
                <th>Review Title</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>01</td>

                <td>Noor Fatima</td>
                <td>0307-123 4567</td>
                <td>nf.4933@ gmail.com</td>
                <td>2022</td>
                <td>2/4</td>
                <td>09-12-22</td>
                <td>Lorum Ipsum Dolar</td>
                <td>
                  Lorum Ipsum Dolar Lorum Ipsum Dolar Lorum Ipsum Dolar Lorum
                  Ipsum Dolar...View More
                </td>
                <td>Delete</td>
              </tr>
              <tr>
                <td>01</td>

                <td>Noor Fatima</td>
                <td>0307-123 4567</td>
                <td>nf.4933@ gmail.com</td>
                <td>2022</td>
                <td>2/4</td>
                <td>09-12-22</td>
                <td>Lorum Ipsum Dolar</td>
                <td>
                  Lorum Ipsum Dolar Lorum Ipsum Dolar Lorum Ipsum Dolar Lorum
                  Ipsum Dolar...View More
                </td>
                <td>Delete</td>
              </tr>
            </tbody>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Review;
