import React from "react";
import About from "./About";
import Admission from "./Admission";
import Courses from "./Courses";
import "./Details.css";
import Distance_Education from "./Distance_Education";
import Faq from "./Faq";
import Announcement from "./Announcement";
import Placement from "./Placement";
import Review from "./Review";
import Scholarship from "./Scholarship";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Details = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div className="mx-lg-5">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            background: "#463196 0 0 no-repeat padding-box",
            paddingTop: 1,
          }}
          className="tab_bg"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            TabIndicatorProps={{
              style: { background: "#fff", height: 4, marginTop: 10 },
            }}
          >
            <Tab
              label="About"
              {...a11yProps(0)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />

            <Tab
              label="Courses & Fees"
              {...a11yProps(1)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />
            <Tab
              label="Announcement"
              {...a11yProps(2)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />

            <Tab
              label="Admission"
              {...a11yProps(3)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />
            <Tab
              label="Reviews "
              {...a11yProps(4)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />

            <Tab
              label="Distance Education"
              {...a11yProps(5)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />
            <Tab
              label="Placements "
              {...a11yProps(6)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />
            <Tab
              label="Scholarship "
              {...a11yProps(7)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />
            <Tab
              label="Faq"
              {...a11yProps(8)}
              sx={{ textTransform: "initial", fontWeight: 600, color: "#fff" }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <About />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Courses />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Announcement />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Admission />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Review />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Distance_Education />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Placement />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <Scholarship />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <Faq />
        </TabPanel>
      </div>
    </div>
  );
};

export default Details;
