import React from "react";
import Colleges from "../../components/College/Colleges";

const College = () => {
  return (
    <div className="container-fluid">
      <Colleges />
    </div>
  );
};

export default College;
