import React from "react";
import img from "../../../../img/search/Group 1279.png";
const ProfileAll = () => {
  return (
    <div>
      <div className="row">
        {/* Card 1 */}
        <input type="checkbox" style={{ display: "none" }} id="card-7" />
        <label className="col-md-4 card-container" htmlFor="card-7">
          <div className="card-flip">
            {/* Card 1 Front */}
            <div className="card front">
              <div>
                <img
                  src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  className="card-img-top all_img"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex">
                    <div className="img_main">
                      <img src={img} alt="" />
                    </div>
                    <div>
                      <p className="all_p_text1">Khushpreet</p>
                      <p className="all_p_text2">
                        College Kolkata, West Bengal
                      </p>
                      <div className="d-flex">
                        <p className="all_p_text3">Total Links</p>
                        <p className="all_p_text4">142</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <button className="btn_all_1">Linked</button>
                  <button className="btn_all_2">Message</button>
                  <button className="btn_all_3">Posts</button>
                </div>
              </div>
            </div>
            {/* End Card 1 Front */}
            {/* Card 1 Back */}
            <div className="card back">
              <div className="card-block">
                <button className="flip_btn mt-3">Profile</button>
                <h6 className="mt-2">Khushpreet</h6>
                <div className="text-start mt-3 p-2" style={{ float: "left" }}>
                  <h5 className="flip_text_h5">Area of Expertise-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                  <h5 className="mt-4 flip_text_h5">Highest education-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                </div>
              </div>
            </div>
            {/* End Card 1 Back */}
          </div>
        </label>
        {/* End Card 1 */}
        {/* Card 2 */}
        <input type="checkbox" style={{ display: "none" }} id="card-8" />
        <label className="col-md-4 card-container" htmlFor="card-8">
          <div className="card-flip">
            {/* Card 1 Front */}
            <div className="card front">
              <div>
                <img
                  src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  className="card-img-top all_img"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex">
                    <div className="img_main">
                      <img src={img} alt="" />
                    </div>
                    <div>
                      <p className="all_p_text1">Khushpreet</p>
                      <p className="all_p_text2">
                        College Kolkata, West Bengal
                      </p>
                      <div className="d-flex">
                        <p className="all_p_text3">Total Links</p>
                        <p className="all_p_text4">142</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <button className="btn_all_1">Linked</button>
                  <button className="btn_all_2">Message</button>
                  <button className="btn_all_3">Posts</button>
                </div>
              </div>
            </div>
            {/* End Card 1 Front */}
            {/* Card 1 Back */}
            <div className="card back">
              <div className="card-block">
                <button className="flip_btn mt-3">Profile</button>
                <h6 className="mt-2">Khushpreet</h6>
                <div className="text-start mt-3 p-2" style={{ float: "left" }}>
                  <h5 className="flip_text_h5">Area of Expertise-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                  <h5 className="mt-4 flip_text_h5">Highest education-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                </div>
              </div>
            </div>
            {/* End Card 1 Back */}
          </div>
        </label>
        {/* End Card 2 */}
        {/* Card 3 */}
        <input type="checkbox" style={{ display: "none" }} id="card-9" />
        <label className="col-md-4 card-container" htmlFor="card-9">
          <div className="card-flip">
            {/* Card 1 Front */}
            <div className="card front">
              <div>
                <img
                  src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  className="card-img-top all_img"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex">
                    <div className="img_main">
                      <img src={img} alt="" />
                    </div>
                    <div>
                      <p className="all_p_text1">Khushpreet</p>
                      <p className="all_p_text2">
                        College Kolkata, West Bengal
                      </p>
                      <div className="d-flex">
                        <p className="all_p_text3">Total Links</p>
                        <p className="all_p_text4">142</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <button className="btn_all_1">Linked</button>
                  <button className="btn_all_2">Message</button>
                  <button className="btn_all_3">Posts</button>
                </div>
              </div>
            </div>
            {/* End Card 1 Front */}
            {/* Card 1 Back */}
            <div className="card back">
              <div className="card-block">
                <button className="flip_btn mt-3">Profile</button>
                <h6 className="mt-2">Khushpreet</h6>
                <div className="text-start mt-3 p-2" style={{ float: "left" }}>
                  <h5 className="flip_text_h5">Area of Expertise-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                  <h5 className="mt-4 flip_text_h5">Highest education-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                </div>
              </div>
            </div>
            {/* End Card 1 Back */}
          </div>
        </label>
        {/* End Card 3 */}
        {/* Card 4 */}
        <input type="checkbox" style={{ display: "none" }} id="card-10" />
        <label className="col-md-4 card-container mt-3" htmlFor="card-10">
          <div className="card-flip">
            {/* Card 1 Front */}
            <div className="card front">
              <div>
                <img
                  src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  className="card-img-top all_img"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex">
                    <div className="img_main">
                      <img src={img} alt="" />
                    </div>
                    <div>
                      <p className="all_p_text1">Khushpreet</p>
                      <p className="all_p_text2">
                        College Kolkata, West Bengal
                      </p>
                      <div className="d-flex">
                        <p className="all_p_text3">Total Links</p>
                        <p className="all_p_text4">142</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <button className="btn_all_1">Linked</button>
                  <button className="btn_all_2">Message</button>
                  <button className="btn_all_3">Posts</button>
                </div>
              </div>
            </div>
            {/* End Card 1 Front */}
            {/* Card 1 Back */}
            <div className="card back">
              <div className="card-block">
                <button className="flip_btn mt-3">Profile</button>
                <h6 className="mt-2">Khushpreet</h6>
                <div className="text-start mt-3 p-2" style={{ float: "left" }}>
                  <h5 className="flip_text_h5">Area of Expertise-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                  <h5 className="mt-4 flip_text_h5">Highest education-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                </div>
              </div>
            </div>
            {/* End Card 1 Back */}
          </div>
        </label>
        {/* End Card 4 */}
        {/* Card 5 */}
        <input type="checkbox" style={{ display: "none" }} id="card-11" />
        <label className="col-md-4 card-container mt-3" htmlFor="card-11">
          <div className="card-flip">
            {/* Card 1 Front */}
            <div className="card front">
              <div>
                <img
                  src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  className="card-img-top all_img"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex">
                    <div className="img_main">
                      <img src={img} alt="" />
                    </div>
                    <div>
                      <p className="all_p_text1">Khushpreet</p>
                      <p className="all_p_text2">
                        College Kolkata, West Bengal
                      </p>
                      <div className="d-flex">
                        <p className="all_p_text3">Total Links</p>
                        <p className="all_p_text4">142</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <button className="btn_all_1">Linked</button>
                  <button className="btn_all_2">Message</button>
                  <button className="btn_all_3">Posts</button>
                </div>
              </div>
            </div>
            {/* End Card 1 Front */}
            {/* Card 1 Back */}
            <div className="card back">
              <div className="card-block">
                <button className="flip_btn mt-3">Profile</button>
                <h6 className="mt-2">Khushpreet</h6>
                <div className="text-start mt-3 p-2" style={{ float: "left" }}>
                  <h5 className="flip_text_h5">Area of Expertise-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                  <h5 className="mt-4 flip_text_h5">Highest education-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                </div>
              </div>
            </div>
            {/* End Card 1 Back */}
          </div>
        </label>
        {/* End Card 5 */}
        {/* Card 6 */}
        <input type="checkbox" style={{ display: "none" }} id="card-12" />
        <label className="col-md-4 card-container mt-3" htmlFor="card-12">
          <div className="card-flip">
            {/* Card 1 Front */}
            <div className="card front">
              <div>
                <img
                  src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  className="card-img-top all_img"
                  alt="..."
                />
                <div className="card-body">
                  <div className="d-flex">
                    <div className="img_main">
                      <img src={img} alt="" />
                    </div>
                    <div>
                      <p className="all_p_text1">Khushpreet</p>
                      <p className="all_p_text2">
                        College Kolkata, West Bengal
                      </p>
                      <div className="d-flex">
                        <p className="all_p_text3">Total Links</p>
                        <p className="all_p_text4">142</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <button className="btn_all_1">Linked</button>
                  <button className="btn_all_2">Message</button>
                  <button className="btn_all_3">Posts</button>
                </div>
              </div>
            </div>
            {/* End Card 1 Front */}
            {/* Card 1 Back */}
            <div className="card back">
              <div className="card-block">
                <button className="flip_btn mt-3">Profile</button>
                <h6 className="mt-2">Khushpreet</h6>
                <div className="text-start mt-3 p-2" style={{ float: "left" }}>
                  <h5 className="flip_text_h5">Area of Expertise-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                  <h5 className="mt-4 flip_text_h5">Highest education-</h5>
                  <h6>MSc molecular biology and biochmistry</h6>
                </div>
              </div>
            </div>
            {/* End Card 1 Back */}
          </div>
        </label>
        {/* End Card 6 */}
      </div>
    </div>
  );
};

export default ProfileAll;
