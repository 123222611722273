import React from "react";
import RightSide from "../../components/Search/RightSide/RightSide";

const Search = () => {
  return (
    <div className="container-fluid">
      {/* <Navigation /> */}
      <div className="row p-5">
        {/* <div className="col-md-3">
          <LeftSide />
        </div>
        <div className="col-md-6">
          <MainPart />
        </div> */}
        <div className="col-md-12">
          <RightSide />
        </div>
      </div>
    </div>
  );
};

export default Search;
