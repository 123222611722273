import React from "react";
import RegistrationAll from "../../components/Registration/Registration";

const Registration = () => {
  return (
    <div>
      <RegistrationAll />
    </div>
  );
};

export default Registration;
