import React from "react";
import Details from "../../components/Edit_college_profiles/Details";

const Edit_college_profile = () => {
  return (
    <div>
      <Details />
    </div>
  );
};

export default Edit_college_profile;
