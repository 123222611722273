import React from "react";
import img from "../../../img/home/announcement-megaphone-svgrepo-com.png";
import img2 from "../../../img/home/Rectangle 560.png";
import img3 from "../../../img/home/XMLID_91_.png";
import img4 from "../../../img/home/connection-svgrepo-com.png";
import img5 from "../../../img/home/Icon metro-attachment.png";
import img6 from "../../../img/home/send-svgrepo-com (3).png";
import "./RightSide.css";
const RightSide = () => {
  return (
    <div className="container">
      <div>
        <div className="d-flex p-2 justify-content-center card_header">
          <a href="" className="card_text">
            <img src={img} alt="" />
            <span className="ms-2">LATEST ANNOUNCEMENT</span>
          </a>
        </div>

        <nav
          className="side w-100 mt-3"
          id="myScrollspy"
          style={{ height: 280 }}
        >
          <div>
            <div className="d-flex">
              <a href="" className="right_side_text">
                <img src={img2} alt="" />
                <span className="ms-2 right_side_text">
                  Apple reality pro May launch..
                </span>
              </a>
            </div>
            <div className="d-flex small_text_main">
              <span className=" small_text">Manpreet Kaur</span>
              <span className="ms-5  small_text">19 Sep 22</span>
            </div>
          </div>
          <div className="my-3">
            <div className="d-flex">
              <a href="" className="right_side_text">
                <img src={img2} alt="" />
                <span className="ms-2 right_side_text">
                  JEE advanced 2022 result to b..
                </span>
              </a>
            </div>
            <div className="d-flex small_text_main">
              <span className=" small_text">Manpreet Kaur</span>
              <span className="ms-5  small_text">19 Sep 22</span>
            </div>
          </div>
          <div className="my-3">
            <div className="d-flex">
              <a href="" className="right_side_text">
                <img src={img2} alt="" />
                <span className="ms-2 right_side_text">
                  Apple reality pro May launch..
                </span>
              </a>
            </div>
            <div className="d-flex small_text_main">
              <span className=" small_text">Manpreet Kaur</span>
              <span className="ms-5  small_text">19 Sep 22</span>
            </div>
          </div>
          <div className="my-3">
            <div className="d-flex">
              <a href="" className="right_side_text">
                <img src={img2} alt="" />
                <span className="ms-2 right_side_text">
                  JEE advanced 2022 result to b..
                </span>
              </a>
            </div>
            <div className="d-flex small_text_main">
              <span className=" small_text">Manpreet Kaur</span>
              <span className="ms-5  small_text">19 Sep 22</span>
            </div>
          </div>
          <a href="" className="link_text">
            Show more..
          </a>
        </nav>
      </div>
      <div className="mt-4">
        <div className="d-flex p-2 justify-content-center card_header">
          <a href="" className="card_text">
            <img src={img3} alt="" />
            <span className="ms-2">LATEST HIRING</span>
          </a>
        </div>

        <nav
          className="side w-100 mt-3"
          id="myScrollspy"
          style={{ height: 280 }}
        >
          <div>
            <div className="d-flex">
              <a href="" className="right_side_text">
                <img src={img2} alt="" />
                <span className="ms-2 right_side_text">
                  Apple reality pro May launch..
                </span>
              </a>
            </div>
            <div className="d-flex small_text_main">
              <span className=" small_text">Manpreet Kaur</span>
              <span className="ms-5  small_text">19 Sep 22</span>
            </div>
          </div>
          <div className="my-3">
            <div className="d-flex">
              <a href="" className="right_side_text">
                <img src={img2} alt="" />
                <span className="ms-2 right_side_text">
                  JEE advanced 2022 result to b..
                </span>
              </a>
            </div>
            <div className="d-flex small_text_main">
              <span className=" small_text">Manpreet Kaur</span>
              <span className="ms-5  small_text">19 Sep 22</span>
            </div>
          </div>
          <div className="my-3">
            <div className="d-flex">
              <a href="" className="right_side_text">
                <img src={img2} alt="" />
                <span className="ms-2 right_side_text">
                  Apple reality pro May launch..
                </span>
              </a>
            </div>
            <div className="d-flex small_text_main">
              <span className=" small_text">Manpreet Kaur</span>
              <span className="ms-5  small_text">19 Sep 22</span>
            </div>
          </div>
          <div className="my-3">
            <div className="d-flex">
              <a href="" className="right_side_text">
                <img src={img2} alt="" />
                <span className="ms-2 right_side_text">
                  JEE advanced 2022 result to b..
                </span>
              </a>
            </div>
            <div className="d-flex small_text_main">
              <span className=" small_text">Manpreet Kaur</span>
              <span className="ms-5  small_text">19 Sep 22</span>
            </div>
          </div>
          <a href="" className="link_text">
            Show more..
          </a>
        </nav>
      </div>
      <div className="mt-4">
        <div className="d-flex p-2 justify-content-center card_header">
          <a href="" className="card_text">
            <img src={img4} alt="" />
            <span className="ms-2">HCCECO LINKS</span>
          </a>
        </div>

        <nav
          className="side w-100 mt-3"
          id="myScrollspy"
          style={{ height: 280 }}
        >
          <div>
            <div className="d-flex message_part">
              <button className="btn1">Messages</button>
              <button className="btn2">Link Requests</button>
            </div>
            <div className="search_message mb-3">
              <input
                type="text"
                className="btn_input mt-4 p-3"
                placeholder="Search connections.."
              />
            </div>
            <div>
              <div>
                <div>
                  <a
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal2"
                    href=""
                    className="home_card_name"
                    style={{ marginLeft: -175 }}
                  >
                    <img
                      src="https://images.unsplash.com/photo-1548382131-e0ebb1f0cdea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                      alt="college"
                      className="home_card_img"
                    />
                    <span className="ms-2">Noor Fatiha</span>
                  </a>
                </div>
              </div>
              <div className="second_text" style={{ marginTop: -10 }}>
                <span className="home_card_text">
                  College Lyallpur college of Technology
                </span>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog navbar_modal">
          <div className="modal-content">
            <div className="modal-header side_modal">
              <img
                src="https://images.unsplash.com/photo-1548382131-e0ebb1f0cdea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                alt="college"
                className="home_card_img"
              />
              <span className="ms-2 text-white">Noor Fatiha</span>
              <div className="p-0" style={{ marginLeft: -80, marginTop: 50 }}>
                <p>online</p>
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body side_modal_body">
              <div className=" w-100">
                <div
                  className="message_right d-block"
                  style={{ marginLeft: 200 }}
                >
                  <p>
                    Hello! What's up? Hello! What up? Hello! What's up? Hello!
                    What up?
                  </p>
                </div>
              </div>

              <div>
                <div className="message_left my-5">
                  <p>
                    Hello! What's up? Hello! What up? Hello! What's up? Hello!
                    What up?
                  </p>
                </div>
              </div>
              <div>
                <div className="logoSearch4 w-100 ">
                  <div
                    className="search3"
                    style={{
                      background: "#F5F5F5 0% 0% no-repeat padding-box",
                      boxShadow: " 0px 2px 10px #00000021",
                      opacity: 1,
                    }}
                  >
                    <div className="s-icon2">
                      <img src={img5} alt="" />
                    </div>

                    <div className="side_message_input">
                      <input type="text" placeholder="Type a message..." />
                    </div>
                    <div className="s-icon2 d-flex">
                      <img src={img6} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSide;
