import React from "react";
import img from "../../../img/icon/Component 75 – 1.png";
import img2 from "../../../img/colleges-inner-page/Ellipse 120.png";
import img3 from "../../../img/colleges-inner-page/Icon ionic-ios-school.png";
import img4 from "../../../img/colleges-inner-page/Icon ionic-ios-time.jpg";

import "./Announcement.css";

const Announcement = () => {
  return (
    <div>
      <div className="row p-0">
        <nav className="col-md-2 side p-0" id="myScrollspy">
          <ul
            className="nav nav-pills nav-stacked"
            data-offset-top={10}
            style={{ background: "unset" }}
          >
            <li className="active">
              <a href="#">GRAPHIC DESIGNER REQUIRED...</a>
            </li>
            <li>
              <a href="#section_announcement2">GRAPHIC DESIGNER REQUIRED...</a>
            </li>
            <li>
              <a href="#section_announcement3">GRAPHIC DESIGNER REQUIRED...</a>
            </li>
            <li>
              <a href="#section_announcement4">GRAPHIC DESIGNER REQUIRED...</a>
            </li>
          </ul>
        </nav>
        <div className="col-md-10 rightSide px-5">
          <div className="left_card my-5" id="#">
            <div className="d-flex justify-content-between p-3">
              <div className="d-flex">
                <div>
                  <img className="profile_img" src={img2} alt="" />
                </div>
                <div className="ms-2">
                  <div className="d-flex">
                    <p className="profile_name">
                      Noor Fatima{" "}
                      <span className="enrolled ms-2 fs-6">
                        has created an announcement post
                      </span>
                    </p>
                    <a
                      className="ms-5"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <img src={img4} alt="" />
                      <span> 2Hrs</span>
                    </a>
                  </div>
                  <div className="d-flex enrolled_main">
                    <a>
                      <img src={img} alt="" style={{ marginTop: "10px" }} />
                      <span className="w-100">
                        {" "}
                        Student Lyallpur college of Technology
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <p className="star_img"></p>

                <p className="rating_text2">3.4/4</p>
              </div>
            </div>
            <div className="px-4">
              <p className="review_text_middle w-100">
                Graphic designer required!
              </p>
              <p className="text-start fs-6 w-75 p-2">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam....{" "}
                <span style={{ color: "green" }}>Read more</span>
              </p>
              <div className="announcement_text">
                <div className="d-flex">
                  <img src={img2} alt="" className="ms-4" />
                  <p className="ms-5 mt-2">Write an opinion..</p>
                </div>
              </div>
            </div>
          </div>
          <div id="section_announcement2">
            <h1>Section 3</h1>
            <p>
              6) (optional) add data-offset-top|bottom to calculate position of
              the scroll
            </p>
            <p>
              7) affix plugin toggles between the 3 classes: .affix, .affix-top,
              and .affix-bottom
            </p>
            <p>
              8) add css properties to handle the actual positions, except for
              position: fixed on the .affix class
            </p>
          </div>
          <div id="section_announcement3">
            <h1>Section 4-1</h1>
            <p>
              9) plugin adds the .affix-top and .affix-bottom class to indicate
              the element in its top-most or bottom-most position. positioning
              with css is not required at this point.
            </p>
            <p>
              10) scrolling past the affixed element triggers the actual
              affixing - this is where the plugin replaces the .affix-top or
              .affix-bottom class with the .affix class (sets position: fixed).
              at this point, you must add the css top or bottom property to
              position the affixed element in the page.
            </p>
            <p>
              11) if a bottom offset is defined, scrolling past it replaces the
              .affix class with the .affix-bottom. since offsets are optional,
              setting one requires the appropriate css. in this case, add
              position: absolute when necessary.
            </p>
          </div>
          <div id="section_announcement4">
            <h1>Section 4-2</h1>
            <p>
              12) in the previous eg, the affix plugin adds the .affix class
              (position: fixed) to the &lt;nav&gt; element after scrolling 197
              pixels from the top. we added the css top property with a value of
              0 to the .affix class. this is to make sure that the navbar stays
              at the top all the time, when we have scrolled 197 pixels from the
              top.
            </p>
            <br />
            <br />
            <p>Other things:</p>
            <p>
              bootstrap grid system: clear floats (.clearfix class), offsetting
              columns, push and pull to change column ordering
            </p>
            <p>
              templates: blog, portfolio, webpage, social, marketing, analytics,
              online store
            </p>
            <p>!!themes***</p>
            <p>!!quiz</p>
            <p>!!js ref</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Announcement;
