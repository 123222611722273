import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/core";
import img from "../../../img/home/Ellipse 120.png";
import img2 from "../../../img/home/Group 188.png";
import img3 from "../../../img/home/home1/Icon ionic-ios-time.png";
import img4 from "../../../img/home/home1/Group 174.png";
import img5 from "../../../img/home/home1/Component 119 – 6.png";
import img6 from "../../../img/home/home1/Component 120 – 3.png";
import img7 from "../../../img/home/home1/Component 121 – 3.png";
import img8 from "../../../img/home/home1/send-svgrepo-com (3).png";

import "./MainPart.css";

const MainPart = () => {
  return (
    <div className="main">
      <div className=" p-4 ">
        <input
          type="text"
          name=""
          id=""
          className="search mt-3"
          placeholder="Search your college activity and more"
        />
        <div className="p-4">
          <div className="row row-cols-1 row-cols-md-4 g-5">
            <div className="col box_part" style={{ width: 197 }}>
              <div className="box_text">
                <i className="fas fa-plus icon"></i>
                <p className="mt-4">Post an Event</p>
              </div>
            </div>
          </div>
        </div>
        <div className="logoSearch3">
          <div className="search_main">
            <div className="search3 mt-2">
              <div className="s-icon3">
                <img src={img} alt="" />
              </div>

              <input type="text" placeholder="What's in your mind, Argha?" />
              <div className="s-icon3 d-flex">
                <i class="fa-solid fa-image fs-4 "></i>
                <i class="fa-solid fa-at fs-4 px-5"></i>
                <i class="fa-solid fa-ellipsis-vertical fs-4"></i>
              </div>
            </div>
            <div className="second_input">
              <input type="text" name="" id="" placeholder="Write Title Here" />
            </div>
            <div className="second_input2">
              <textarea
                name=""
                id=""
                cols="89"
                rows="2"
                placeholder="Write Description Here"
              ></textarea>
            </div>
            <div>
              <button className="post_btn">Post</button>
            </div>
          </div>
        </div>

        <div className="">
          <div className=" text-center my-5">
            <h6 className="suggested_text">Suggested Links</h6>
          </div>

          <>
            <Splide
              options={{
                // type: "loop",
                drag: "free",
                focus: "center",
                gap: "2rem",
                perPage: 2,
                height: "10rem",
                breakpoints: {
                  1200: { arrows: false, perPage: 2 },
                  800: { perPage: 2 },
                  640: { perPage: 1 },
                },
              }}
            >
              <SplideSlide>
                <div
                  className="suggested_card "
                  style={{
                    visibility: "visible",
                    animationName: "fadeInUp",
                  }}
                >
                  <img src={img2} alt="" style={{ marginLeft: 260 }} />
                  <div className="d-flex">
                    <div>
                      <img
                        src={img}
                        alt="portfolio-img1 "
                        className="img-fluid"
                      />
                    </div>

                    <div className="text-start ms-3">
                      <span>Bharat</span>
                      <p className="suggested_p">
                        Student Lyallpur college of Technology
                      </p>
                      <div>
                        <button className="suggested_btn1 mt-3">Link</button>
                        <button className="suggested_btn2">Posts</button>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div
                  className="suggested_card "
                  style={{
                    visibility: "visible",
                    animationName: "fadeInUp",
                  }}
                >
                  <img src={img2} alt="" style={{ marginLeft: 260 }} />
                  <div className="d-flex">
                    <div>
                      <img
                        src={img}
                        alt="portfolio-img1 "
                        className="img-fluid"
                      />
                    </div>

                    <div className="text-start ms-3">
                      <span>Bharat</span>
                      <p className="suggested_p">
                        Student Lyallpur college of Technology
                      </p>
                      <div>
                        <button className="suggested_btn1 mt-3">Link</button>
                        <button className="suggested_btn2">Posts</button>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div
                  className="suggested_card "
                  style={{
                    visibility: "visible",
                    animationName: "fadeInUp",
                  }}
                >
                  <img src={img2} alt="" style={{ marginLeft: 260 }} />
                  <div className="d-flex">
                    <div>
                      <img
                        src={img}
                        alt="portfolio-img1 "
                        className="img-fluid"
                      />
                    </div>

                    <div className="text-start ms-3">
                      <span>Bharat</span>
                      <p className="suggested_p">
                        Student Lyallpur college of Technology
                      </p>
                      <div>
                        <button className="suggested_btn1 mt-3">Link</button>
                        <button className="suggested_btn2">Posts</button>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </>
        </div>
        <div className="home_card">
          <div className="d-flex justify-content-around mt-4">
            <div>
              <a href="" className="home_card_name">
                <img
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDF8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                  alt="college"
                  className="home_card_img"
                />
                <span className="ms-2">Monika</span>
              </a>
            </div>
            <p className="mt-1">posted an announcement</p>
            <div className="mt-1">
              <a href="" className="home_card_time">
                <img src={img3} alt="" />
                <span className="ms-2 home_card_text">6 Days</span>
              </a>
            </div>
            <i className="fa-solid fa-ellipsis-vertical fs-4 mt-1"></i>
          </div>
          <div className="second_text">
            <img src={img4} alt="" />
            <span className="home_card_text">
              College Lyallpur college of Technology
            </span>
          </div>
          <div className="home_card_text2">
            <p>Graphic designer required!</p>
          </div>
          <div>
            <p className="home_card_text3">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. …. eos et accusam et justo duo dolores et
              ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
              Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetet
              ur sadipscing elitr, sed diam nonumy
            </p>
          </div>
          <hr className="hr" />
          <div className="d-flex justify-content-around mt-3">
            <div className="d-flex justify-content-around">
              <div>
                <a href="" className="home_card_text ">
                  <img src={img5} alt="" />
                  <span className="ms-2 mt-5">125</span>
                </a>
              </div>
              <div className="mx-5">
                <a href="" className="home_card_text ">
                  <img src={img6} alt="" />
                  <span className="ms-2 mt-5">125</span>
                </a>
              </div>
              <div>
                <a href="" className="home_card_text ">
                  <img src={img7} alt="" />
                  <span className="ms-2 mt-5">125</span>
                </a>
              </div>
            </div>
            <div>
              <p className="view">View Opinions</p>
            </div>
          </div>
          <hr className="hr1 " />
          <div className="logoSearch2 mt-5 px-5 ">
            <div className="search2" style={{ border: "none" }}>
              <div className="s-icon2">
                <img src={img} alt="" />
              </div>
              <input type="text" placeholder="Write an opinion.." />
              <div className="s-icon2 d-flex">
                <img src={img7} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="home_card mt-4">
          <div className="d-flex justify-content-around mt-4">
            <div>
              <a href="" className="home_card_name">
                <img
                  src="https://images.unsplash.com/photo-1548382131-e0ebb1f0cdea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                  alt="college"
                  className="home_card_img"
                />
                <span className="ms-2">Noor Fatiha</span>
              </a>
            </div>
            <p className="mt-1">posted an announcement</p>
            <div className="mt-1">
              <a href="" className="home_card_time">
                <img src={img3} alt="" />
                <span className="ms-2 home_card_text">6 Days</span>
              </a>
            </div>
            <i className="fa-solid fa-ellipsis-vertical fs-4 mt-1"></i>
          </div>
          <div className="second_text">
            <img src={img4} alt="" />
            <span className="home_card_text">
              College Lyallpur college of Technology
            </span>
          </div>
          <div className="home_card_text2">
            <p>Graphic designer required!</p>
          </div>
          <div>
            <p className="home_card_text3">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. …. eos et accusam et justo duo dolores et
              ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
              Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetet
              ur sadipscing elitr, sed diam nonumy
            </p>
          </div>
          <hr className="hr" />
          <div className="d-flex justify-content-around mt-3">
            <div className="d-flex justify-content-around">
              <div>
                <a href="" className="home_card_text ">
                  <img src={img5} alt="" />
                  <span className="ms-2 mt-5">125</span>
                </a>
              </div>
              <div className="mx-5">
                <a href="" className="home_card_text ">
                  <img src={img6} alt="" />
                  <span className="ms-2 mt-5">125</span>
                </a>
              </div>
              <div>
                <a href="" className="home_card_text ">
                  <img src={img7} alt="" />
                  <span className="ms-2 mt-5">125</span>
                </a>
              </div>
            </div>
            <div>
              <p className="view">View Opinions</p>
            </div>
          </div>
          <hr className="hr1 " />
          <div className="logoSearch2 mt-5 px-5 ">
            <div className="search2" style={{ border: "none" }}>
              <div className="s-icon2">
                <img src={img} alt="" />
              </div>
              <input type="text" placeholder="Write an opinion.." />
              <div className="s-icon2 d-flex">
                <img src={img7} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="home_card mt-4" style={{ height: 560 }}>
          <div className="d-flex justify-content-around mt-4">
            <div>
              <a href="" className="home_card_name">
                <img
                  src="https://images.unsplash.com/photo-1548382131-e0ebb1f0cdea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                  alt="college"
                  className="home_card_img"
                />
                <span className="ms-2">Noor Fatiha</span>
              </a>
            </div>
            <p className="mt-1">posted an announcement</p>
            <div className="mt-1">
              <a href="" className="home_card_time">
                <img src={img3} alt="" />
                <span className="ms-2 home_card_text">6 Days</span>
              </a>
            </div>
            <i className="fa-solid fa-ellipsis-vertical fs-4 mt-1"></i>
          </div>

          <div className="second_text">
            <img src={img4} alt="" />
            <span className="home_card_text">
              College Lyallpur college of Technology
            </span>
          </div>
          <hr className="img_hr" />
          <div>
            <div className="d-flex justify-content-around mt-4">
              <div>
                <a href="" className="home_card_name">
                  <img
                    src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEwfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60"
                    alt="college"
                    className="home_card_img"
                  />
                  <span className="ms-2">Akash</span>
                </a>
              </div>
              <p className="mt-1">posted an announcement</p>
              <div className="mt-1">
                <a href="" className="home_card_time">
                  <img src={img3} alt="" />
                  <span className="ms-2 home_card_text">6 Days</span>
                </a>
              </div>
              <i className="fa-solid fa-ellipsis-vertical fs-4 mt-1"></i>
            </div>

            <div className="second_text">
              <img src={img4} alt="" />
              <span className="home_card_text">
                College Lyallpur college of Technology
              </span>
            </div>
          </div>
          <div className="home_card_text2">
            <p>Graphic designer required!</p>
          </div>
          <div>
            <p className="home_card_text3">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. …. eos et accusam et justo duo dolores et
              ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
              Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetet
              ur sadipscing elitr, sed diam nonumy
            </p>
          </div>
          <hr className="hr" />
          <div className="d-flex justify-content-around mt-3">
            <div className="d-flex justify-content-around">
              <div>
                <a href="" className="home_card_text ">
                  <img src={img5} alt="" />
                  <span className="ms-2 mt-5">125</span>
                </a>
              </div>
              <div className="mx-5">
                <a href="" className="home_card_text ">
                  <img src={img6} alt="" />
                  <span className="ms-2 mt-5">125</span>
                </a>
              </div>
              <div>
                <a href="" className="home_card_text ">
                  <img src={img7} alt="" />
                  <span className="ms-2 mt-5">125</span>
                </a>
              </div>
            </div>
            <div>
              <p className="view">View Opinions</p>
            </div>
          </div>
          <hr className="hr1 " />
          <div className="logoSearch2 mt-5 px-5 ">
            <div className="search2" style={{ border: "none" }}>
              <div className="s-icon2">
                <img src={img} alt="" />
              </div>
              <input type="text" placeholder="Write an opinion.." />
              <div className="s-icon2 d-flex">
                <img src={img7} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="home_card mt-4">
          <div className="d-flex justify-content-around mt-4">
            <div>
              <a href="" className="home_card_name">
                <img
                  src="https://images.unsplash.com/20/cambridge.JPG?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDF8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                  alt="college"
                  className="home_card_img"
                />
                <span className="ms-2">A.G College Of Tech..</span>
              </a>
            </div>
            <p className="mt-1">posted an announcement</p>
            <div className="mt-1">
              <a href="" className="home_card_time">
                <img src={img3} alt="" />
                <span className="ms-2 home_card_text">6 Days</span>
              </a>
            </div>
            <i className="fa-solid fa-ellipsis-vertical fs-4 mt-1"></i>
          </div>
          <div className="second_text">
            <img src={img4} alt="" />
            <span className="home_card_text">
              College Lyallpur college of Technology
            </span>
          </div>
          <div className="home_card_text2">
            <p>Graphic designer required!</p>
          </div>
          <div>
            <p className="home_card_text3">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. …. eos et accusam et justo duo dolores et
              ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
              Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetet
              ur sadipscing elitr, sed diam nonumy
            </p>
          </div>
          <hr className="hr" />
          <div className="d-flex justify-content-around mt-3">
            <div className="d-flex justify-content-around">
              <div>
                <a href="" className="home_card_text ">
                  <img src={img5} alt="" />
                  <span className="ms-2 mt-5">125</span>
                </a>
              </div>
              <div className="mx-5">
                <a href="" className="home_card_text ">
                  <img src={img6} alt="" />
                  <span className="ms-2 mt-5">125</span>
                </a>
              </div>
              <div>
                <a href="" className="home_card_text ">
                  <img src={img7} alt="" />
                  <span className="ms-2 mt-5">125</span>
                </a>
              </div>
            </div>
            <div>
              <p className="view">View Opinions</p>
            </div>
          </div>
          <hr className="hr1 " />
          <div className="logoSearch2 mt-5 px-5 ">
            <div className="search2" style={{ border: "none" }}>
              <div className="s-icon2">
                <img src={img} alt="" />
              </div>
              <input type="text" placeholder="Write an opinion.." />
              <div className="s-icon2 d-flex">
                <img src={img7} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPart;
