import React from "react";
import LeftSide from "../../components/Corporate/Left/LeftSide";
import RightSide from "../../components/Corporate_mock_test/Right/RightSide";
import "./Mock_test.css";
const Mock_test = () => {
  return (
    <div className="container-fluid">
      {/* <Navigation /> */}
      <div className="row">
        <div className="col-md-3 p-lg-5" style={{ background: "#EEEEEE" }}>
          <LeftSide />
        </div>
        <div className="col-md-9">
          <RightSide />
        </div>
      </div>
    </div>
  );
};

export default Mock_test;
