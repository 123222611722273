import React from "react";

import img11 from "../../../img/search/Group 505.png";
import img12 from "../../../img/search/Group 506.png";
import img13 from "../../../img/search/Group 507.png";
import img14 from "../../../img/search/Group 508.png";
import img15 from "../../../img/search/Group 509.png";
import img16 from "../../../img/search/Group 510.png";
import img17 from "../../../img/search/Group 511.png";
import img18 from "../../../img/search/Group 512.png";
import img19 from "../../../img/search/Group 513.png";
import img20 from "../../../img/search/Group 514.png";

import img21 from "../../../img/home/Ellipse 120.png";
import img22 from "../../../img/home/Group 188.png";
import img23 from "../../../img/home/home1/Icon ionic-ios-time.png";
import img24 from "../../../img/home/home1/Group 174.png";
import img25 from "../../../img/home/home1/Component 119 – 6.png";
import img26 from "../../../img/home/home1/Component 120 – 3.png";
import img27 from "../../../img/home/home1/Component 121 – 3.png";

import img31 from "../../../img/icon/Component 75 – 1.png";
import img32 from "../../../img/icon/Component 74 – 1.png";
import img34 from "../../../img/icon/Component 76 – 1.png";
import img35 from "../../../img/icon/Component 78 – 1.png";
import img36 from "../../../img/icon/Icon material-location-on@2x.png";
import img37 from "../../../img/icon/Icon ionic-ios-book.png";
import "./RightSide.css";
import { Link } from "react-router-dom";
import Profile from "../Profile/Profile";
const Menu = {
  top: "569px",
  left: "144px",
  textAlign: "left",
  font: "normal normal medium 14px/21px Poppins",
  letterSpacing: "0px",
  color: "#0F0C1A",
  textTransform: "uppercase",
  opacity: 0.9,

  borderRadius: "unset",
  width: "100%",
};

const RightSide = () => {
  return (
    <div>
      <div className="row">
        <div className="col-md-2">
          <div className="left_card_search text-start mt-4">
            <div className="d-flex align-items-start">
              <div className="my-4">
                <div
                  className="nav flex-column nav-pills me-5"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <div className="d-flex right_side_nav">
                    <button
                      className="nav-link active"
                      id="v-pills-home-courses-tab-courses active"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home-courses"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-home-courses"
                      aria-selected="true"
                      style={Menu}
                    >
                      <a href="" className="mx-3">
                        <img src={img11} alt="" />
                      </a>
                      Profiles (104){" "}
                    </button>
                  </div>

                  <button className="nav-link" style={Menu}>
                    <Link
                      to={"/home_announcement"}
                      style={{ textDecoration: "none", color: "#0F0C1A" }}
                    >
                      <a href="/home_announcement" className="mx-3">
                        <img src={img12} alt="" />
                      </a>
                      Posts (25)
                    </Link>
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-disabled-courses-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-disabled-courses"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-disabled-courses"
                    aria-selected="false"
                    style={Menu}
                  >
                    <a href="" className="mx-3">
                      <img src={img13} alt="" />
                    </a>
                    Hiring (02)
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-messages-courses-tab2"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings2-courses"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings2-courses"
                    aria-selected="false"
                    style={Menu}
                  >
                    <a href="" className="mx-3">
                      <img src={img14} alt="" />
                    </a>
                    Announcements (52)
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-messages-courses-tab11"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings2-courses11"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings2-courses11"
                    aria-selected="false"
                    style={Menu}
                  >
                    <a href="" className="mx-3">
                      <img src={img15} alt="" />
                    </a>
                    Questions (06)
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-messages-courses-tab12"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings2-courses12"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings2-courses12"
                    aria-selected="false"
                    style={Menu}
                  >
                    <a href="" className="mx-3">
                      <img src={img16} alt="" />
                    </a>
                    Offer Services (12)
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-messages-courses-tab13"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings2-courses13"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings2-courses13"
                    aria-selected="false"
                    style={Menu}
                  >
                    <a href="" className="mx-3">
                      <img src={img17} alt="" />
                    </a>
                    Colleges (12)
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-messages-courses-tab14"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings2-courses14"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings2-courses14"
                    aria-selected="false"
                    style={Menu}
                  >
                    <a href="" className="mx-3">
                      <img src={img18} alt="" />
                    </a>
                    Courses (09 )
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-messages-courses-tab15"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings2-courses15"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings2-courses15"
                    aria-selected="false"
                    style={Menu}
                  >
                    <a href="" className="mx-3">
                      <img src={img19} alt="" />
                    </a>
                    Exams (02)
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-messages-courses-tab16"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings2-courses16"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings2-courses16"
                    aria-selected="false"
                    style={Menu}
                  >
                    <a href="" className="mx-3">
                      <img src={img20} alt="" />
                    </a>
                    Corporate (12)
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-10">
          <div className="tab-content" id="v-pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="v-pills-home-courses"
              role="tabpanel"
              aria-labelledby="v-pills-home-courses-tab-courses"
              tabIndex={0}
            >
              <h3 className="text-start content_title p-5 text-center fs-3">
                PROFILES
              </h3>
              <Profile />
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-disabled-courses"
              role="tabpanel"
              aria-labelledby="v-pills-profile-courses-tab"
              tabIndex={0}
            >
              <h3 className="text-start content_title p-5 text-center fs-3">
                HIRING
              </h3>
              <div className="home_card">
                <div className="d-flex justify-content-around mt-4">
                  <div>
                    <a href="" className="home_card_name">
                      <img
                        src="https://images.unsplash.com/20/cambridge.JPG?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDF8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                        alt="college"
                        className="home_card_img"
                      />
                      <span className="ms-2">A.G College Of Tech..</span>
                    </a>
                  </div>
                  <p className="mt-1">posted an announcement</p>
                  <div className="mt-1">
                    <a href="" className="home_card_time">
                      <img src={img23} alt="" />
                      <span className="ms-2 home_card_text">6 Days</span>
                    </a>
                  </div>
                  <i className="fa-solid fa-ellipsis-vertical fs-4 mt-1"></i>
                </div>
                <div className="second_text">
                  <img src={img24} alt="" />
                  <span className="home_card_text">
                    College Lyallpur college of Technology
                  </span>
                </div>
                <div className="home_card_text2">
                  <p>Graphic designer required!</p>
                </div>
                <div>
                  <p className="home_card_text3">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. …. eos et accusam et justo
                    duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                    takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                    dolor sit amet, consetet ur sadipscing elitr, sed diam
                    nonumy
                  </p>
                </div>
                <hr className="hr" />
                <div className="d-flex justify-content-around mt-3">
                  <div className="d-flex justify-content-around">
                    <div>
                      <a href="" className="home_card_text ">
                        <img src={img25} alt="" />
                        <span className="ms-2 mt-5">125</span>
                      </a>
                    </div>
                    <div className="mx-5">
                      <a href="" className="home_card_text ">
                        <img src={img26} alt="" />
                        <span className="ms-2 mt-5">125</span>
                      </a>
                    </div>
                    <div>
                      <a href="" className="home_card_text ">
                        <img src={img27} alt="" />
                        <span className="ms-2 mt-5">125</span>
                      </a>
                    </div>
                  </div>
                  <div>
                    <p className="view">View Opinions</p>
                  </div>
                </div>
                <hr className="hr1 " />
                <div className="logoSearch2 mt-5 px-5 ">
                  <div className="search2" style={{ border: "none" }}>
                    <div className="s-icon2">
                      <img src={img21} alt="" />
                    </div>
                    <input type="text" placeholder="Write an opinion.." />
                    <div className="s-icon2 d-flex">
                      <img src={img22} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-settings2-courses"
              role="tabpanel"
              aria-labelledby="v-pills-messages-courses-tab2"
              tabIndex={0}
            >
              <h3 className="text-start content_title p-5 text-center fs-3">
                ANNOUNCEMENTS
              </h3>
              <div className="home_card">
                <div className="d-flex justify-content-around mt-4">
                  <div>
                    <a href="" className="home_card_name">
                      <img
                        src="https://images.unsplash.com/20/cambridge.JPG?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDF8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                        alt="college"
                        className="home_card_img"
                      />
                      <span className="ms-2">A.G College Of Tech..</span>
                    </a>
                  </div>
                  <p className="mt-1">posted an announcement</p>
                  <div className="mt-1">
                    <a href="" className="home_card_time">
                      <img src={img23} alt="" />
                      <span className="ms-2 home_card_text">6 Days</span>
                    </a>
                  </div>
                  <i className="fa-solid fa-ellipsis-vertical fs-4 mt-1"></i>
                </div>
                <div className="second_text">
                  <img src={img24} alt="" />
                  <span className="home_card_text">
                    College Lyallpur college of Technology
                  </span>
                </div>
                <div className="home_card_text2">
                  <p>Graphic designer required!</p>
                </div>
                <div>
                  <p className="home_card_text3">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. …. eos et accusam et justo
                    duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                    takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                    dolor sit amet, consetet ur sadipscing elitr, sed diam
                    nonumy
                  </p>
                </div>
                <hr className="hr" />
                <div className="d-flex justify-content-around mt-3">
                  <div className="d-flex justify-content-around">
                    <div>
                      <a href="" className="home_card_text ">
                        <img src={img25} alt="" />
                        <span className="ms-2 mt-5">125</span>
                      </a>
                    </div>
                    <div className="mx-5">
                      <a href="" className="home_card_text ">
                        <img src={img26} alt="" />
                        <span className="ms-2 mt-5">125</span>
                      </a>
                    </div>
                    <div>
                      <a href="" className="home_card_text ">
                        <img src={img27} alt="" />
                        <span className="ms-2 mt-5">125</span>
                      </a>
                    </div>
                  </div>
                  <div>
                    <p className="view">View Opinions</p>
                  </div>
                </div>
                <hr className="hr1 " />
                <div className="logoSearch2 mt-5 px-5 ">
                  <div className="search2" style={{ border: "none" }}>
                    <div className="s-icon2">
                      <img src={img21} alt="" />
                    </div>
                    <input type="text" placeholder="Write an opinion.." />
                    <div className="s-icon2 d-flex">
                      <img src={img22} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-settings2-courses13"
              role="tabpanel"
              aria-labelledby=" v-pills-messages-courses-tab13"
              tabIndex={0}
            >
              <h3 className="text-start content_title p-5 text-center fs-3">
                COLLEGES
              </h3>
              <div className="row row-cols-1 row-cols-md-3 g-4 mt-5">
                <div className="col">
                  <div className="card" style={{}}>
                    <img
                      src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcTT8Fz7ZZE4dkFF17j1_ZRoQWtO-S3B6uahpOsL9bPOqXbi54RR"
                      className="card-img-top"
                      alt="..."
                    />
                    <div
                      className="card-body"
                      style={{ background: "#EBEBEB " }}
                    >
                      <p className="card-text">
                        RAM DEVI UNIVERSITY INSTITUTE OF SCIENCE, ARTS,
                        PARAMEDICAL ...
                      </p>
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between px-5">
                          <div className="d-flex">
                            <a href="" className="search_span">
                              <img src={img36} alt="" />
                              <span className="ms-2">
                                Coimbatore,Tamil Nadu
                              </span>
                            </a>
                          </div>
                          <div className="d-flex">
                            <a href="" className="search_span">
                              <img src={img37} alt="" />
                              <span className="ms-2">UCC</span>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between px-5">
                          <div>
                            <h6>Course</h6>
                            <a>B.Ed</a>
                          </div>
                          <div>
                            <h6>Fees</h6>
                            <a>₹ 63,000</a>
                          </div>
                          <div>
                            <h6>Exam</h6>
                            <a>AKNUCET</a>
                          </div>
                          <div>
                            <h6>Ranked</h6>
                            <a>#0 by</a>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between px-5">
                          <div className="d-flex">
                            <img className="card_list_img" src={img32} alt="" />
                            <p>551</p>
                          </div>
                          <div className="d-flex">
                            <img className="card_list_img" src={img31} alt="" />
                            <p>12</p>
                          </div>
                          <div className="d-flex">
                            <img className="card_list_img" src={img34} alt="" />
                            <p>305</p>
                          </div>
                          <div className="d-flex">
                            <img className="card_list_img" src={img35} alt="" />
                            <p>125</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="card-body card_footer">
                      <a href="#" className="card-link">
                        Courses & Fees
                      </a>
                      <a href="#" className="card-link ">
                        Apply Now
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card" style={{}}>
                    <img
                      src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcTT8Fz7ZZE4dkFF17j1_ZRoQWtO-S3B6uahpOsL9bPOqXbi54RR"
                      className="card-img-top"
                      alt="..."
                    />
                    <div
                      className="card-body"
                      style={{ background: "#EBEBEB " }}
                    >
                      <p className="card-text">
                        RAM DEVI UNIVERSITY INSTITUTE OF SCIENCE, ARTS,
                        PARAMEDICAL ...
                      </p>
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between px-5">
                          <div className="d-flex">
                            <a href="" className="search_span">
                              <img src={img36} alt="" />
                              <span className="ms-2">
                                Coimbatore,Tamil Nadu
                              </span>
                            </a>
                          </div>
                          <div className="d-flex">
                            <a href="" className="search_span">
                              <img src={img37} alt="" />
                              <span className="ms-2">UCC</span>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between px-5">
                          <div>
                            <h6>Course</h6>
                            <a>B.Ed</a>
                          </div>
                          <div>
                            <h6>Fees</h6>
                            <a>₹ 63,000</a>
                          </div>
                          <div>
                            <h6>Exam</h6>
                            <a>AKNUCET</a>
                          </div>
                          <div>
                            <h6>Ranked</h6>
                            <a>#0 by</a>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between px-5">
                          <div className="d-flex">
                            <img className="card_list_img" src={img32} alt="" />
                            <p>551</p>
                          </div>
                          <div className="d-flex">
                            <img className="card_list_img" src={img31} alt="" />
                            <p>12</p>
                          </div>
                          <div className="d-flex">
                            <img className="card_list_img" src={img34} alt="" />
                            <p>305</p>
                          </div>
                          <div className="d-flex">
                            <img className="card_list_img" src={img35} alt="" />
                            <p>125</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="card-body card_footer">
                      <a href="#" className="card-link">
                        Courses & Fees
                      </a>
                      <a href="#" className="card-link ">
                        Apply Now
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card" style={{}}>
                    <img
                      src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcTT8Fz7ZZE4dkFF17j1_ZRoQWtO-S3B6uahpOsL9bPOqXbi54RR"
                      className="card-img-top"
                      alt="..."
                    />
                    <div
                      className="card-body"
                      style={{ background: "#EBEBEB " }}
                    >
                      <p className="card-text">
                        RAM DEVI UNIVERSITY INSTITUTE OF SCIENCE, ARTS,
                        PARAMEDICAL ...
                      </p>
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between px-5">
                          <div className="d-flex">
                            <a href="" className="search_span">
                              <img src={img36} alt="" />
                              <span className="ms-2">
                                Coimbatore,Tamil Nadu
                              </span>
                            </a>
                          </div>
                          <div className="d-flex">
                            <a href="" className="search_span">
                              <img src={img37} alt="" />
                              <span className="ms-2">UCC</span>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between px-5">
                          <div>
                            <h6>Course</h6>
                            <a>B.Ed</a>
                          </div>
                          <div>
                            <h6>Fees</h6>
                            <a>₹ 63,000</a>
                          </div>
                          <div>
                            <h6>Exam</h6>
                            <a>AKNUCET</a>
                          </div>
                          <div>
                            <h6>Ranked</h6>
                            <a>#0 by</a>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between px-5">
                          <div className="d-flex">
                            <img className="card_list_img" src={img32} alt="" />
                            <p>551</p>
                          </div>
                          <div className="d-flex">
                            <img className="card_list_img" src={img31} alt="" />
                            <p>12</p>
                          </div>
                          <div className="d-flex">
                            <img className="card_list_img" src={img34} alt="" />
                            <p>305</p>
                          </div>
                          <div className="d-flex">
                            <img className="card_list_img" src={img35} alt="" />
                            <p>125</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="card-body card_footer">
                      <a href="#" className="card-link">
                        Courses & Fees
                      </a>
                      <a href="#" className="card-link ">
                        Apply Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-settings2-courses14"
              role="tabpanel"
              aria-labelledby=" v-pills-messages-courses-tab14"
              tabIndex={0}
            >
              <h3 className="text-start content_title p-5 text-center fs-3">
                COURSES
              </h3>

              <div className="px-5">
                <div className=" popular">
                  <div className="row row-cols-1 row-cols-md-2 my-2">
                    <div className="col py-5 px-5 ">
                      <h4 className="text-start ">
                        Bachelors of Science [B.Sc](Microbiology)
                      </h4>
                      <div className="d-flex">
                        <div className="d-flex">
                          <div className="p-2">
                            <p className="star"></p>
                          </div>
                          <p className="mx-1">3 Yrs</p>
                        </div>
                        <div className="d-flex ms-5">
                          <div className="p-2">
                            <p className="star"></p>
                          </div>
                          <p className="mx-1">Full Time</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between w-75">
                        <a href="">Overview</a>

                        <a href="">Overview</a>
                        <a href="">Overview</a>
                      </div>
                    </div>
                    <div className="col py-5 px-5 ">
                      <div className="w-75">
                        <div className="d-flex justify-content-between ">
                          <div className="d-flex">
                            <div className="p-2">
                              <p className="star"></p>
                            </div>
                            <h4 className="mx-1">Average Fees</h4>
                          </div>
                          <div className="d-flex">
                            <div className="p-2">
                              <p className="star2"></p>
                            </div>
                            <h4 className="mx-1">Average Salary</h4>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between w-75">
                          <p>₹ 30,000</p>
                          <p>₹ 30,000</p>
                        </div>
                        <button
                          className="btn btn-outline-dark"
                          type="submit"
                          style={{
                            width: "100%",
                          }}
                        >
                          12 Colleges Offering This Course
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-5 my-3">
                <div className=" popular">
                  <div className="row row-cols-1 row-cols-md-2 my-2">
                    <div className="col py-5 px-5 ">
                      <h4 className="text-start ">
                        Bachelors of Science [B.Sc](Microbiology)
                      </h4>
                      <div className="d-flex">
                        <div className="d-flex">
                          <div className="p-2">
                            <p className="star"></p>
                          </div>
                          <p className="mx-1">3 Yrs</p>
                        </div>
                        <div className="d-flex ms-5">
                          <div className="p-2">
                            <p className="star"></p>
                          </div>
                          <p className="mx-1">Full Time</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between w-75">
                        <a href="">Overview</a>

                        <a href="">Overview</a>
                        <a href="">Overview</a>
                      </div>
                    </div>
                    <div className="col py-5 px-5 ">
                      <div className="w-75">
                        <div className="d-flex justify-content-between ">
                          <div className="d-flex">
                            <div className="p-2">
                              <p className="star"></p>
                            </div>
                            <h4 className="mx-1">Average Fees</h4>
                          </div>
                          <div className="d-flex">
                            <div className="p-2">
                              <p className="star2"></p>
                            </div>
                            <h4 className="mx-1">Average Salary</h4>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between w-75">
                          <p>₹ 30,000</p>
                          <p>₹ 30,000</p>
                        </div>
                        <button
                          className="btn btn-outline-dark"
                          type="submit"
                          style={{
                            width: "100%",
                          }}
                        >
                          12 Colleges Offering This Course
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-settings2-courses15"
              role="tabpanel"
              aria-labelledby=" v-pills-messages-courses-tab15"
              tabIndex={0}
            >
              <h3 className="text-start content_title p-5 text-center fs-3">
                EXAMS
              </h3>
              <div>
                <div className="stream text-start">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex p-3">
                        <img
                          src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSGFXknp64F6atN6OV7yxoB-kAsZYWnIOgznZ78mC7wyKCZJGZA"
                          alt=""
                          style={{ width: 80, height: 80 }}
                          className="img-fluid"
                        />
                        <div className="ms-3" style={{ width: 320 }}>
                          <h6>AP POLYCET</h6>
                          <p>
                            Andhra Pradesh Polytechnical Common Entrance Test
                          </p>
                          <a href="" className="text">
                            2188 Colleges Accepting this Exam
                          </a>
                          <div>
                            <a href="" className="text2">
                              Overview{" "}
                              <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                            </a>
                            <a href="" className="text2 mx-3">
                              Syllabus{" "}
                              <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                            </a>
                            <a href="" className="text2">
                              Exam Pattern{" "}
                              <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex mt-3">
                        <div className="d-flex" style={{ width: 220 }}>
                          <img
                            src="https://i.ibb.co/HPkY4PK/images-removebg-preview.png"
                            alt=""
                            style={{ height: 15 }}
                            className="m-1"
                          />
                          <h6>Application Date</h6>
                        </div>
                        <div className="d-flex" style={{ width: 220 }}>
                          <img
                            src="https://i.ibb.co/TrvKqs4/images-removebg-preview-1.png"
                            alt=""
                            style={{ height: 15 }}
                            className="m-1"
                          />
                          <h6 className="mx-2">Exam Date</h6>
                        </div>
                        <img
                          src="https://i.ibb.co/tPzdNwy/images-1-removebg-preview.png"
                          alt=""
                          style={{ height: 20 }}
                          className="m-1"
                        />
                        <h6>Result Date</h6>
                      </div>
                      <div className="d-flex px-4">
                        <p style={{ width: 220 }}>10 Apr 22-17 May 22</p>
                        <p className="mx-4" style={{ width: 200 }}>
                          28 May 22
                        </p>
                        <p style={{ width: 220 }}>07 Jun 22</p>
                      </div>
                      <div className="mt-4 button_last">
                        <button className="btn btn-outline-dark button_update me-3">
                          Get Updates
                        </button>
                        <button className=" button_apply">How to Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stream text-start mt-5">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex p-3">
                        <img
                          src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSGFXknp64F6atN6OV7yxoB-kAsZYWnIOgznZ78mC7wyKCZJGZA"
                          alt=""
                          style={{ width: 80, height: 80 }}
                          className="img-fluid"
                        />
                        <div className="ms-3" style={{ width: 320 }}>
                          <h6>AP POLYCET</h6>
                          <p>
                            Andhra Pradesh Polytechnical Common Entrance Test
                          </p>
                          <a href="" className="text">
                            2188 Colleges Accepting this Exam
                          </a>
                          <div>
                            <a href="" className="text2">
                              Overview{" "}
                              <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                            </a>
                            <a href="" className="text2 mx-3">
                              Syllabus{" "}
                              <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                            </a>
                            <a href="" className="text2">
                              Exam Pattern{" "}
                              <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex mt-3">
                        <div className="d-flex" style={{ width: 220 }}>
                          <img
                            src="https://i.ibb.co/HPkY4PK/images-removebg-preview.png"
                            alt=""
                            style={{ height: 15 }}
                            className="m-1"
                          />
                          <h6>Application Date</h6>
                        </div>
                        <div className="d-flex" style={{ width: 220 }}>
                          <img
                            src="https://i.ibb.co/TrvKqs4/images-removebg-preview-1.png"
                            alt=""
                            style={{ height: 15 }}
                            className="m-1"
                          />
                          <h6 className="mx-2">Exam Date</h6>
                        </div>
                        <img
                          src="https://i.ibb.co/tPzdNwy/images-1-removebg-preview.png"
                          alt=""
                          style={{ height: 20 }}
                          className="m-1"
                        />
                        <h6>Result Date</h6>
                      </div>
                      <div className="d-flex px-4">
                        <p style={{ width: 220 }}>10 Apr 22-17 May 22</p>
                        <p className="mx-4" style={{ width: 200 }}>
                          28 May 22
                        </p>
                        <p style={{ width: 220 }}>07 Jun 22</p>
                      </div>
                      <div className="mt-4 button_last">
                        <button className="btn btn-outline-dark button_update me-3">
                          Get Updates
                        </button>
                        <button className=" button_apply">How to Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stream text-start mt-5">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex p-3">
                        <img
                          src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSGFXknp64F6atN6OV7yxoB-kAsZYWnIOgznZ78mC7wyKCZJGZA"
                          alt=""
                          style={{ width: 80, height: 80 }}
                          className="img-fluid"
                        />
                        <div className="ms-3" style={{ width: 320 }}>
                          <h6>AP POLYCET</h6>
                          <p>
                            Andhra Pradesh Polytechnical Common Entrance Test
                          </p>
                          <a href="" className="text">
                            2188 Colleges Accepting this Exam
                          </a>
                          <div>
                            <a href="" className="text2">
                              Overview{" "}
                              <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                            </a>
                            <a href="" className="text2 mx-3">
                              Syllabus{" "}
                              <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                            </a>
                            <a href="" className="text2">
                              Exam Pattern{" "}
                              <i class="fa-sharp fa-solid fa-play fs-6 ms-1 sharp_arrow "></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex mt-3">
                        <div className="d-flex" style={{ width: 220 }}>
                          <img
                            src="https://i.ibb.co/HPkY4PK/images-removebg-preview.png"
                            alt=""
                            style={{ height: 15 }}
                            className="m-1"
                          />
                          <h6>Application Date</h6>
                        </div>
                        <div className="d-flex" style={{ width: 220 }}>
                          <img
                            src="https://i.ibb.co/TrvKqs4/images-removebg-preview-1.png"
                            alt=""
                            style={{ height: 15 }}
                            className="m-1"
                          />
                          <h6 className="mx-2">Exam Date</h6>
                        </div>
                        <img
                          src="https://i.ibb.co/tPzdNwy/images-1-removebg-preview.png"
                          alt=""
                          style={{ height: 20 }}
                          className="m-1"
                        />
                        <h6>Result Date</h6>
                      </div>
                      <div className="d-flex px-4">
                        <p style={{ width: 220 }}>10 Apr 22-17 May 22</p>
                        <p className="mx-4" style={{ width: 200 }}>
                          28 May 22
                        </p>
                        <p style={{ width: 220 }}>07 Jun 22</p>
                      </div>
                      <div className="mt-4 button_last">
                        <button className="btn btn-outline-dark button_update me-3">
                          Get Updates
                        </button>
                        <button className=" button_apply">How to Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSide;
