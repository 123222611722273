import React from "react";
import img from "../../img/icon/Icon ionic-md-add@2x.png";
import img2 from "../../img/icon/Icon material-delete@2x.png";
import "./Course.css";
const Course = () => {
  return (
    <div>
      <div>
        <div className="" style={{ margin: "0px 120px" }}>
          <form className="row g-3">
            <div className="col-md-3 form">
              <label htmlFor="inputState" className="form-label profile_label2">
                Choose Streams
              </label>
              <select id="inputState" className="form-select">
                <option selected>Select Stream</option>
                <option>A</option>
                <option>B</option>
              </select>
            </div>

            <div className="col-md-3 form mt-5">
              <label
                htmlFor="inputEmail4"
                className="form-label profile_label"
              ></label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="No Main Stream Added"
              />
            </div>
            <div className="col-md-3 form mt-5">
              <label
                htmlFor="inputEmail4"
                className="form-label profile_label"
              ></label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="No Main Stream Added"
              />
            </div>
            <div className="col-md-3 form mt-5 d-flex">
              <div className="course_img1">
                <img src={img} alt="" className="p-3 " />
              </div>
              <div className="course_img1 ms-3">
                <img src={img2} alt="" className="p-3 " />
              </div>
            </div>
            <div className="col-md-6 form">
              <label htmlFor="inputEmail4" className="form-label profile_label">
                Course Type
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="Choose course type..."
              />
            </div>
            <div className="col-md-6 form">
              <label htmlFor="inputEmail4" className="form-label profile_label">
                Course Name
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="Choose course name.."
              />
            </div>
            <div className="col-md-5 form">
              <label htmlFor="inputEmail4" className="form-label profile_label">
                Course Fee Details
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="Course Fee Details"
              />
            </div>
            <div className="col-md-5 form">
              <label htmlFor="inputEmail4" className="form-label profile_label">
                Course Fee
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="Enter course fees.."
              />
            </div>
            <div className="col-md-2 form mt-5 d-flex">
              <div className="course_img1">
                <img src={img} alt="" className="p-3 " />
              </div>
              <div className="course_img1 ms-3">
                <img src={img2} alt="" className="p-3 " />
              </div>
            </div>

            <div className="col-md-6 form">
              <label htmlFor="inputEmail4" className="form-label profile_label">
                Course Place
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="Enter course place"
              />
            </div>
            <div className="col-md-6 form">
              <label htmlFor="inputEmail4" className="form-label profile_label">
                Course Duration
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="Enter Course Duration"
              />
            </div>
            <div className="col-md-6 form">
              <label htmlFor="inputEmail4" className="form-label profile_label">
                Course Eligibility
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="Enter course eligibility"
              />
            </div>
            <div className="col-md-6 form">
              <label htmlFor="inputEmail4" className="form-label profile_label">
                Course Type
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="Enter course typpe"
              />
            </div>
            <div className="col-md-6 form">
              <label htmlFor="inputEmail4" className="form-label profile_label">
                Program Type
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="Course Category"
              />
            </div>
            <div className="col-md-6 form">
              <label htmlFor="inputEmail4" className="form-label profile_label">
                Exam Accepted
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="Choose exam accepted"
              />
            </div>

            <div className="d-flex justify-content-center align-items-center mb-5">
              <button
                type="submit"
                className=" profile_btn2 "
                style={{ width: 220 }}
              >
                Add More
              </button>
              <button
                type="submit"
                className="  profile_btn ms-3"
                style={{ width: 220 }}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Course;
