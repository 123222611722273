import React from "react";
import Details from "../../components/Exam_inner/Details";

const Exam_inner = () => {
  return (
    <div>
      <Details />
    </div>
  );
};

export default Exam_inner;
