import React from "react";
import { Link } from "react-router-dom";
import img from "../../img/login/Group 692.png";
import "./Login.css";
const Login = () => {
  return (
    <div>
      <div>
        <div className="row p-0 m-0">
          <div className="col-md-7 p-0 m-0">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: 600 }}
            >
              <div>
                <h1 className="login_h1">LOGO</h1>
                <h6 className="login_h6">Sign in to your account</h6>
                <img src={img} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
          <div
            className="col-md-5 login_left "
            style={{ position: "relative" }}
          >
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: 800 }}
            >
              <div>
                <div className="img_card2 ">
                  <form className="row g-3 p-lg-5 p-3">
                    <div className="col-md-12 form">
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Enter Email"
                      />
                    </div>
                    <div className="col-md-12 form">
                      <input
                        type="password"
                        className="form-control"
                        id="inputPassword4"
                        placeholder="Enter Password"
                      />
                    </div>

                    <div className="col-md-12 form">
                      <select id="inputState" className="form-select">
                        <option selected>Select Login Type</option>
                        <option>Student</option>
                        <option>College</option>
                        <option>Campus Ambassadors</option>
                        <option>School or college authorities</option>

                        <option>Others</option>
                      </select>
                    </div>
                    <p className="login_p1">Forgot Password?</p>
                    <div className="d-flex justify-content-center align-items-center mb-5">
                      <button
                        type="submit"
                        className="  profile_btn"
                        style={{ width: "100%" }}
                      >
                        Login
                      </button>
                    </div>
                    <div className="d-flex justify-content-center">
                      <p className="login_p"> Don't have an account?</p>
                      <Link to={"/register"}>
                        <a href="/register">Create Account</a>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
