import React from "react";
import LeftSide from "../../components/Home_default2/LeftSide/LeftSide";
import MainPart from "../../components/Home_1/MainPart/MainPart";
import RightSide from "../../components/Home_default2/RightSide/RightSide";

import "./Home.css";
const Home_1 = () => {
  return (
    <div className="container-fluid">
      {/* <Navigation /> */}
      <div className="row p-5">
        <div className="col-md-3">
          <LeftSide />
        </div>
        <div className="col-md-6">
          <MainPart />
        </div>
        <div className="col-md-3">
          <RightSide />
        </div>
      </div>
    </div>
  );
};

export default Home_1;
